import AnalyticsInsightsTable from '@/components/AnalyticsInsightsTable';

import { getFacebookData } from '@/views/BrandAnalytics/utilsFacebook.js';
import { getInstagramData } from '@/views/BrandAnalytics/utilsInstagram.js';
import { getTikTokData } from '@/views/BrandAnalytics/utilsTikTok.js';
import { getLinkedinData } from '@/views/BrandAnalytics/utilsLinkedin.js';
import { getYoutubeData } from '@/views/BrandAnalytics/utilsYoutube.js';

import { parse } from 'date-fns';

export default {
  name: 'BrandAnalyticsSummaryList',

  props: {
    accountType: {
      default: 'FACEBOOK',
    },
    pageInfo: {
      default: () => {},
    },
    metrics: {
      require: true,
    },
    compact: {
      required: false,
      default: false,
      type: Boolean,
    },
    totalPosts: {
      default: 0,
      type: Number,
    },
  },

  components: {
    AnalyticsInsightsTable,
  },

  computed: {
    analytics() {
      if (this.accountType === 'FACEBOOK') {
        return this.getFacebookData();
      }

      if (this.accountType === 'TIKTOK') {
        return this.getTikTokData();
      }

      if (this.accountType === 'LINKEDIN') {
        return this.getLinkedinData();
      }

      if (this.accountType === 'YOUTUBE') {
        return this.getYoutubeData();
      }

      return this.getInstagramData();
    },

    fans() {
      return this.pageInfo?.fans;
    },

    hasTotalFans() {
      return !!this.fans;
    },
  },

  methods: {
    getLastCounterInPageInfo(item = 'fans') {
      const itemObj = this.pageInfo[item];

      if (!itemObj) {
        return 0;
      }

      const sortedFans = [...itemObj].sort((a, b) => {
        return parse(b.endTime) - parse(a.endTime);
      });

      return sortedFans[0]?.value ?? 0;
    },

    getFacebookData() {
      let response = getFacebookData(this.metrics);

      if (this.hasTotalFans) {
        if (this.fans) {
          response.unshift({
            name: 'Total Fans',
            values: this.getLastCounterInPageInfo('fans'),
          });
        }
      }

      return response;
    },

    getInstagramData() {
      let response = getInstagramData(this.metrics);

      if (this.fans) {
        response.unshift({
          name: 'Total Followers',
          values: this.getLastCounterInPageInfo('fans'),
        });
      }

      return response;
    },

    getTikTokData() {
      let response = getTikTokData(this.metrics);

      if (this.fans) {
        response.unshift(
          {
            name: 'Total Followers',
            position: 1,
            values: this.getLastCounterInPageInfo('fans'),
          },
          {
            icon: 'lovePurple',
            name: 'Total Likes',
            position: 2,
            values: this.getLastCounterInPageInfo('likes'),
          },
          {
            icon: 'posts',
            name: 'Total Posts',
            position: 3,
            values: this.getLastCounterInPageInfo('postCount'),
          }
        );
      }

      return response;
    },

    getLinkedinData() {
      let response = getLinkedinData(this.metrics);

      if (this.fans) {
        response.unshift(
          {
            name: 'Total Followers',
            position: 1,
            values: this.getLastCounterInPageInfo('fans'),
          },
          {
            icon: 'posts',
            name: 'Total Posts',
            position: 2,
            values: this.pageInfo?.currentPosts || this.totalPosts || 0,
          }
        );
      }

      return response;
    },

    getYoutubeData() {
      return getYoutubeData(this.metrics, this.totalPosts);
    },
  },
};
