import AnalyticsPostCardGrid from '@/components/AnalyticsPostCardGrid';
import Dropdown from '@/components/Dropdown2';

import mixinPosts from '@/components/BrandAnalyticsSummaryTops/mixinPosts';

import { getTotalEngagement } from '@/views/BrandAnalytics/utilsCommon';

export default {
  name: 'BrandAnalyticsSummaryTops',

  components: {
    AnalyticsPostCardGrid,
    Dropdown,
  },

  props: {
    accountInfo: {
      required: true,
    },
    brandUrl: {
      required: true,
    },
    posts: {
      required: true,
      type: Array,
    },
  },

  mixins: [mixinPosts],

  data() {
    return {
      optionSelected: null,
    };
  },

  computed: {
    accountImage() {
      return this.accountInfo?.avatarUrl;
    },

    accountName() {
      return this.accountInfo?.name;
    },

    dataToShow() {
      const toShow = ['engagementRate', 'publishDate', 'totalEngagements'];

      if (this.isFacebookAccount) {
        return [...toShow, 'peopleReached', 'totalClicks'];
      }

      if (this.isTikTokAccount || this.isLinkedinAccount) {
        return [...toShow, 'impressions'];
      }

      //default instagram
      return [...toShow, 'peopleReached'];
    },

    sortedPosts() {
      if (!this.optionSelected) {
        return [];
      }

      const { name } = this.optionSelected;
      const posts = [...this.originalPosts];

      if (name === 'Clicked') {
        return posts.sort((a, b) => {
          const aMetric = a?.metrics?.clicks ?? 0;
          const bMetric = b?.metrics?.clicks ?? 0;

          return bMetric - aMetric;
        });
      }

      if (['People Reached', 'Impressions'].includes(name)) {
        return posts.sort((a, b) => {
          const aMetric = a?.metrics?.impressions ?? 0;
          const bMetric = b?.metrics?.impressions ?? 0;

          return bMetric - aMetric;
        });
      }

      if (name === 'Recently') {
        return posts.sort((a, b) => {
          return new Date(b?.publishAt) - new Date(a?.publishAt);
        });
      }

      //Sort default - sort by Engagements
      return (posts || []).sort((a, b) => {
        const aTotalEngagements = getTotalEngagement(a?.metrics ?? {}) ?? 0;
        const bTotalEngagements = getTotalEngagement(b?.metrics ?? {}) ?? 0;

        return bTotalEngagements - aTotalEngagements;
      });
    },

    tops() {
      return this.sortedPosts.slice(0, 5);
    },

    options() {
      let options = [
        { name: 'Engagements', title: 'Top 5: Most Engaging' },
        { name: 'Recently', title: 'Top 5: Most Recent' },
      ];

      if (this.isFacebookAccount) {
        options.push({ name: 'Clicked', title: 'Top 5: Most Clicked' });
      }

      if (this.isInstagramAccount) {
        options.push({ name: 'People Reached', title: 'Top 5: Highest Reach' });
      }

      if (this.isLinkedinAccount) {
        options.push({ name: 'Impressions', title: 'Top 5: Most Impressions' });
        options.push({ name: 'Clicked', title: 'Top 5: Most Clicked' });
      }

      return (options || []).sort((a, b) => {
        return a.title > b.title ? 1 : -1;
      });
    },
  },

  methods: {
    showTops(id) {
      this.showPost(id, 'tops');
    },
  },

  mounted() {
    this.optionSelected = this.options[0];
  },
};
