import ChartLine from '@/components/Charts/ChartLine';
import Icon from '@/components/AnalyticsCardIcon';

export default {
  name: 'TotalsCharts',

  components: {
    ChartLine,
    Icon,
  },

  props: {
    chart: {
      required: true,
    },
    height: {
      required: false,
      default: null,
      type: Number,
    },
    width: {
      required: false,
      default: null,
      type: Number,
    },
    chartWidth: {
      required: false,
      default: null,
      type: Number,
    },
    chartHeight: {
      required: false,
      default: null,
      type: Number,
    },
    numberClass: {
      required: false,
      default: null,
      type: String,
    },
    textClass: {
      required: false,
      default: null,
      type: String,
    },
    iconClass: {
      required: false,
      default: null,
      type: String,
    },
  },
};
