import BrandAnalyticsSummaryTotalsChartsCard from '@/components/BrandAnalyticsSummaryTotalsChartsCard';
import MonthBrandAnalyticsReport from '@/components/MonthBrandAnalyticsReport';

import remoteQuery from './remoteQuery.graphql';
import { reduceMetric } from '../../utils/metrics';

export default {
  name: 'MonthAnalyticsReport',

  components: {
    BrandAnalyticsSummaryTotalsChartsCard,
    MonthBrandAnalyticsReport,
  },

  data() {
    return {
      analyticReport: null,
    };
  },

  apollo: {
    analyticReport: {
      query() {
        return remoteQuery;
      },
      variables() {
        return {
          id: this.$route.params.report,
          accessKey: this.$route.query.accessKey,
          isCustomReport: this.$route.path.includes('custom'),
        };
      },
      skip() {
        return !this.$route.params || !this.$route.path;
      },
    },
  },

  computed: {
    accountType() {
      return this.analyticReport?.type;
    },

    posts() {
      return this.analyticReport?.posts;
    },

    youtubeVideos() {
      const obj = this.analyticReport.postsYoutubeByReactions?.reduce(
        (acc, posts) => {
          acc[`postsBy${posts.__typename}`] = posts.posts;
          return acc;
        },
        {}
      );
      return obj ?? {};
    },

    pageInfo() {
      return this.analyticReport?.pageInfo;
    },

    totalPosts() {
      return this.posts.length ?? 0;
    },

    totalFans() {
      return this.analyticReport?.totalFans;
    },

    metrics() {
      const metrics = this.posts.map(post => post.metrics);

      let totalMetrics = metrics.reduce(reduceMetric, {});
      if (this.totalFans && this.accountType === 'YOUTUBE') {
        totalMetrics.subscribersCount = this.totalFans;
      }

      return totalMetrics;
    },

    fans() {
      return this.accountType === 'YOUTUBE'
        ? this.pageInfo?.subscribers
        : this.pageInfo?.fans ?? [];
    },

    audience() {
      return this.analyticReport?.audience;
    },

    audienceInRangeYoutube() {
      const { audience } = this;

      const countries = this.transformAudience(audience.countries);
      const genderAge = this.transformAudience(audience.genderAge);
      const sharingService = this.transformAudience(audience.sharingService);
      const playbackLocation = this.transformAudience(
        audience.playbackLocation
      );
      const subscribedStatus = this.transformAudience(
        audience.subscribedStatus
      );

      return {
        countries,
        genderAge,
        sharingService,
        playbackLocation,
        subscribedStatus,
      };
    },

    audienceInRangeFans() {
      const { audience } = this;

      const cities = this.transformAudience(audience.cities);
      const countries = this.transformAudience(audience.countries);
      const genderAge = this.transformAudience(audience.genderAge);
      const locale = this.transformAudience(audience.locale);
      const functions = this.transformAudience(audience.functions);
      const industries = this.transformAudience(audience.industries);
      const regions = this.transformAudience(audience.regions);
      const seniorities = this.transformAudience(audience.seniorities);
      const sharingService = this.transformAudience(audience.sharingService);
      const playbackLocation = this.transformAudience(
        audience.playbackLocation
      );

      return {
        cities: [cities[0]],
        countries: [countries[0]],
        genderAge: [genderAge[0]],
        locale: [locale[0]],
        functions: [functions[0]],
        industries: [industries[0]],
        regions: [regions[0]],
        seniorities: [seniorities[0]],
        sharingService: [sharingService[0]],
        playbackLocation: [playbackLocation[0]],
      };
    },

    audienceInRange() {
      const { audience, filterAudienceOnline } = this;

      return {
        online: filterAudienceOnline(audience.online || []),
      };
    },

    analyticsSummary() {
      return {
        accountType: this.accountType,
        startDate: this.startDate,
        endDate: this.endDate,
        fans: this.fans,
        totalFans: this.totalFans,
        metrics: this.metrics,
        posts: this.posts,
        totalPosts: this.totalPosts,
        pageInfo: this.pageInfo,
        audienceInRange: this.audienceInRange,
        audienceInRangeFans:
          this.accountType === 'YOUTUBE'
            ? this.audienceInRangeYoutube
            : this.audienceInRangeFans,
        youtubeVideos: this.youtubeVideos,
      };
    },
  },

  methods: {
    transformAudience(audienceObj) {
      return (audienceObj || []).map(val => JSON.parse(val.value));
    },

    filterAudienceOnline(audienceObj) {
      return (audienceObj || []).map(val => {
        return {
          endTime: val.endTime,
          value: JSON.parse(val.value),
        };
      });
    },

    transformYoutubeAudience(audienceObj) {
      let audienceReduced = audienceObj
        .map(({ value }) => JSON.parse(value))
        .reduce((acc, audienceProp) => {
          for (const key in audienceProp) {
            acc[key] = acc[key]
              ? acc[key] + audienceProp[key]
              : audienceProp[key];
          }
          return acc;
        }, {});

      return audienceReduced;
    },
  },
};
