import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

export default {
  name: 'OrganicPaid',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    metrics: {
      required: true,
    },
  },

  computed: {
    peopleReached() {
      const { impressionsOrganic, impressionsPaid } = this.metrics;

      return {
        organic: impressionsOrganic || 0,
        paid: impressionsPaid || 0,
      };
    },
    videoView() {
      const { videoViewsOrganic, videoViewsPaid } = this.metrics;

      return {
        organic: videoViewsOrganic || 0,
        paid: videoViewsPaid || 0,
      };
    },
  },
};
