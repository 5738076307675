import { getTotalEngagement } from './utilsCommon';

/**
 * Get engagements rate of Tiktok
 * @param {*} metric
 */
export const getEngagementRate = metric => {
  const totalEngagements = getTotalEngagement(metric);
  const impressions = metric?.impressions ?? 0;

  if (impressions > 0) {
    return (totalEngagements * 100) / impressions;
  }

  return 0;
};

/**
 * Convert metrics in data with name and value information
 * @param {*} metrics
 */
export const getTikTokData = metrics => {
  const { comments, engagement, impressions, likes, shares } = metrics;

  const engagementRate = getEngagementRate(metrics) ?? undefined;

  const metricsToShow = [
    {
      name: 'Engagement Rate',
      percent: true,
      position: 3,
      values: engagementRate || 0,
    },
    {
      name: 'Engagements',
      position: 4,
      values: engagement || 0,
    },
    {
      name: 'Impressions',
      position: 5,
      values: impressions || 0,
    },
    {
      name: 'Likes',
      position: 6,
      values: likes || 0,
    },
    {
      name: 'Comments',
      position: 7,
      values: comments || 0,
    },
    {
      icon: 'Shares',
      name: 'Shares',
      position: 8,
      values: shares,
    },
  ];

  return metricsToShow;
};
