// A collection of functions for calculate facebook
import { capitalize } from 'lodash';
import delve from 'dlv';

import {
  getTotalEngagement,
  getTotalReactions,
  getPercents,
} from './utilsCommon';

/**
 * Get Facebook Total Reach from metrics
 * @param {*} metric
 */
export const getTotalReach = metric => {
  const organic = metric?.impressionsOrganic || 0;
  const paid = metric?.impressionsPaid || 0;

  return organic + paid;
};

/**
 * Get Facebook Engagement rate from metrics
 * @param {*} metric
 */
export const getEngagementRate = metric => {
  const engagedUsers = metric?.engagedUsers || 0;
  const impressions = metric?.impressions || 0;

  if (impressions > 0) {
    return (engagedUsers / impressions) * 100;
  }

  return 0;
};

/**
 * return a Object with representative information about metrics
 * @param {*} metrics
 */
export const getRepresentativeMetrics = metrics => {
  const { clicks, comments, impressions, shares, videoViews } = metrics;

  return {
    clicks,
    comments,
    impressions,
    shares,
    videoViews,
    totalEngagements: getTotalEngagement(metrics),
    engagementRate: getEngagementRate(metrics),
  };
};

/**
 * Object with a new struct of data facebook metrics
 *
 * @param metrics
 * @returns {*[]}
 */
export const getFacebookData = metrics => {
  const {
    clicks,
    comments,
    impressions,
    impressionsOrganic,
    impressionsPaid,
    shares,
    videoViews,
    videoViewsOrganic,
    videoViewsPaid,
  } = metrics;

  const reactions = {
    ...metrics.reactions,
    total: getTotalReactions(metrics.reactions),
  };

  const detailsReaction = delve(metrics, 'reactions.others', null)
    ? ['like', 'others']
    : ['like', 'love', 'wow', 'haha', 'sad', 'anger'];

  const peopleReached = {
    organic: impressionsOrganic || 0,
    paid: impressionsPaid || 0,
    total: impressions || 0,
  };

  const videoView = {
    organic: videoViewsOrganic || 0,
    paid: videoViewsPaid || 0,
    total: videoViews || 0,
  };

  return [
    //People reached
    {
      details: [
        {
          name: 'Organic Reach',
          percent: getPercents(peopleReached.total, peopleReached.organic),
          values: peopleReached.organic,
        },
        {
          name: 'Paid Reach',
          percent: getPercents(peopleReached.total, peopleReached.paid),
          values: peopleReached.paid,
        },
      ],
      icon: 'peopleReached',
      name: 'Post Reach',
      showMore: true,
      values: peopleReached.total,
    },
    {
      icon: 'engagementRate',
      name: 'Post Engagement Rate',
      percent: true,
      values: getEngagementRate(metrics),
    },
    {
      icon: 'Total Engagements',
      name: 'Post Engagements',
      values: getTotalEngagement(metrics),
    },
    {
      icon: 'Clicks',
      name: 'Post Clicks',
      values: clicks,
    },
    //Reactions
    {
      details: detailsReaction.map(name => {
        return {
          name: capitalize(name),
          percent: getPercents(reactions.total, reactions[name]),
          values: reactions[name],
        };
      }),
      icon: 'Total Reactions',
      name: 'Post Reactions',
      showMore: true,
      values: reactions.total,
    },
    {
      icon: 'Comments',
      name: 'Post Comments',
      values: comments,
    },
    {
      icon: 'Shares',
      name: 'Post Shares',
      values: shares,
    },
    //Videos
    {
      details: [
        {
          name: 'Organic Views',
          percent: getPercents(videoView.total, videoView.organic),
          values: videoView.organic,
        },
        {
          name: 'Paid Views',
          percent: getPercents(videoView.total, videoView.paid),
          values: videoView.paid,
        },
      ],
      icon: 'Video Views',
      name: 'Post Video Views',
      showMore: true,
      values: videoView.total,
    },
  ];
};
