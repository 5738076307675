import { format } from 'date-fns';
import { mapActions, mapState } from 'vuex';

import { getFacebookData } from '@/views/BrandAnalytics/utilsFacebook.js';
import { getTikTokData } from '@/views/BrandAnalytics/utilsTikTok.js';
import { getLinkedinData } from '@/views/BrandAnalytics/utilsLinkedin.js';
import { getYoutubeData } from '@/views/BrandAnalytics/utilsYoutube.js';

// import { CREATE_POST_PANEL } from '@/components/CreatePostPanel';

// import SwipeModal from '@/components/SwipeModal';

export default {
  computed: {
    ...mapState('analytics', {
      activeRemoteAccount: state => state.activeRemoteAccount,
    }),

    typeName() {
      return this.posts[0]?.__typename || null;
    },

    isFacebookAccount() {
      return this.typeName === 'FacebookPost';
    },

    isInstagramAccount() {
      return this.typeName === 'InstagramPost';
    },

    isTikTokAccount() {
      return this.typeName === 'TikTokPost';
    },

    isLinkedinAccount() {
      return this.typeName === 'LinkedinPost';
    },

    isYoutubeAccount() {
      return this.typeName.includes(['YoutubePost', 'RemoteYoutubeVideo']);
    },

    originalPosts() {
      const {
        isFacebookAccount,
        isTikTokAccount,
        isLinkedinAccount,
        isYoutubeAccount,
        posts,
        youtubeVideos,
      } = this;

      if (!posts.some(p => p.id) && !isYoutubeAccount) {
        return [];
      }

      //Facebook
      if (isFacebookAccount) {
        return posts.map(post => {
          const metrics = post?.metrics || [];
          const fbMetrics = getFacebookData(metrics);

          const engagementRate = fbMetrics.find(
            m =>
              m?.icon === 'engagementRate' ||
              m?.name.includes('Engagement Rate')
          );

          const totalEngagements = fbMetrics.find(
            m =>
              m?.icon === 'Total Engagements' ||
              m?.name.includes('Post Engagements')
          );

          return {
            ...post,
            attachment: this.getAttachment(post.attachments),
            id: post.id,
            publishAt: format(post.remotePublishAt, 'MMM D, YYYY'),
            metrics: {
              ...metrics,
              engagementRate: engagementRate?.values || 0,
              totalEngagements: totalEngagements?.values || 0,
            },
          };
        });
      }

      //TikTok
      if (isTikTokAccount) {
        return posts.map(post => {
          const metrics = post?.metrics || [];
          const tkMetrics = getTikTokData(metrics);

          const engagementRate = tkMetrics.find(
            m => m.name === 'Engagement Rate'
          );

          const totalEngagements = tkMetrics.find(m => m.name === 'Engagement');

          return {
            ...post,
            attachment: this.getAttachment(post.attachments),
            id: post.id,
            publishAt: format(post.remotePublishAt, 'MMM D, YYYY'),
            metrics: {
              ...metrics,
              engagementRate: engagementRate?.values,
              totalEngagements: totalEngagements?.values,
            },
          };
        });
      }

      //Linkedin
      if (isLinkedinAccount) {
        return posts.map(post => {
          const metrics = post?.metrics || [];
          const lkdMetrics = getLinkedinData(metrics);

          const engagementRate = lkdMetrics.find(
            m => m.name === 'Engagement Rate'
          );

          const totalEngagements = lkdMetrics.find(
            m => m.name === 'Total Engagements' || m.icon === 'Engagements'
          );

          return {
            ...post,
            attachment: this.getAttachment(post.attachments),
            id: post.id,
            publishAt: format(post.remotePublishAt, 'MMM D, YYYY'),
            metrics: {
              ...metrics,
              engagementRate: engagementRate?.values,
              totalEngagements: totalEngagements?.values,
            },
          };
        });
      }

      // Youtube
      if (isYoutubeAccount) {
        return youtubeVideos.map(post => {
          const metrics = post?.metrics || [];

          return {
            ...post,
            id: post.id,
            publishAt: format(post.remotePublishAt, 'MMM D, YYYY'),
            metrics,
          };
        });
      }
      //Instagram
      return posts.map(post => {
        const { metrics } = post;
        const { engagement, reach } = metrics;

        return {
          ...post,
          attachment: this.getAttachment(post.attachments),
          engagementRate: (engagement / reach) * 100,
          id: post.id,
          impressions: reach,
          publishAt: format(post.remotePublishAt, 'MMM D, YYYY'),
          totalEngagements: engagement,
        };
      });
    },
  },

  methods: {
    ...mapActions('modals', ['showModal']),
    ...mapActions('panels', { openPanelTarget: 'openPanel' }),

    getAttachment(attachments) {
      if (attachments && attachments[0]) {
        const type = attachments.length > 1 ? 'album' : attachments[0].type;

        return {
          image: attachments[0].src,
          type,
        };
      }

      return {
        image: this.baseUrl('img/placeholder_default.png'),
      };
    },

    getPost(id) {
      return this.posts.find(post => post.id === id) || {};
    },

    showPost(id, source = 'posts') {
      const { accountImage, accountName, brandUrl } = this;
      const { clientWidth } = document.body;

      let width = '1000px';

      if (clientWidth > 500 && clientWidth < 1100) {
        width = '480px';
      } else if (clientWidth <= 500) {
        width = `${clientWidth}px`;
      }

      let initialIndex = 0;

      const arrayOfProps = (this[source] || []).map((post, index) => {
        if (post.id === id) {
          initialIndex = index;
        }

        return {
          post: {
            ...post,
            user: {
              ...this.accountInfo,
            },
          },
          accountImage,
          accountName,
          accountInfo: this.accountInfo,
          brandUrl,
          width,
          extraConfig: {
            copyPostCallback: this.copyPost,
            accountInfo: this.accountInfo,
            copyPostName: `Repost`,
            internalLink: {
              name: 'AnalyticsInternalLink',
              params: {
                current: 'posts',
                link: 'link',
                postId: post?.id,
                rootId: this.activeRemoteAccount?.id ?? null,
              },
            },
          },
        };
      });
    },
  },
};
