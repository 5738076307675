import Icon from '@/components/AnalyticsCardIcon';
import CircularProgress from '@/components/CircularProgress';

/** TODO Tavo (refactor .vue) */

export default {
  name: 'OrganicPaidCard',

  components: {
    CircularProgress,
    Icon,
  },

  props: {
    peopleReached: {
      default: null,
      type: Object,
    },
    videoView: {
      default: null,
      type: Object,
    },
  },

  computed: {
    sizeChart() {
      const { clientWidth } = document.body;

      return clientWidth > 500 ? '24px' : '20px';
    },

    isPeopleReach() {
      return !!this.peopleReached;
    },
  },

  methods: {
    getShare(value) {
      const { organic, paid } = value;
      const total = (organic || 0) + (paid || 0);

      return {
        organic: this.$filters.formatNumber((organic * 100) / total),
        paid: this.$filters.formatNumber((paid * 100) / total),
      };
    },
  },
};
