/**
 * Get engagements rate of Instagram
 * @param {*} metric
 */
export const getEngagementRate = metric => {
  const { engagement, reach } = metric;

  return (engagement / reach) * 100;
};

/**
 * Convert metrics in data with name and value information
 * @param {*} metrics
 */
export const getInstagramData = metrics => {
  const { comments, engagement, impressions, likes, reach, saved } = metrics;

  const engagementRate = getEngagementRate(metrics) ?? undefined;

  const metricsToShow = [
    {
      name: 'People Reached',
      position: 1,
      values: reach || 0,
    },
    {
      name: 'Organic Engagement Rate',
      percent: true,
      position: 2,
      values: engagementRate || 0,
    },
    {
      name: 'Total Organic Engagements',
      position: 3,
      values: engagement || 0,
    },
    {
      name: 'Impressions',
      position: 4,
      values: impressions || 0,
    },
    {
      name: 'Organic Likes',
      position: 5,
      values: likes || 0,
    },
    {
      name: 'Organic Comments',
      position: 7,
      values: comments || 0,
    },
    {
      name: 'Organic Saves',
      position: 8,
      values: saved || 0,
    },
  ];

  return metricsToShow;
};
