// @flow

export default function(HTMLElement, ClientRect) {
  const k = node.ownerDocument.documentElement;
  const clientLeft = k ? k.clientLeft : 0;
  const clientTop = k ? k.clientTop : 0;

  const left = Math.round(clientRect.left) - clientLeft;
  const right = Math.round(clientRect.right) - clientLeft;
  const top = Math.round(clientRect.top) - clientTop;
  const bottom = Math.round(clientRect.bottom) - clientTop;

  return {
    bottom,
    left,
    right,
    top,
    height: bottom - top,
    width: right - left,
  };
}
