export default {
  name: 'FanVariance',

  props: {
    height: {
      type: Number,
    },
    width: {
      type: Number,
    },
    maxVariance: {
      type: Number,
    },
    avgVariance: {
      type: Number,
    },
    isYoutube: {
      default: false,
    },
  },
};
