import BrandAnalyticsFansCards from '@/components/BrandAnalyticsFansCards';

import { getCountry } from './utils.js';
import linkedinStandardizedData from './linkedinStandardizedData';

export default {
  name: 'BrandAnalyticsFansLinkedin',

  components: {
    BrandAnalyticsFansCards,
  },

  props: {
    audienceInRangeFans: {
      required: true,
      type: Object,
    },
  },

  computed: {
    countries() {
      const { countries } = this.audienceInRangeFans;

      return (this.getTotalByGroup(countries[0]) ?? []).map(c => {
        return {
          ...c,
          name: c.name.replace('urn:li:country:', ''),
        };
      });
    },

    functions() {
      const { functions } = this.audienceInRangeFans;

      return (this.getTotalByGroup(functions[0]) ?? []).map(c => {
        return {
          ...c,
          name: this.getStandardValue(c.name),
        };
      });
    },

    industries() {
      const { industries } = this.audienceInRangeFans;

      return (this.getTotalByGroup(industries[0]) ?? []).map(c => {
        return {
          ...c,
          name: this.getStandardValue(c.name),
        };
      });
    },

    regions() {
      const { regions } = this.audienceInRangeFans;

      return (this.getTotalByGroup(regions[0]) ?? []).map(c => {
        return {
          ...c,
          name: this.getStandardValue(c.name),
        };
      });
    },

    seniorities() {
      const { seniorities } = this.audienceInRangeFans;

      return (this.getTotalByGroup(seniorities[0]) ?? []).map(c => {
        return {
          ...c,
          name: this.getStandardValue(c.name),
        };
      });
    },

    haveCountryData() {
      return this.countries?.length > 0;
    },

    // Chart Data
    countryChartData() {
      return this.getChartData(this.countries).map(value => {
        return {
          ...value,
          label: getCountry(value.label),
        };
      });
    },

    haveFunctionData() {
      return this.functions?.length > 0;
    },

    functionChartData() {
      return this.getChartData(this.functions);
    },

    haveIndustryData() {
      return this.industries?.length > 0;
    },

    industryChartData() {
      return this.getChartData(this.industries);
    },

    haveRegionData() {
      return this.regions?.length > 0;
    },

    regionChartData() {
      return this.getChartData(this.regions);
    },

    haveSeniorityData() {
      return this.seniorities?.length > 0;
    },

    seniorityChartData() {
      return this.getChartData(this.seniorities);
    },

    steps() {
      const width = window.innerWidth;

      return width > 580 ? 9 : 5;
    },
  },

  methods: {
    /**
     *
     * @param {*} data
     * @param {*} color
     * @returns
     */
    getChartData(data = [], color = '#DAF1EF') {
      return data
        .sort((a, b) => b.total - a.total)
        .slice(0, 10)
        .map(value => {
          return {
            color,
            data: value.total,
            label: value.name,
          };
        });
    },

    /**
     *
     * @param {*} obj
     * @returns
     */
    getTotalByGroup(obj) {
      if (!obj) {
        return [];
      }

      return Object.values(obj).map(val => {
        const { followerCounts } = val;

        const total =
          (followerCounts?.organicFollowerCount || 0) +
          (followerCounts?.paidFollowerCount || 0);

        // eslint-disable-next-line no-unused-vars
        const [_, name] = Object.values(val);

        return {
          name: name || '',
          total,
        };
      });
    },

    getStandardValue(cName) {
      const seek = linkedinStandardizedData.find(({ name }) => name === cName);

      return seek?.value || '';
    },
  },
};
