import Vue from 'vue';
import VueRouter from 'vue-router';

import MonthAnalyticsReport from '@/views/MonthAnalyticsReport';

Vue.use(VueRouter);

const routes = [
  {
    path: '/analytics-report/:report',
    component: MonthAnalyticsReport,
  },
  {
    path: '/analytics-custom-report/:report',
    component: MonthAnalyticsReport,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
