import AnalyticsCardIcon from '@/components/AnalyticsCardIcon';

export default {
  name: 'BrandAnalyticsFansCards',

  components: {
    AnalyticsCardIcon,
    ChartBipolarBar: () => import('@/components/ChartBipolarBar'),
    ChartHorizontalBarWithStep: () =>
      import('@/components/ChartHorizontalBarWithStep'),
    GenderAge: () => import('@/components/BrandAnalyticsFansGenderAge'),
  },

  props: {
    title: {
      required: true,
      type: String,
    },

    icon: {
      required: true,
      type: String,
    },

    genderAgeProps: {
      default: null,
      type: Object,
    },

    chartBipolarProps: {
      default: null,
      type: Object,
    },

    chartHorizontalBarWithStepProps: {
      default: null,
      type: Object,
    },

    chartClass: {
      default: null,
      type: String,
      required: false,
    },

    itemClass: {
      default: null,
      type: String,
      required: false,
    },
  },

  computed: {
    isFanChart() {
      return this.genderAgeProps || this.chartBipolarProps;
    },
  },
};
