export const COLORS = {
  pink: {
    backgroundColor: 'rgba(250, 116, 158, 0.29)',
    borderColor: 'rgba(250, 116, 158, 1)',
  },
  green: {
    backgroundColor: 'rgba(161, 178, 32, 0.24)',
    borderColor: 'rgba(161, 178, 32, 1)',
  },
  red: {
    backgroundColor: 'rgba(246, 201, 199, 0.51)',
    borderColor: 'rgba(224, 100, 93, 1)',
  },
  purple: {
    backgroundColor: 'rgba(225, 189, 229, 0.44)',
    borderColor: 'rgba(195, 90, 163, 1)',
  },
  blue: {
    backgroundColor: 'rgba(100, 157, 190, 0.25)',
    borderColor: 'rgba(100, 157, 190, 1)',
  },
  orange: {
    backgroundColor: 'rgba(250, 185, 122, 0.36)',
    borderColor: 'rgba(245, 137, 107, 1)',
  },
};
