const MS_PER_SEC = 1000;
const SEC_PER_MIN = 60;
const MIN_PER_HOUR = 60;
const HOUR_PER_DAY = 24;
const DAYS_PER_WEEK = 7;
const MONTHS_PER_YEAR = 12;
const US_PER_MS = 1000;
const AVG_DAYS_PER_MONTH = 30.43;
const AVG_DAYS_PER_YEAR = 365.242;
const SEC_PER_HOUR = SEC_PER_MIN * MIN_PER_HOUR;
const SEC_PER_DAY = SEC_PER_HOUR * HOUR_PER_DAY;
const SEC_PER_WEEK = SEC_PER_DAY * DAYS_PER_WEEK;
const SEC_PER_YEAR = SEC_PER_DAY * AVG_DAYS_PER_YEAR;
const MS_PER_MIN = MS_PER_SEC * SEC_PER_MIN;
const MS_PER_HOUR = MS_PER_MIN * MIN_PER_HOUR;
const MS_PER_DAY = MS_PER_SEC * SEC_PER_DAY;
const MS_PER_WEEK = MS_PER_DAY * DAYS_PER_WEEK;
const MS_PER_YEAR = MS_PER_DAY * AVG_DAYS_PER_YEAR;

const DAYS = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

Object.freeze(DAYS);

export default {
  DAYS,
  MS_PER_SEC,
  MS_PER_MIN,
  MS_PER_HOUR,
  MS_PER_DAY,
  MS_PER_WEEK,
  MS_PER_YEAR,
  SEC_PER_MIN,
  SEC_PER_HOUR,
  SEC_PER_DAY,
  SEC_PER_WEEK,
  SEC_PER_YEAR,
  US_PER_MS,
  MIN_PER_HOUR,
  HOUR_PER_DAY,
  DAYS_PER_WEEK,
  MONTHS_PER_YEAR,
  AVG_DAYS_PER_MONTH,
  AVG_DAYS_PER_YEAR,

  getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  },

  getCurrentTimeInSeconds() {
    return Date.now() / MS_PER_SEC;
  },
};
