import delve from 'dlv';

import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

export default {
  name: 'AnalyticsPostCardGrid',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    posts: {
      default: () => [],
      type: Array,
    },
    dataToShow: {
      default: () => {
        return [
          'engagementRate',
          'peopleReached',
          'publishDate',
          'totalClicks',
          'totalEngagements',
          'shares',
          'comments',
        ];
      },
      type: Array,
    },
    aspect: {
      required: false,
    },
    gridClass: {
      required: false,
      default: null,
      type: String,
    },
    itemTextClass: {
      required: false,
      default: null,
      type: String,
    },
    itemIconClass: {
      required: false,
      default: null,
      type: String,
    },
  },
  computed: {
    isSafari() {
      return (
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
      );
    },
  },

  methods: {
    getProps(post) {
      const postProps = {
        aspect: post.__typename === 'TikTokPost' ? '9:16' : this.aspect,
        dataToShow: this.dataToShow,
        src: this.srcPost(post),
        type: this.typePost(post),
        thumbnail: this.thumbnailPost(post),
        post,
      };

      if (this.itemTextClass) {
        postProps.itemTextClass = this.itemTextClass;
      }

      if (this.itemIconClass) {
        postProps.itemIconClass = this.itemIconClass;
      }

      return postProps;
    },

    srcPost(post) {
      return (
        delve(post, 'attachments.0.thumbnail', null) ??
        delve(post, 'attachments.0.src', null) ??
        post.attachment?.thumbnail ??
        post.attachment?.src ??
        post.attachment?.image
      );
    },

    typePost(post) {
      return post?.attachment?.type ?? delve(post, 'attachments.0.type', null);
    },

    thumbnailPost(post) {
      return post.attachment.thumbnail ?? null;
    },
  },
};
