import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

import introspectionQueryResultData from './fragmentTypes';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const { csrf_token, endpoints } = window.env; // eslint-disable-line camelcase

const httpLink = createHttpLink({
  credentials: 'same-origin',
  uri: `${endpoints.graphql}`,
  headers: {
    accept: 'application/json',
    'X-CSRF-TOKEN': csrf_token,
  },
});

const newContext = setContext((operation, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const cache = new InMemoryCache({
  fragmentMatcher,
  cacheRedirects: {
    Query: {
      post: (_, { id }, { getCacheKey }) =>
        getCacheKey({ id, __typename: 'Post' }),
    },
  },
});

// Create the apollo client
const apolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([newContext.concat(httpLink)]),
});

export default apolloClient;
