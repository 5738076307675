import Popper from 'popper.js';
import styles from './Dropdown.module.css';
import getElementPosition from '../../utils/getElementPosition';

export default {
  name: 'Dropdown',

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    innerClass: {
      type: String,
    },
    innerClassOption: {
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    placeholder: {
      default: 'Select an option',
      type: String,
    },
    preselectFirst: {
      default: false,
      type: Boolean,
    },
    value: {
      type: [Object, String, Number],
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    label() {
      const { value } = this;

      if (!value) {
        return <span class={styles.placeholder}>{this.placeholder}</span>;
      }

      return this.$scopedSlots.default({
        onClick: () => {},
        option: this.value,
      });
    },
  },

  methods: {
    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },

    onClick(option) {
      this.isOpen = false;

      this.$emit('input', option);
    },

    setupPopper() {
      const { button, dropdown } = this.$refs;
      this.popper = new Popper(button, dropdown, {
        placement: 'bottom-start',
      });
      const position = getElementPosition(button);

      dropdown.style.minWidth = `${position.width}px`;
    },
  },

  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.$nextTick(() => this.setupPopper());
      } else {
        this.destroyPopper();
      }
    },
  },

  mounted() {
    if (this.preselectFirst && this.options.length && !this.value) {
      this.onClick(this.options[0]);
    }
  },

  beforeDestroy() {
    this.destroyPopper();
  },

  render() {
    return (
      <div
        aria-expanded={String(this.isOpen)}
        aria-haspopup="menu"
        class={styles.container}
      >
        <div
          ref="button"
          class={[
            styles.inner,
            this.innerClass,
            this.disabled && styles.disabled,
          ]}
          onClick={() => {
            if (!this.disabled) {
              this.isOpen = !this.isOpen;
            }
          }}
        >
          {this.label}
          <span class={styles.caret} />
        </div>

        {this.isOpen && (
          <portal to="default">
            <div
              class={[styles.dropdown, this.innerClassOption]}
              ref="dropdown"
              v-click-outside={() => (this.isOpen = false)}
            >
              {this.options.map(option =>
                this.$scopedSlots.default({
                  option,
                  className: styles.option,
                  onClick: () => this.onClick(option),
                })
              )}
            </div>
          </portal>
        )}
      </div>
    );
  },
};
