/**
 * Object with a new struct of data youtube metrics
 *
 * @param metrics
 * @returns {*[]}
 */
export const getYoutubeData = (metrics, totalPosts = 1) => {
  const {
    views,
    estimatedMinutesWatched,
    averageViewDuration,
    likes,
    subscribersGained,
    shares,
    comments,
    videosAddedToPlaylists,
    cardImpressions,
    subscribersCount,
  } = metrics;

  const totalSeconds = averageViewDuration / totalPosts || 0;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.round(totalSeconds - minutes * 60);

  let youtubeData = [
    {
      name: 'Subscribers Gained',
      icon: 'totalFans',
      position: 1,
      values: subscribersGained || 0,
    },
    {
      name: 'Views',
      icon: 'impressions',
      position: 2,
      values: views || 0,
    },
    {
      name: 'Watch Time (hours)',
      icon: 'videoViews',
      position: 3,
      values: estimatedMinutesWatched / 60 || 0,
    },
    {
      name: 'Average View Duration (Minutes)',
      icon: 'videoViews',
      isString: true,
      position: 4,
      values: `${minutes}:${seconds}`,
    },
    {
      name: 'Likes',
      position: 5,
      values: likes || 0,
    },
    {
      name: 'Card Impressions',
      icon: 'People Reached',
      position: 6,
      values: cardImpressions || 0,
    },
    {
      name: 'Comments',
      position: 7,
      values: comments || 0,
    },
    {
      name: 'Shares',
      position: 8,
      values: shares || 0,
    },
    {
      name: 'Added to Playlists',
      icon: 'saves',
      position: 9,
      values: videosAddedToPlaylists || 0,
    },
  ];

  if (subscribersCount) {
    youtubeData.unshift({
      name: 'Total Subscribers',
      icon: 'totalFans',
      position: 0,
      values: subscribersCount || 0,
    });
  }

  return youtubeData;
};
