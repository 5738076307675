import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5f07786b&"
import script from "./LeftRight.js?vue&type=script&lang=js&"
export * from "./LeftRight.js?vue&type=script&lang=js&"
import style0 from "./styles.css?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports