import Bootstrap from '../../lib/Bootstrap';
import ISO6391 from 'iso-639-1';

const BootstrapDataCountries = Bootstrap.getBootstrapData('Countries');

export const getPercents = (total, compare) => {
  const percent = (compare * 100) / total;

  if (percent > 0 && percent < 1) {
    return parseFloat(percent).toFixed(3);
  }

  return parseFloat(percent).toFixed(0);
};

export const getLanguageAndCountry = locale => {
  const lc = locale.split('_');

  // ar_AR y es_LA are exception
  //https://developers.facebook.com/docs/internationalization/

  const language = ISO6391.getName(lc[0]);

  if (['ar_AR', 'es_LA'].includes(locale)) {
    return {
      country: locale === 'es_LA' ? 'Latin América' : 'Arabic',
      language,
    };
  }

  return {
    country: BootstrapDataCountries[lc[1]],
    language,
  };
};

export const getCountry = countryEnc => {
  const seek = (countryEnc || '').toUpperCase();

  return BootstrapDataCountries[seek] || seek;
};
