import moment from 'moment';
import { filter, sumBy, mean } from 'lodash';

import AnalyticsPostCardGrid from '@/components/AnalyticsPostCardGrid';
import BrandAnalyticsBestTimesCards from '@/components/BrandAnalyticsBestTimesCards';
import BrandAnalyticsFansCards from '@/components/BrandAnalyticsFansCards';
import BrandAnalyticsFansLinkedin from '@/components/BrandAnalyticsFansLinkedin';
import BrandAnalyticsSummaryList from '@/components/BrandAnalyticsSummaryList';
import BrandAnalyticsSummaryOrganicPaidChartsCard from '@/components/BrandAnalyticsSummaryOrganicPaidChartsCard';
import BrandAnalyticsSummaryTops from '@/components/BrandAnalyticsSummaryTops';
import BrandAnalyticsSummaryTotalsChartsCard from '@/components/BrandAnalyticsSummaryTotalsChartsCard';
import FanVariance from '@/components/FanVariance';
import OrganicPaidCharts from '@/components/BrandAnalyticsSummaryOrganicPaidCharts';
import ReactionsCharts from '@/components/BrandAnalyticsSummaryReactionsCharts';
import TotalsCharts from '@/components/BrandAnalyticsSummaryTotalsCharts';

import { sortPostsBy } from '@/views/BrandAnalytics/utils.js';

import {
  getCountry,
  getPercents,
  getTotalGender,
} from '@/components/BrandAnalyticsFans/utils.js';

import mixinPosts from '@/components/BrandAnalyticsSummaryTops/mixinPosts';

import { format } from 'date-fns';
import {
  chartTotalEngagements,
  chartTotalFans,
  chartTotalReactions,
  getGenericCharts,
  getTotalPosts,
  totalEngagements,
  totalMetrics,
  totalReactions,
} from '../BrandAnalyticsSummaryTotalsCharts/utils';

import tc from 'thousands-counter';

const TZ_FACEBOOK = 'PDT'; //Pacific Daylight Time (GMT-7)

const callbackLabel = tooltipItems => {
  return `${tc(tooltipItems.yLabel)} Followers`;
};

export default {
  name: 'MonthBrandAnalyticsReport',

  mixins: [mixinPosts],

  components: {
    AnalyticsPostCardGrid,
    BrandAnalyticsBestTimesCards,
    BrandAnalyticsFansCards,
    BrandAnalyticsFansLinkedin,
    BrandAnalyticsSummaryList,
    BrandAnalyticsSummaryOrganicPaidChartsCard,
    BrandAnalyticsSummaryTops,
    BrandAnalyticsSummaryTotalsChartsCard,
    FanVariance,
    OrganicPaidCharts,
    ReactionsCharts,
    TotalsCharts,
  },

  props: {
    accountImage: {
      default: '',
    },
    accountName: {
      default: '',
    },
    accountType: {
      default: 'FACEBOOK',
      type: String,
    },
    brandUrl: {
      default: '',
    },
    endDate: {
      required: false,
    },
    fans: {
      default: () => [],
      type: Array,
    },
    metrics: {
      default: () => {},
      type: Object,
    },
    totalFans: {
      default: 0,
      type: Number,
    },
    posts: {
      default: () => [],
      type: Array,
    },
    totalPosts: {
      default: 0,
      type: Number,
    },
    showIn: {
      default: 'list',
      type: String,
    },
    startDate: {
      required: false,
    },
    audienceInRange: {
      default: () => {},
      type: Object,
    },
    audienceInRangeFans: {
      default: () => {},
      type: Object,
    },
    pageInfo: {
      default: () => {},
    },
    youtubeVideos: {
      default: () => [],
    },
  },

  data() {
    return {
      selectedDay: 'Monday',
    };
  },

  computed: {
    isFacebookAccount() {
      const typeName = this.posts[0]?.__typename;

      return typeName === 'FacebookPost';
    },

    isInstagramAccount() {
      const typeName = this.posts[0]?.__typename;

      return typeName === 'InstagramPost';
    },

    isFacebookOrLinkedinAccount() {
      return ['LINKEDIN', 'FACEBOOK'].includes(this.accountType);
    },

    isYoutubeAccount() {
      return this.accountType === 'YOUTUBE';
    },

    dataToShow() {
      const toShow = ['publishDate', 'totalEngagements'];

      if (this.isFacebookAccount) {
        return [...toShow, 'peopleReached', 'totalClicks'];
      }

      if (this.isTikTokAccount) {
        return [...toShow, 'impressions'];
      }

      if (this.isYoutubeAccount) {
        return ['views', 'shares', 'likes'];
      }

      return [...toShow, 'peopleReached'];
    },

    allDaysAndHoursInLocal() {
      return (this.daysPDT || []).reduce((ac, day) => {
        const datetime = (day.stats || []).map(stat => {
          return {
            amount: stat.amount,
            dateTime: new Date(stat.datetime),
            day: format(stat.datetime, 'MM/DD/YYYY'),
            hour: this.getLocalHours(stat.datetime),
            hour24: this.getHour24(stat.datetime),
            weekDay: this.getWeekDay(stat.datetime),
          };
        });

        return [...ac, ...datetime];
      }, []);
    },

    allHoursInLocal() {
      const { allDaysAndHoursInLocal } = this;
      return allDaysAndHoursInLocal.reduce((ac, day) => {
        const { amount, hour, hour24 } = day;

        if (ac[hour24]) {
          ac[hour24] = {
            hour,
            hour24,
            amount: ac[hour24].amount + amount,
          };
        } else {
          ac[hour24] = {
            hour,
            hour24,
            amount,
          };
        }

        return ac;
      }, []);
    },

    allWeekGroupDays() {
      const { groupByWeekDays } = this;
      const hoursGroups = [];
      for (let i = 0; i < 24; i++) {
        hoursGroups[i] = {
          values: [],
          avg: 0,
          hour24: i,
          label: moment(`${i}`, 'hh').format('h A'),
        };
      }

      Object.entries(groupByWeekDays).map(([weekday, audience]) => {
        hoursGroups.forEach((value, hour) => {
          const filteredHours = filter(audience, ['hour24', `${hour}`]);
          const sumHours = sumBy(filteredHours, 'amount');
          hoursGroups[hour].values.push(sumHours);
        });
      });

      //calculate all averages
      hoursGroups.forEach((value, i) => {
        const { values } = hoursGroups[i];
        hoursGroups[i].avg = mean(values);
      });

      return hoursGroups;
    },

    allWeekAudienceChartByDay() {
      const { allWeekGroupDays } = this;

      const data = {
        labels: allWeekGroupDays.map(({ label }) => label),
        datasets: [
          {
            backgroundColor: 'rgba(218, 241, 239, 1)',
            borderColor: 'rgba(101, 198, 189, 1)',
            borderWidth: 1.5,
            pointRadius: 2,
            pointHoverRadius: 3,
            data: allWeekGroupDays.map(({ avg }) => avg),
            zeroLineWidth: 0,
            lineWidth: 0,
          },
        ],
      };

      return {
        chartData: data,
        displayX: true,
        tooltips: true,
      };
    },

    audienceChartByDay() {
      const { groupByWeekDays } = this;

      const selectedDay = (groupByWeekDays[this.selectedDay] || []).reduce(
        (ac, day) => {
          const { hour24 } = day;

          if (ac[hour24]) {
            const average = (ac[hour24].average + day.amount) / 2;

            ac[hour24] = {
              ...day,
              average,
            };
          } else {
            ac[hour24] = {
              ...day,
              average: parseInt(day.amount),
            };
          }

          return ac;
        },
        []
      );

      return {
        labels: this.allHoursInLocal.map(({ hour }) => hour),
        datasets: [
          {
            backgroundColor: 'rgba(218, 241, 239, 1)',
            borderColor: 'rgba(101, 198, 189, 1)',
            borderWidth: 1.5,
            pointRadius: 2,
            pointHoverRadius: 3,
            data: selectedDay.map(hour => hour.average),
            zeroLineWidth: 0,
            lineWidth: 0,
          },
        ],
      };
    },

    subscribers() {
      return [...(this.pageInfo?.subscribers ?? [])].sort(
        (a, b) => new Date(a.endTime) - new Date(b.endTime)
      );
    },

    topPostsReach() {
      const posts = [...sortPostsBy(this.tops, 'people-reached')];
      return posts.slice(0, 10);
    },

    topPostsLikes() {
      return this.youtubeVideos.postsByLikes ?? [];
    },

    topPostsViews() {
      return this.youtubeVideos.postsByViews ?? [];
    },

    topPostsShares() {
      return this.youtubeVideos.postsByShares ?? [];
    },

    topPostsEngagement() {
      const posts = [...sortPostsBy(this.tops, 'total-engagements')];
      return posts.slice(0, 10);
    },

    topPostsReactions() {
      const posts = [...sortPostsBy(this.tops, 'total-reactions')];
      return posts.slice(0, 10);
    },

    topPostsClicks() {
      const posts = [...sortPostsBy(this.tops, 'post-click')];
      return posts.slice(0, 10);
    },

    chartLineProps() {
      return {
        callbackLabel: callbackLabel,
        chartData: this.audienceChartByDay,
        displayX: true,
        tooltips: true,
      };
    },

    cities() {
      return this.getTotalByGroup(this.audienceInRangeFans.cities);
    },

    citiesChartData() {
      return Object.entries(this.cities)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(city => {
          return {
            color: '#DAF1EF',
            data: city[1],
            label: city[0],
          };
        });
    },

    countries() {
      return this.getTotalByGroup(this.audienceInRangeFans.countries);
    },

    countryChartData() {
      return Object.entries(this.countries)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(country => {
          return {
            color: '#DAF1EF',
            data: country[1],
            label: getCountry(country[0]),
          };
        });
    },

    playbackLocations() {
      return this.getTotalByGroup(this.audienceInRangeFans.playbackLocation);
    },

    playbackLocationChartData() {
      return Object.entries(this.playbackLocations)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(playbackLocation => {
          return {
            color: '#DAF1EF',
            data: playbackLocation[1],
            label: playbackLocation[0],
          };
        });
    },

    sharingServices() {
      return this.getTotalByGroup(this.audienceInRangeFans.sharingService);
    },

    sharingServiceCharData() {
      return Object.entries(this.sharingServices)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(sharingService => {
          return {
            color: '#DAF1EF',
            data: sharingService[1],
            label: sharingService[0],
          };
        });
    },

    subscribedStatus() {
      return this.getTotalByGroup(this.audienceInRangeFans.subscribedStatus);
    },

    subscribedStatusChartData() {
      return Object.entries(this.subscribedStatus)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(subscribedStatus => {
          return {
            color: '#DAF1EF',
            data: subscribedStatus[1] / 60,
            label: subscribedStatus[0],
          };
        });
    },

    daysPDT() {
      const { online } = this.audienceInRange;

      return (online || []).map(audience => {
        const { endTime: day, value } = audience;

        const stats = Object.entries(value).map(([hour, amount]) => {
          return {
            datetime: this.getPDT(day, hour),
            amount,
          };
        });

        return {
          day: format(day, 'MM/DD/YYYY'),
          stats,
        };
      });
    },

    fanVarianceAvg() {
      const sum = this.fanVariance.reduce((acc, curr) => {
        return acc + curr;
      }, 0);

      const count = this.fanVariance.length;
      const result = sum / count;

      return count === 0 ? 0 : result;
    },

    fanVariance() {
      if (this.fans.length === 1) {
        return [this.fans[0].value];
      }
      return this.fans.reduce((acc, curr, i) => {
        if (i === 0) return acc;

        acc.push(this.fans[i].value - this.fans[i - 1].value);
        return acc;
      }, []);
    },

    fanMaxVariance() {
      const maxVariance = this.fanVariance.sort(
        (a, b) => Math.abs(b) - Math.abs(a)
      )[0];
      return maxVariance ?? 0;
    },

    genderAge() {
      return this.getTotalByGroup(this.audienceInRangeFans.genderAge);
    },

    genderAgeChartData() {
      const labels = Object.keys(this.genderAge)
        .filter(ga => !ga.includes('U.'))
        .map(ga => ga.substring(2))
        .reduce((ac, ga) => {
          if (!ac.includes(ga)) {
            ac.push(ga);
          }

          return ac;
        }, [])
        .sort((a, b) => {
          return a.localeCompare(b);
        });

      const totalGender = this.totalWomen + this.totalMen;

      return labels.map(label => {
        const female = Object.entries(this.genderAge).find(
          gender =>
            gender[0].includes(`F.${label}`) || gender[0].includes(`f.${label}`)
        );

        const male = Object.entries(this.genderAge).find(
          gender =>
            gender[0].includes(`M.${label}`) || gender[0].includes(`m.${label}`)
        );

        const data1 = getPercents(totalGender, female ? female[1] : 0);
        const data2 = getPercents(totalGender, male ? male[1] : 0);

        return {
          data1,
          data2,
          label,
        };
      });
    },

    groupByWeekDays() {
      const { allDaysAndHoursInLocal } = this;

      return allDaysAndHoursInLocal.reduce((ac, day) => {
        if (ac[day.weekDay]) {
          ac[day.weekDay].push(day);
        } else {
          ac[day.weekDay] = [day];
        }

        return ac;
      }, []);
    },

    haveCitiesData() {
      return this.audienceInRangeFans.cities?.filter(e => !!e).length > 0;
    },

    haveCountryData() {
      return this.audienceInRangeFans.countries.filter(e => !!e).length > 0;
    },

    haveGenderData() {
      return this.audienceInRangeFans.genderAge.filter(e => !!e).length > 0;
    },

    havePlaybackLocationData() {
      return (
        this.audienceInRangeFans.playbackLocation?.filter(e => !!e).length > 0
      );
    },

    haveSharingServiceCharData() {
      return (
        this.audienceInRangeFans.sharingService?.filter(e => !!e).length > 0
      );
    },

    haveSubscribedData() {
      return (
        this.audienceInRangeFans.subscribedStatus?.filter(e => !!e).length > 0
      );
    },

    metricsChart() {
      return this.posts.map(post => {
        return {
          date: format(new Date(post.remotePublishAt), 'DD-MM-YYYY'),
          metrics: post.metrics,
        };
      });
    },

    timezone() {
      return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'undefined';
    },

    tops() {
      return this.originalPosts;
    },

    totalCharts() {
      const { fans, accountType, subscribers } = this;

      //sorting fans
      (fans ?? []).sort((a, b) => {
        return new Date(a.endTime) - new Date(b.endTime);
      });

      if (accountType === 'INSTAGRAM') {
        return this.generateInstagramData(fans, this.metricsChart);
      }

      if (accountType === 'YOUTUBE') {
        return this.generateYoutubeData(subscribers, this.metricsChart);
      }

      return this.generateFacebookData(fans, this.metricsChart, accountType);
    },

    totalMen() {
      return this.isYoutubeAccount
        ? getTotalGender('m.', this.genderAge)
        : getTotalGender('M.', this.genderAge);
    },

    totalWomen() {
      return this.isYoutubeAccount
        ? getTotalGender('f.', this.genderAge)
        : getTotalGender('F.', this.genderAge);
    },

    reachMetrics() {
      return {
        organic: this.metrics.impressionsOrganic,
        paid: this.metrics.impressionsPaid,
      };
    },

    videoMetrics() {
      return {
        organic: this.metrics.videoViewsOrganic,
        paid: this.metrics.videoViewsPaid,
      };
    },

    titleFansCard() {
      return this.isYoutubeAccount
        ? 'Subscribers Gender and Age'
        : 'Fans Gender and Age';
    },
  },

  methods: {
    getLastValue(data) {
      const sorted = [...(data || [])].sort(
        (a, b) => new Date(b.endTime) - new Date(a.endTime)
      );

      return sorted[0]?.value ?? 0;
    },

    generateFacebookData(fans, metrics, accountType) {
      const {
        clicks,
        comments,
        impressions,
        shares,
        videoViews,
      } = totalMetrics(metrics);

      return {
        totalFans: {
          chartValue: chartTotalFans(fans),
          icon: 'totalFans',
          progress: this.getProgress(chartTotalFans(fans)),
          text: 'Total Fans',
          value: this.getLastValue(fans),
        },
        peopleReached: {
          chartValue: getGenericCharts(
            metrics,
            'impressions',
            accountType === 'FACEBOOK' ? 'green' : 'orange'
          ),
          icon: accountType === 'FACEBOOK' ? 'peopleReached' : 'impressions',
          text: accountType === 'FACEBOOK' ? 'Post Reach' : 'Impressions',
          value: impressions,
        },
        totalEngagements: {
          chartValue: chartTotalEngagements(metrics),
          icon: 'totalEngagements',
          rate: this.getEngagementRate(metrics),
          text: 'Post Engagements',
          value: totalEngagements(metrics),
        },
        clicks: {
          chartValue: getGenericCharts(metrics, 'clicks', 'purple'),
          icon: 'clicks',
          text: 'Post Clicks',
          value: clicks,
        },
        totalReactions: {
          chartValue: chartTotalReactions(metrics),
          icon: 'totalReactions',
          text: 'Post Reactions',
          value: totalReactions(metrics),
        },
        comments: {
          chartValue: getGenericCharts(metrics, 'comments', 'blue'),
          icon: 'comments',
          text: 'Post Comments',
          value: comments,
        },
        shares: {
          chartValue: getGenericCharts(metrics, 'shares', 'blue'),
          icon: 'shares',
          text: 'Post Shares',
          value: shares,
        },
        videoViews: {
          chartValue: getGenericCharts(metrics, 'videoViews', 'orange'),
          icon: 'videoViews',
          text: 'Post Video View',
          value: videoViews,
        },
      };
    },

    generateInstagramData(fans, metrics) {
      const {
        comments,
        engagement,
        impressions,
        likes,
        reach,
        saved,
      } = totalMetrics(metrics);

      return {
        totalFans: {
          chartValue: chartTotalFans(fans),
          icon: 'totalFans',
          progress: this.getProgress(chartTotalFans(fans)),
          text: 'Total Fans',
          value: this.getLastValue(fans),
        },
        peopleReached: {
          chartValue: getGenericCharts(metrics, 'reach', 'green'),
          icon: 'peopleReached',
          text: 'People Reached',
          value: reach,
        },
        totalEngagements: {
          chartValue: getGenericCharts(metrics, 'engagement', 'red'),
          icon: 'Total Engagements',
          text: 'Total Organic Engagements',
          value: engagement,
        },
        impressions: {
          chartValue: getGenericCharts(metrics, 'impressions', 'orange'),
          icon: 'Impressions',
          text: 'Total Impressions',
          value: impressions,
        },
        posts: {
          chartValue: getTotalPosts(this.posts, 'blue'),
          icon: 'posts',
          text: 'Total Posts',
          value: this.posts.length,
        },
        organicLikes: {
          chartValue: getGenericCharts(metrics, 'likes', 'blue'),
          icon: 'Organic Likes',
          text: 'Total Organic Likes',
          value: likes,
        },
        comments: {
          chartValue: getGenericCharts(metrics, 'comments', 'blue'),
          icon: 'comments',
          text: 'Total Organic Comments',
          value: comments,
        },
        saves: {
          chartValue: getGenericCharts(metrics, 'saved', 'blue'),
          icon: 'saves',
          text: 'Total Organic Saves',
          value: saved,
        },
      };
    },

    generateYoutubeData(fans, metrics) {
      const {
        views,
        estimatedMinutesWatched,
        averageViewDuration,
        averageViewPercentage,
        likes,
        subscribersGained,
        shares,
        comments,
        videosAddedToPlaylists,
        annotationImpressions,
        annotationClickableImpressions,
        cardImpressions,
        cardClicks,
      } = totalMetrics(metrics);

      return {
        totalFans: {
          chartValue: chartTotalFans(fans),
          icon: 'totalFans',
          progress: this.getProgress(chartTotalFans(fans)),
          text: 'Total Subscribers',
          value: this.totalFans,
        },
        views: {
          chartValue: getGenericCharts(metrics, 'views', 'orange'),
          icon: 'videoViews',
          text: 'Views',
          value: views,
        },
        estimatedMinutesWatched: {
          chartValue: getGenericCharts(
            metrics,
            'estimatedMinutesWatched',
            'orange'
          ),
          icon: 'videoViews',
          text: 'Watch Time (Hours)',
          value: estimatedMinutesWatched / 60,
        },
        averageViewDuration: {
          chartValue: getGenericCharts(
            metrics,
            'averageViewDuration',
            'orange'
          ),
          icon: 'videoViews',
          text: 'Average View Duration (Minutes)',
          value: averageViewDuration / 60,
        },
        likes: {
          chartValue: getGenericCharts(metrics, 'likes', 'orange'),
          icon: 'Likes',
          text: 'Total Likes',
          value: likes,
        },
        cardImpressions: {
          chartValue: getGenericCharts(metrics, 'cardImpressions', 'green'),
          icon: 'People Reached',
          text: 'Card Impressions',
          value: cardImpressions,
        },
        comments: {
          chartValue: getGenericCharts(metrics, 'comments', 'blue'),
          icon: 'comments',
          text: 'Comments',
          value: comments,
        },
        shares: {
          chartValue: getGenericCharts(metrics, 'shares', 'blue'),
          icon: 'shares',
          text: 'Shares',
          value: shares,
        },
        videosAddedToPlaylists: {
          chartValue: getGenericCharts(
            metrics,
            'videosAddedToPlaylists',
            'blue'
          ),
          icon: 'saves',
          text: 'Added to Playlists',
          value: videosAddedToPlaylists,
        },
      };
    },

    getEngagementRate(posts) {
      const metrics = posts.map(({ metrics }) => metrics).filter(Boolean);

      const engagedUsers = metrics.reduce((ac, metric) => {
        ac += metric.engagedUsers || 0;

        return ac;
      }, 0);

      const impressions = metrics.reduce((ac, metric) => {
        ac += metric.impressions || 0;

        return ac;
      }, 0);

      if (impressions > 0) {
        const engagement = (engagedUsers / impressions) * 100;

        return parseFloat(engagement).toFixed(1);
      }

      return 0;
    },

    getHour24(dateTime) {
      return format(new Date(dateTime), 'H');
    },

    getLocalHours(dateTime) {
      return format(new Date(dateTime), 'h A');
    },

    getPDT(facebookTime, hour) {
      const day = format(facebookTime, 'MM/DD/YYYY');

      return `${day} ${hour}:00:00 ${TZ_FACEBOOK}`;
    },

    getProgress(chartData) {
      const { data } = chartData.datasets[0];
      const initial = data[0] || 0;
      const final = data[data.length - 1] || 0;
      const relation = initial === 0 ? 1 : initial;

      return parseFloat(((final - initial) / relation) * 100).toFixed(1);
    },

    getTotalByGroup(obj) {
      const result = (obj || []).reduce((ac, val) => {
        if (val) {
          Object.keys(val).forEach(key => {
            if (ac[key]) {
              ac[key] += val[key];
            } else {
              ac[key] = val[key];
            }
          });
        }
        return ac;
      }, []);

      if (result.length > 0 && !result[0]) return null;

      return result;
    },

    getWeekDay(dateTime) {
      return format(new Date(dateTime), 'dddd');
    },
  },
};
