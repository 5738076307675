import {
  getEngagementRate,
  getTotalEngagement,
  getTotalReactions,
} from './utilsCommon.js';

/* Build for Work with Facebook and Instagram Data Analytics */
const sortByPostDate = posts => {
  return posts.sort(
    (a, b) => new Date(b.remotePublishAt) - new Date(a.remotePublishAt)
  );
};

const sortByMetricsDesc = (posts, field) => {
  return posts.sort(({ metrics: aMetric }, { metrics: bMetric }) => {
    return bMetric[field] - aMetric[field];
  });
};

const sortByCombinedMetricsDesc = (posts, fields) => {
  return posts.sort(({ metrics: aMetrics }, { metrics: bMetrics }) => {
    let valueInA = 0;
    fields.forEach(field => {
      if (aMetrics[field]) {
        valueInA += aMetrics[field];
      }
    });

    let valueInB = 0;
    fields.forEach(field => {
      if (bMetrics[field]) {
        valueInB += bMetrics[field];
      }
    });

    return valueInB - valueInA;
  });
};

const sortByReactionsDesc = posts => {
  return posts.sort(({ metrics: aMetrics }, { metrics: bMetrics }) => {
    const a = getTotalReactions(aMetrics.reactions);
    const b = getTotalReactions(bMetrics.reactions);

    return b - a;
  });
};

const sortByTotalEngagementsDesc = posts => {
  return posts.sort(({ metrics: aMetrics }, { metrics: bMetrics }) => {
    const a = getTotalEngagement(aMetrics);
    const b = getTotalEngagement(bMetrics);

    return b - a;
  });
};

const sortByEngamentRateDesc = posts => {
  return posts.sort(({ metrics: aMetrics }, { metrics: bMetrics }) => {
    const a = getEngagementRate(aMetrics);
    const b = getEngagementRate(bMetrics);

    return b - a;
  });
};

export const sortPostsBy = (posts = [], filter) => {
  if (filter === 'post-date') {
    return sortByPostDate(posts);
  }

  if (filter === 'post-click') {
    return sortByMetricsDesc(posts, 'clicks');
  }

  if (filter === 'comments') {
    return sortByMetricsDesc(posts, 'comments');
  }

  if (filter === 'views') {
    return sortByMetricsDesc(posts, 'views');
  }

  if (filter === 'shares') {
    return sortByMetricsDesc(posts, 'shares');
  }

  if (filter === 'likes') {
    return sortByMetricsDesc(posts, 'likes');
  }

  if (filter === 'people-reached') {
    //if is Instagram account
    const hasReach = posts.some(post => post.metrics.reach);
    if (hasReach) {
      return sortByMetricsDesc(posts, 'reach');
    }

    return sortByCombinedMetricsDesc(posts, [
      'impressions',
      'impressionsOrganic',
      'impressionsPaid',
    ]);
  }

  if (filter === 'total-reactions') {
    //if is Instagram account
    const hasReach = posts.some(post => post.metrics.likes);
    if (hasReach) {
      return sortByMetricsDesc(posts, 'likes');
    }

    return sortByReactionsDesc(posts);
  }

  if (filter === 'total-engagements') {
    //if is Instagram account
    const hasReach = posts.some(post => post.metrics.engagement);
    if (hasReach) {
      return sortByMetricsDesc(posts, 'engagement');
    }

    return sortByTotalEngagementsDesc(posts);
  }

  if (filter === 'engagement-rate') {
    const hasReach = posts.some(post => post.metrics.engagement);
    if (hasReach) {
      return sortByMetricsDesc(posts, 'engagement');
    }

    return sortByEngamentRateDesc(posts);
  }

  return [];
};
