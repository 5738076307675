export function reduceMetric(carry, metric) {
  for (let key in metric) {
    const value = metric[key];
    const isNumber = typeof value === 'number';
    const isObject = typeof value === 'object';

    if (!carry[key]) {
      carry[key] = isObject ? {} : 0;
    }

    if (isObject) {
      carry[key] = reduceMetric(carry[key], metric[key]);
    } else if (isNumber) {
      carry[key] += metric[key];
    } else {
      carry[key] = metric[key];
    }
  }

  return carry;
}
