import AnalyticsCardIcon from '@/components/AnalyticsCardIcon';
import LeftRight from '@/components/LeftRight';

export default {
  name: 'AnalyticsInsightsTable',

  components: {
    AnalyticsCardIcon,
    LeftRight,
  },

  props: {
    analytics: {
      required: true,
    },
    hasTotalFans: {
      default: false,
    },
    compact: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      unfoldSlides: [],
    };
  },

  computed: {
    simpleTable() {
      return !this.analytics.some(analytic => analytic.showMore);
    },
  },

  methods: {
    allowMore(analytic) {
      const { name, showMore } = analytic;

      return showMore && this.unfoldSlides.some(val => val === name);
    },

    openSlide(analytic) {
      const { name, showMore } = analytic;

      if (!showMore) {
        return;
      }

      if (this.unfoldSlides.some(val => val === name)) {
        this.unfoldSlides = this.unfoldSlides.filter(val => val != name);

        return;
      }

      this.unfoldSlides.unshift(name);
    },

    formatNumber(num, digits = 1) {
      return this.$filters.formatNumber(num, digits);
    },
  },
};
