export default [
  {
    name: 'urn:li:function:1',
    value: 'Accounting',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:2',
    value: 'Administrative',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:3',
    value: 'Arts and Design',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:4',
    value: 'Business Development',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:5',
    value: 'Community and Social Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:6',
    value: 'Consulting',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:7',
    value: 'Education',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:8',
    value: 'Engineering',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:9',
    value: 'Entrepreneurship',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:10',
    value: 'Finance',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:11',
    value: 'Healthcare Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:12',
    value: 'Human Resources',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:13',
    value: 'Information Technology',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:14',
    value: 'Legal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:15',
    value: 'Marketing',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:16',
    value: 'Media and Communication',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:17',
    value: 'Military and Protective Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:18',
    value: 'Operations',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:19',
    value: 'Product Management',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:20',
    value: 'Program and Project Management',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:21',
    value: 'Purchasing',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:22',
    value: 'Quality Assurance',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:23',
    value: 'Real Estate',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:24',
    value: 'Research',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:25',
    value: 'Sales',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:function:26',
    value: 'Support',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:1',
    value: 'Defense & Space',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:3',
    value: 'Computer Hardware',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:4',
    value: 'Computer Software',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:5',
    value: 'Computer Networking',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:6',
    value: 'Internet',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:7',
    value: 'Semiconductors',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:8',
    value: 'Telecommunications',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:9',
    value: 'Law Practice',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:10',
    value: 'Legal Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:11',
    value: 'Management Consulting',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:12',
    value: 'Biotechnology',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:13',
    value: 'Medical Practice',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:14',
    value: 'Hospital & Health Care',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:15',
    value: 'Pharmaceuticals',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:16',
    value: 'Veterinary',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:17',
    value: 'Medical Device',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:18',
    value: 'Cosmetics',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:19',
    value: 'Apparel & Fashion',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:20',
    value: 'Sporting Goods',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:21',
    value: 'Tobacco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:22',
    value: 'Supermarkets',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:23',
    value: 'Food Production',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:24',
    value: 'Consumer Electronics',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:25',
    value: 'Consumer Goods',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:26',
    value: 'Furniture',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:27',
    value: 'Retail',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:28',
    value: 'Entertainment',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:29',
    value: 'Gambling & Casinos',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:30',
    value: 'Leisure, Travel & Tourism',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:31',
    value: 'Hospitality',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:32',
    value: 'Restaurants',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:33',
    value: 'Sports',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:34',
    value: 'Food & Beverages',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:35',
    value: 'Motion Pictures & Film',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:36',
    value: 'Broadcast Media',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:37',
    value: 'Museums & Institutions',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:38',
    value: 'Fine Art',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:39',
    value: 'Performing Arts',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:40',
    value: 'Recreational Facilities & Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:41',
    value: 'Banking',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:42',
    value: 'Insurance',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:43',
    value: 'Financial Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:44',
    value: 'Real Estate',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:45',
    value: 'Investment Banking',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:46',
    value: 'Investment Management',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:47',
    value: 'Accounting',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:48',
    value: 'Construction',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:49',
    value: 'Building Materials',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:50',
    value: 'Architecture & Planning',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:51',
    value: 'Civil Engineering',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:52',
    value: 'Aviation & Aerospace',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:53',
    value: 'Automotive',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:54',
    value: 'Chemicals',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:55',
    value: 'Machinery',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:56',
    value: 'Mining & Metals',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:57',
    value: 'Oil & Energy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:58',
    value: 'Shipbuilding',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:59',
    value: 'Utilities',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:3131',
    value: 'Mobile Games',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:60',
    value: 'Textiles',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:61',
    value: 'Paper & Forest Products',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:62',
    value: 'Railroad Manufacture',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:63',
    value: 'Farming',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:64',
    value: 'Ranching',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:65',
    value: 'Dairy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:66',
    value: 'Fishery',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:67',
    value: 'Primary/Secondary Education',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:68',
    value: 'Higher Education',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:69',
    value: 'Education Management',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:70',
    value: 'Research',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:71',
    value: 'Military',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:72',
    value: 'Legislative Office',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:73',
    value: 'Judiciary',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:74',
    value: 'International Affairs',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:75',
    value: 'Government Administration',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:76',
    value: 'Executive Office',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:77',
    value: 'Law Enforcement',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:78',
    value: 'Public Safety',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:79',
    value: 'Public Policy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:80',
    value: 'Marketing & Advertising',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:81',
    value: 'Newspapers',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:82',
    value: 'Publishing',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:83',
    value: 'Printing',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:84',
    value: 'Information Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:85',
    value: 'Libraries',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:86',
    value: 'Environmental Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:87',
    value: 'Package/Freight Delivery',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:88',
    value: 'Individual & Family Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:89',
    value: 'Religious Institutions',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:90',
    value: 'Civic & Social Organization',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:91',
    value: 'Consumer Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:92',
    value: 'Transportation/Trucking/Railroad',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:93',
    value: 'Warehousing',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:94',
    value: 'Airlines/Aviation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:95',
    value: 'Maritime',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:96',
    value: 'Information Technology & Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:97',
    value: 'Market Research',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:98',
    value: 'Public Relations & Communications',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:99',
    value: 'Design',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:100',
    value: 'Non-profit Organization Management',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:101',
    value: 'Fundraising',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:102',
    value: 'Program Development',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:103',
    value: 'Writing & Editing',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:104',
    value: 'Staffing & Recruiting',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:105',
    value: 'Professional Training & Coaching',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:106',
    value: 'Venture Capital & Private Equity',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:107',
    value: 'Political Organization',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:108',
    value: 'Translation & Localization',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:109',
    value: 'Computer Games',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:110',
    value: 'Events Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:111',
    value: 'Arts & Crafts',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:112',
    value: 'Electrical & Electronic Manufacturing',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:113',
    value: 'Online Media',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:114',
    value: 'Nanotechnology',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:115',
    value: 'Music',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:116',
    value: 'Logistics & Supply Chain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:117',
    value: 'Plastics',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:118',
    value: 'Computer & Network Security',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:119',
    value: 'Wireless',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:120',
    value: 'Alternative Dispute Resolution',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:121',
    value: 'Security & Investigations',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:122',
    value: 'Facilities Services',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:123',
    value: 'Outsourcing/Offshoring',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:124',
    value: 'Health, Wellness & Fitness',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:125',
    value: 'Alternative Medicine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:126',
    value: 'Media Production',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:127',
    value: 'Animation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:128',
    value: 'Commercial Real Estate',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:129',
    value: 'Capital Markets',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:130',
    value: 'Think Tanks',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:131',
    value: 'Philanthropy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:132',
    value: 'E-learning',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:133',
    value: 'Wholesale',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:134',
    value: 'Import & Export',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:135',
    value: 'Mechanical Or Industrial Engineering',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:136',
    value: 'Photography',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:137',
    value: 'Human Resources',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:138',
    value: 'Business Supplies & Equipment',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:139',
    value: 'Mental Health Care',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:140',
    value: 'Graphic Design',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:141',
    value: 'International Trade & Development',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:142',
    value: 'Wine & Spirits',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:143',
    value: 'Luxury Goods & Jewelry',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:144',
    value: 'Renewables & Environment',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:145',
    value: 'Glass, Ceramics & Concrete',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:146',
    value: 'Packaging & Containers',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:147',
    value: 'Industrial Automation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:148',
    value: 'Government Relations',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:industry:150',
    value: 'Horticulture',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4',
    value: 'Abilene, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7',
    value: 'Greater Boston Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:12',
    value: 'Albany, Georgia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:14',
    value: 'Greater Chicago Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:16',
    value: 'Albany, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:20',
    value: 'Albuquerque, New Mexico Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:21',
    value: 'Cincinnati, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:22',
    value: 'Alexandria, Louisiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:24',
    value: 'Allentown, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:27',
    value: 'Altoona, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:28',
    value: 'Cleveland/Akron, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:31',
    value: 'Dallas/Fort Worth Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:32',
    value: 'Amarillo, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:34',
    value: 'Greater Denver Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:35',
    value: 'Greater Detroit Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:38',
    value: 'Anchorage, Alaska Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:42',
    value: 'Houston, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:45',
    value: 'Anniston, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:46',
    value: 'Oshkosh, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:48',
    value: 'Asheville, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:49',
    value: 'Greater Los Angeles Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:50',
    value: 'Athens, Georgia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:51',
    value: 'Orange County, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:52',
    value: 'Greater Atlanta Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:56',
    value: 'Miami/Fort Lauderdale Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:58',
    value: 'Auburn, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:60',
    value: 'Augusta, Georgia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:63',
    value: 'Greater Milwaukee Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:64',
    value: 'Austin, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:68',
    value: 'Bakersfield, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:70',
    value: 'Greater New York City Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:73',
    value: 'Bangor, Maine Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:74',
    value: 'Barnstable/Yarmouth, Massachusetts Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:76',
    value: 'Baton Rouge, Louisiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:77',
    value: 'Greater Philadelphia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:79',
    value: 'Portland, Oregon Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:82',
    value: 'Sacramento, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:83',
    value: 'Beaumont/Port Arthur, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:84',
    value: 'San Francisco Bay Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:86',
    value: 'Bellingham, Washington Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:87',
    value: 'Benton Harbor, Michigan Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:88',
    value: 'Billings, Montana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:91',
    value: 'Greater Seattle Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:92',
    value: 'Biloxi, Mississippi Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:96',
    value: 'Ithaca, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:97',
    value: 'Washington D.C. Metro Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:100',
    value: 'Birmingham, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:101',
    value: 'Bismarck, North Dakota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:102',
    value: 'Bloomington, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:104',
    value: 'Bloomington/Normal, Illinois Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:108',
    value: 'Boise, Idaho Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:124',
    value: 'Brownsville, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:126',
    value: 'Bryan/College Station, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:128',
    value: 'Buffalo/Niagara, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:130',
    value: 'Burlington, Vermont Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:132',
    value: 'Canton, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:135',
    value: 'Casper, Wyoming Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:136',
    value: 'Cedar Rapids, Iowa Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:140',
    value: 'Urbana-Champaign, Illinois Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:144',
    value: 'Charleston, South Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:148',
    value: 'Charleston, West Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:152',
    value: 'Charlotte, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:154',
    value: 'Charlottesville, Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:156',
    value: 'Chattanooga, Tennessee Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:158',
    value: 'Cheyenne, Wyoming Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:162',
    value: 'Chico, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:166',
    value: 'Clarksville, Tennessee Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:172',
    value: 'Colorado Springs, Colorado Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:174',
    value: 'Columbia, Missouri Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:176',
    value: 'Columbia, South Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:180',
    value: 'Columbus, Georgia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:184',
    value: 'Columbus, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:188',
    value: 'Corpus Christi, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:189',
    value: 'Corvallis, Oregon Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:190',
    value: 'Cumberland, Maryland Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:195',
    value: 'Danville, Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:196',
    value: 'Davenport, Iowa Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:200',
    value: 'Dayton, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:202',
    value: 'Daytona Beach, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:203',
    value: 'Decatur, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:204',
    value: 'Decatur, Illinois Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:212',
    value: 'Des Moines, Iowa Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:218',
    value: 'Dothan, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:219',
    value: 'Dover, Delaware Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:220',
    value: 'Dubuque, Iowa Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:224',
    value: 'Duluth, Minnesota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:229',
    value: 'Eau Claire, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:232',
    value: 'El Paso, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:233',
    value: 'Elkhart, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:234',
    value: 'Elmira, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:235',
    value: 'Enid, Oklahoma Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:236',
    value: 'Erie, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:240',
    value: 'Eugene, Oregon Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:244',
    value: 'Evansville, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:252',
    value: 'Fargo, North Dakota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:256',
    value: 'Fayetteville, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:258',
    value: 'Fayetteville, Arkansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:262',
    value: 'Flagstaff, Arizona Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:265',
    value: 'Florence, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:266',
    value: 'Florence, South Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:267',
    value: 'Fort Collins, Colorado Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:270',
    value: 'Fort Myers, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:271',
    value: 'Fort Pierce, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:272',
    value: 'Fort Smith, Arkansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:275',
    value: 'Destin/Fort Walton Beach, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:276',
    value: 'Fort Wayne, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:284',
    value: 'Fresno, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:288',
    value: 'Gadsden, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:290',
    value: 'Gainesville, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:292',
    value: 'Glens Falls, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:294',
    value: 'Goldsboro, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:296',
    value: 'Grand Forks, North Dakota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:298',
    value: 'Grand Junction, Colorado Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:300',
    value: 'Greater Grand Rapids, Michigan Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:304',
    value: 'Great Falls, Montana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:308',
    value: 'Green Bay, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:312',
    value: 'Greensboro/Winston-Salem, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:315',
    value: 'Greenville, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:316',
    value: 'Greenville, South Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:324',
    value: 'Harrisburg, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:327',
    value: 'Hartford, Connecticut Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:328',
    value: 'Hattiesburg, Mississippi Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:329',
    value: 'Hickory/Lenoir, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:332',
    value: 'Hawaiian Islands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:335',
    value: 'Houma, Louisiana',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:340',
    value: 'Huntington, West Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:344',
    value: 'Huntsville, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:348',
    value: 'Indianapolis, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:350',
    value: 'Iowa City, Iowa Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:352',
    value: 'Jackson, Michigan Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:356',
    value: 'Jackson, Mississippi Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:358',
    value: 'Jackson, Tennessee Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:359',
    value: 'Jacksonville, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:360',
    value: 'Jacksonville, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:361',
    value: 'Jamestown, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:362',
    value: 'Janesville/Beloit, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:366',
    value: 'Johnson City, Tennessee Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:368',
    value: 'Johnstown, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:370',
    value: 'Jonesboro, Arkansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:371',
    value: 'Joplin, Missouri Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:372',
    value: 'Kalamazoo, Michigan Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:376',
    value: 'Kansas City, Missouri Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:381',
    value: 'Killeen/Temple, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:384',
    value: 'Knoxville, Tennessee Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:385',
    value: 'Kokomo, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:387',
    value: 'La Crosse, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:388',
    value: 'Lafayette, Louisiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:392',
    value: 'Lafayette, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:396',
    value: 'Lake Charles, Louisiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:398',
    value: 'Lakeland, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:400',
    value: 'Lancaster, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:404',
    value: 'Lansing, Michigan Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:408',
    value: 'Laredo, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:410',
    value: 'Las Cruces, New Mexico Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:412',
    value: 'Las Vegas, Nevada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:415',
    value: 'Lawrence, Kansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:420',
    value: 'Lawton, Oklahoma Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:424',
    value: 'Lewiston/Auburn, Maine Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:428',
    value: 'Lexington, Kentucky Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:432',
    value: 'Lima, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:436',
    value: 'Lincoln, Nebraska Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:440',
    value: 'Little Rock, Arkansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:442',
    value: 'Longview, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:452',
    value: 'Louisville, Kentucky Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:460',
    value: 'Lubbock, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:464',
    value: 'Lynchburg, Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:468',
    value: 'Macon, Georgia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:472',
    value: 'Madison, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:480',
    value: 'Mansfield, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:488',
    value: 'McAllen, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:489',
    value: 'Medford, Oregon Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:490',
    value: 'Melbourne, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:492',
    value: 'Greater Memphis Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:494',
    value: 'Merced, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:512',
    value: 'Greater Minneapolis-St. Paul Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:514',
    value: 'Missoula, Montana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:516',
    value: 'Mobile, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:517',
    value: 'Modesto, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:520',
    value: 'Monroe, Louisiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:524',
    value: 'Montgomery, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:528',
    value: 'Muncie, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:533',
    value: 'Myrtle Beach, South Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:534',
    value: 'Naples, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:536',
    value: 'Greater Nashville Area, TN',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:552',
    value: 'New London/Norwich, Connecticut Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:556',
    value: 'Greater New Orleans Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:572',
    value: 'Norfolk, Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:579',
    value: 'Ocala, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:580',
    value: 'Odessa/Midland, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:588',
    value: 'Oklahoma City, Oklahoma Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:592',
    value: 'Greater Omaha Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:596',
    value: 'Orlando, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:599',
    value: 'Owensboro, Kentucky Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:601',
    value: 'Panama City, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:602',
    value: 'Parkersburg, West Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:608',
    value: 'Pensacola, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:612',
    value: 'Peoria, Illinois Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:620',
    value: 'Phoenix, Arizona Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:624',
    value: 'Pine Bluff, Arkansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:628',
    value: 'Greater Pittsburgh Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:632',
    value: 'Pittsfield, Massachusetts Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:634',
    value: 'Pocatello, Idaho Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:640',
    value: 'Portland, Maine Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:648',
    value: 'Providence, Rhode Island Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:652',
    value: 'Provo, Utah Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:656',
    value: 'Pueblo, Colorado Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:658',
    value: 'Punta Gorda, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:664',
    value: 'Raleigh-Durham, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:666',
    value: 'Rapid City, South Dakota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:668',
    value: 'Reading, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:669',
    value: 'Redding, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:672',
    value: 'Reno, Nevada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:674',
    value: 'Richland/Kennewick/Pasco, Washington Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:676',
    value: 'Richmond, Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:680',
    value: 'Roanoke, Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:682',
    value: 'Rochester, Minnesota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:684',
    value: 'Rochester, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:688',
    value: 'Rockford, Illinois Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:689',
    value: 'Rocky Mount, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:696',
    value: 'Saginaw, Michigan Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:698',
    value: 'St. Cloud, Minnesota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:700',
    value: 'St. Joseph, Missouri Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:704',
    value: 'Greater St. Louis Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:712',
    value: 'Salinas, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:716',
    value: 'Greater Salt Lake City Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:720',
    value: 'San Angelo, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:724',
    value: 'San Antonio, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:732',
    value: 'Greater San Diego Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:746',
    value: 'San Luis Obispo, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:748',
    value: 'Santa Barbara, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:749',
    value: 'Santa Fe, New Mexico Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:751',
    value: 'Sarasota, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:752',
    value: 'Savannah, Georgia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:756',
    value: 'Scranton, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:761',
    value: 'Sharon, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:762',
    value: 'Sheboygan, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:764',
    value: 'Sherman/Denison, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:768',
    value: 'Shreveport, Louisiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:772',
    value: 'Sioux City, Iowa Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:776',
    value: 'Sioux Falls, South Dakota Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:780',
    value: 'South Bend, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:784',
    value: 'Spokane, Washington Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:788',
    value: 'Springfield, Illinois Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:792',
    value: 'Springfield, Missouri Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:800',
    value: 'Springfield, Massachusetts Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:805',
    value: 'State College, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:808',
    value: 'Steubenville, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:812',
    value: 'Stockton, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:814',
    value: 'Sumter, South Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:816',
    value: 'Syracuse, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:824',
    value: 'Tallahassee, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:828',
    value: 'Tampa/St. Petersburg, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:832',
    value: 'Terre Haute, Indiana Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:836',
    value: 'Texarkana, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:840',
    value: 'Toledo, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:844',
    value: 'Topeka, Kansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:852',
    value: 'Tucson, Arizona Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:856',
    value: 'Tulsa, Oklahoma Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:860',
    value: 'Tuscaloosa, Alabama Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:864',
    value: 'Tyler, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:868',
    value: 'Utica, New York Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:875',
    value: 'Victoria, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:878',
    value: 'Visalia, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:880',
    value: 'Waco, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:892',
    value: 'Waterloo, Iowa Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:894',
    value: 'Wausau, Wisconsin Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:896',
    value: 'West Palm Beach, Florida Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:900',
    value: 'Wheeling, West Virginia Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:904',
    value: 'Wichita, Kansas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:908',
    value: 'Wichita Falls, Texas Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:914',
    value: 'Williamsport, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:920',
    value: 'Wilmington, North Carolina Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:926',
    value: 'Yakima, Washington Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:928',
    value: 'York, Pennsylvania Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:932',
    value: 'Youngstown, Ohio Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:934',
    value: 'Yuba City, California Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:936',
    value: 'Yuma, Arizona Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:982',
    value: 'US Military Posts in Europe',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:983',
    value: 'US Military Posts in Central America',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:984',
    value: 'US Military Posts in the Pacific',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:1129',
    value: 'Hong Kong',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4542',
    value: 'Aberdeen, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4543',
    value: 'St Albans, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4544',
    value: 'Birmingham, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4545',
    value: 'Bath, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4546',
    value: 'Blackburn, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4547',
    value: 'Bradford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4548',
    value: 'Bournemouth, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4549',
    value: 'Bolton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4550',
    value: 'Brighton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4551',
    value: 'Bromley, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4552',
    value: 'Bristol, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4553',
    value: 'Belfast, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4554',
    value: 'Carlisle, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4555',
    value: 'Cambridge, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4556',
    value: 'Cardiff, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4557',
    value: 'Chester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4558',
    value: 'Chelmsford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4559',
    value: 'Colchester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4560',
    value: 'Croydon, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4561',
    value: 'Canterbury, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4562',
    value: 'Coventry, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4563',
    value: 'Crewe, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4564',
    value: 'Dartford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4565',
    value: 'Dundee, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4566',
    value: 'Derby, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4567',
    value: 'Dumfries, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4568',
    value: 'Durham, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4569',
    value: 'Darlington, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4570',
    value: 'Doncaster, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4571',
    value: 'Dorchester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4572',
    value: 'Dudley, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4573',
    value: 'London, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4574',
    value: 'Edinburgh, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4575',
    value: 'Enfield, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4576',
    value: 'Exeter, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4577',
    value: 'Falkirk, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4578',
    value: 'Blackpool, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4579',
    value: 'Glasgow, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4580',
    value: 'Gloucester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4581',
    value: 'Guernsey, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4582',
    value: 'Guildford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4583',
    value: 'Harrow, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4584',
    value: 'Huddersfield, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4585',
    value: 'Harrogate, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4586',
    value: 'Hemel Hempstead, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4587',
    value: 'Hereford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4588',
    value: 'Outer Hebrides, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4589',
    value: 'Hull, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4590',
    value: 'Halifax, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4591',
    value: 'Isle of Man, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4592',
    value: 'Ilford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4593',
    value: 'Ipswich, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4594',
    value: 'Inverness, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4595',
    value: 'Jersey, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4596',
    value: 'Kilmarnock, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4597',
    value: 'Kingston upon Thames, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4598',
    value: 'Kirkwall, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4599',
    value: 'Kirkcaldy, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4600',
    value: 'Liverpool, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4601',
    value: 'Lancaster, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4602',
    value: 'Llandrindod Wells, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4603',
    value: 'Leicester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4604',
    value: 'Llandudno, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4605',
    value: 'Lincoln, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4606',
    value: 'Leeds, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4607',
    value: 'Luton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4608',
    value: 'Manchester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4609',
    value: 'Rochester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4610',
    value: 'Milton Keynes, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4611',
    value: 'Motherwell, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4612',
    value: 'Newcastle upon Tyne, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4613',
    value: 'Nottingham, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4614',
    value: 'Northampton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4615',
    value: 'Newport, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4616',
    value: 'Norwich, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4617',
    value: 'Oldham, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4618',
    value: 'Oxford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4619',
    value: 'Paisley, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4620',
    value: 'Peterborough, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4621',
    value: 'Perth, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4622',
    value: 'Plymouth, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4623',
    value: 'Portsmouth, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4624',
    value: 'Preston, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4625',
    value: 'Reading, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4626',
    value: 'Redhill, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4627',
    value: 'Romford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4628',
    value: 'Sheffield, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4629',
    value: 'Swansea, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4630',
    value: 'Stevenage, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4631',
    value: 'Stockport, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4632',
    value: 'Slough, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4633',
    value: 'Sutton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4634',
    value: 'Swindon, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4635',
    value: 'Southampton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4636',
    value: 'Salisbury, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4637',
    value: 'Sunderland, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4638',
    value: 'Southend on Sea, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4639',
    value: 'Stoke-on-Trent, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4640',
    value: 'Shrewsbury, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4641',
    value: 'Taunton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4642',
    value: 'Galashiels, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4643',
    value: 'Telford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4644',
    value: 'Tonbridge, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4645',
    value: 'Torquay, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4646',
    value: 'Truro, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4647',
    value: 'Cleveland, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4648',
    value: 'Twickenham, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4649',
    value: 'Southall, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4650',
    value: 'Warrington, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4651',
    value: 'Watford, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4652',
    value: 'Wakefield, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4653',
    value: 'Wigan, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4654',
    value: 'Worcester, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4655',
    value: 'Walsall, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4656',
    value: 'Wolverhampton, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4657',
    value: 'York, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4658',
    value: 'Lerwick, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4863',
    value: 'Montreal, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4864',
    value: 'Ontario, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4865',
    value: 'Kitchener, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4866',
    value: 'Winnipeg, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4867',
    value: 'Prince Edward Island, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4868',
    value: 'New Brunswick, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4869',
    value: 'London, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4870',
    value: 'Yukon, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4871',
    value: 'Alberta, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4872',
    value: 'Edmonton, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4873',
    value: 'British Columbia, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4874',
    value: 'Halifax, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4875',
    value: 'Quebec, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4876',
    value: 'Toronto, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4877',
    value: 'Manitoba, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4878',
    value: 'Saskatchewan, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4879',
    value: 'Newfoundland And Labrador, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4880',
    value: 'Vancouver, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4881',
    value: 'Nova Scotia, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4882',
    value: 'Calgary, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4883',
    value: 'Nunavut, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4884',
    value: 'Ottawa, Canada Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4885',
    value: 'Northwest Territories, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4886',
    value: 'Adelaide, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4887',
    value: 'Albury, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4888',
    value: 'Australian Capital Territory, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4889',
    value: 'Ballarat, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4890',
    value: 'Bendigo, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4891',
    value: 'Bundaberg, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4892',
    value: 'Cairns, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4893',
    value: 'Canberra, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4894',
    value: 'Coffs Harbour, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4895',
    value: 'Darwin, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4896',
    value: 'Geelong West, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4897',
    value: 'Launceston, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4898',
    value: 'Mackay, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4899',
    value: 'Mandurah, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4900',
    value: 'Melbourne, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4901',
    value: 'New South Wales, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4902',
    value: 'Newcastle, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4903',
    value: 'Northern Territory, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4904',
    value: 'Nowra, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4905',
    value: 'Perth, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4906',
    value: 'Queensland, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4907',
    value: 'Rockhampton, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4908',
    value: 'South Australia, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4909',
    value: 'Brisbane, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4910',
    value: 'Sydney, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4911',
    value: 'Tasmania, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4912',
    value: 'Toowoomba, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4913',
    value: 'Townsville, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4914',
    value: 'Victoria, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4915',
    value: 'Wagga Wagga, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4916',
    value: 'Western Australia, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4917',
    value: 'Wollongong, Australia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4918',
    value: 'Antwerp Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4919',
    value: 'Bruges Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4920',
    value: 'Brussels Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4921',
    value: 'Charleroi Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4922',
    value: 'Gent Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4923',
    value: 'Liège Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4924',
    value: 'Luxembourg, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4925',
    value: 'Namur Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4926',
    value: 'Seraing Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4927',
    value: 'Verviers Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4928',
    value: 'Basel Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4929',
    value: 'Bern Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4930',
    value: 'Geneva Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4931',
    value: 'Graubunden, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4932',
    value: 'Lausanne Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4933',
    value: 'Lucerne Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4934',
    value: 'Sankt Gallen Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4935',
    value: 'Ticino, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4936',
    value: 'Valais, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4937',
    value: 'Winterthur Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4938',
    value: 'Zürich Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4939',
    value: 'Aalen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4940',
    value: 'Augsburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4941',
    value: 'Bamberg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4942',
    value: 'Bayern, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4943',
    value: 'Bayreuth Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4944',
    value: 'Berlin Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4945',
    value: 'Bielefeld Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4946',
    value: 'Bochum Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4947',
    value: 'Bonn Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4948',
    value: 'Brandenburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4949',
    value: 'Braunschweig Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4950',
    value: 'Bremen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4951',
    value: 'Bremerhaven Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4952',
    value: 'Chemnitz Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4953',
    value: 'Cologne Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4954',
    value: 'Cottbus Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4955',
    value: 'Cuxhaven Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4956',
    value: 'Darmstadt Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4957',
    value: 'Dortmund Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4958',
    value: 'Dresden Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4959',
    value: 'Duisburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4960',
    value: 'Düsseldorf Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4961',
    value: 'Emden Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4962',
    value: 'Erfurt Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4963',
    value: 'Erlangen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4964',
    value: 'Essen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4965',
    value: 'Flensburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4966',
    value: 'Frankfurt Am Main Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4967',
    value: 'Frankfurt Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4968',
    value: 'Freiburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4969',
    value: 'Fulda Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4970',
    value: 'Fürth Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4971',
    value: 'Gera Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4972',
    value: 'Greifswald Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4973',
    value: 'Görlitz Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4974',
    value: 'Göttingen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4975',
    value: 'Hagen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4976',
    value: 'Halle-Neustadt Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4977',
    value: 'Hamburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4978',
    value: 'Hamm Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4979',
    value: 'Hanau Am Main Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4980',
    value: 'Hannover Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4981',
    value: 'Heidelberg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4982',
    value: 'Heilbronn Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4983',
    value: 'Jena Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4984',
    value: 'Karlsruhe Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4985',
    value: 'Kassel Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4986',
    value: 'Kempten (Allgäu) Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4987',
    value: 'Kiel Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4988',
    value: 'Koblenz Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4989',
    value: 'Konstanz Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4990',
    value: 'Kreisfreie Stadt Aachen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4991',
    value: 'Landkreis Ostprignitz-Ruppin, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4992',
    value: 'Landkreis Prignitz, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4993',
    value: 'Landkreis Uckermark, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4994',
    value: 'Landshut Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4995',
    value: 'Leipzig Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4996',
    value: 'Lübeck Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4997',
    value: 'Mainz Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4998',
    value: 'Mannheim Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:4999',
    value: 'Mecklenburg-Vorpommern, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5000',
    value: 'Munich Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5001',
    value: 'Mönchengladbach Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5002',
    value: 'Münster Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5003',
    value: 'Neubrandenburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5004',
    value: 'Neumünster Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5005',
    value: 'Neustadt Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5006',
    value: 'Nordhorn Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5007',
    value: 'Nürnberg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5008',
    value: 'Offenbach Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5009',
    value: 'Oldenburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5010',
    value: 'Osnabrück Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5011',
    value: 'Paderborn Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5012',
    value: 'Passau Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5013',
    value: 'Pforzheim Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5014',
    value: 'Plauen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5015',
    value: 'Potsdam Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5016',
    value: 'Regensburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5017',
    value: 'Reutlingen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5018',
    value: 'Rheine Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5019',
    value: 'Rosenheim Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5020',
    value: 'Rostock Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5021',
    value: 'Saarbrücken Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5022',
    value: 'Schleswig-Holstein, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5023',
    value: 'Schwerin Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5024',
    value: 'Siegen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5025',
    value: 'Stralsund Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5026',
    value: 'Stuttgart Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5027',
    value: 'Trier Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5028',
    value: 'Tübingen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5029',
    value: 'Ulm Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5030',
    value: 'Villingen-Schwenningen Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5031',
    value: 'Wahren Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5032',
    value: 'Wiesbaden Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5033',
    value: 'Wilhelmshaven Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5034',
    value: 'Wolfsburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5035',
    value: 'Wuppertal Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5036',
    value: 'Würzburg Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5037',
    value: 'Zwickau Area, Germany',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5038',
    value: 'Copenhagen Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5039',
    value: 'Esbjerg Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5040',
    value: 'Kolding Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5041',
    value: 'Odense Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5042',
    value: 'Randers Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5043',
    value: 'Vejle Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5044',
    value: 'Ålborg Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5045',
    value: 'Århus Area, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5046',
    value: 'A Coruña Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5047',
    value: 'Alacant Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5048',
    value: 'Albacete Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5049',
    value: 'Albacete, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5050',
    value: 'Alcalá De Henares Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5051',
    value: 'Alcobendas Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5052',
    value: 'Alcorcón Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5053',
    value: 'Algeciras Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5054',
    value: 'Almería Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5055',
    value: 'Aragon, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5056',
    value: 'Arona Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5057',
    value: 'Arrecife Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5058',
    value: 'Asturias, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5059',
    value: 'Avilés Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5060',
    value: 'Badajoz Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5061',
    value: 'Badajoz, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5062',
    value: 'Badalona Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5063',
    value: 'Baleares, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5064',
    value: 'Barcelona Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5065',
    value: 'Bilbao Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5066',
    value: 'Burgos Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5067',
    value: 'Burgos, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5068',
    value: 'Cartagena Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5069',
    value: 'Castellón, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5070',
    value: 'Castilla - La Mancha, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5071',
    value: 'Ceuta, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5072',
    value: 'Ciudad Real Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5073',
    value: 'Ciudad Real, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5074',
    value: 'Cl, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5075',
    value: 'Cuenca, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5076',
    value: 'Cáceres Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5077',
    value: 'Cáceres, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5078',
    value: 'Cádiz, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5079',
    value: 'Córdoba Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5080',
    value: 'Córdoba, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5081',
    value: 'Elx / Elche Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5082',
    value: 'Fuenlabrada Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5083',
    value: 'Ga, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5084',
    value: 'Gijón Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5085',
    value: 'Girona, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5086',
    value: 'Granada Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5087',
    value: 'Granada, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5088',
    value: 'Granollers Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5089',
    value: 'Groa De Murviedro Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5090',
    value: 'Guadalajara Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5091',
    value: 'Guadalajara, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5092',
    value: 'Huelva Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5093',
    value: 'Huelva, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5094',
    value: 'Huesca, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5095',
    value: 'Illes Balears, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5096',
    value: 'Pamplona Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5097',
    value: 'Jaén Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5098',
    value: 'Jaén, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5099',
    value: 'Jerez De La Frontera Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5100',
    value: 'L’Hospitalet De Llobregat Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5101',
    value: 'La Rioja, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5102',
    value: 'Las Palmas De Gran Canaria Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5103',
    value: 'Las Palmas, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5104',
    value: 'Las Rozas De Madrid Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5105',
    value: 'Leganés Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5106',
    value: 'León Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5107',
    value: 'León, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5108',
    value: 'Linares Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5109',
    value: 'Lleida, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5110',
    value: 'Logroño Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5111',
    value: 'Lorca Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5112',
    value: 'Lugo Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5113',
    value: 'Madrid Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5114',
    value: 'Manresa Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5115',
    value: 'Marbella Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5116',
    value: 'Mataró Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5117',
    value: 'Melilla Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5118',
    value: 'Murcia Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5119',
    value: 'Málaga Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5120',
    value: 'Mérida Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5121',
    value: 'Móstoles Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5122',
    value: 'Navarra, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5123',
    value: 'Ourense Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5124',
    value: 'Oviedo Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5125',
    value: 'Palencia Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5126',
    value: 'Palencia, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5127',
    value: 'Palma Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5128',
    value: 'Parla Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5129',
    value: 'Paterna Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5130',
    value: 'Ponferrada Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5131',
    value: 'Pozuelo De Alarcón Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5132',
    value: 'Reus Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5133',
    value: 'Sabadell Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5134',
    value: 'Salamanca Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5135',
    value: 'Salamanca, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5136',
    value: 'Santa Cruz De Tenerife Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5137',
    value: 'Santa Cruz De Tenerife, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5138',
    value: 'Santander Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5139',
    value: 'Santiago De Compostela Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5140',
    value: 'Segovia Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5141',
    value: 'Segovia, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5142',
    value: 'Sevilla Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5143',
    value: 'Soria, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5144',
    value: 'Talavera De La Reina Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5145',
    value: 'Tarragona Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5146',
    value: 'Tarragona, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5147',
    value: 'Teruel, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5148',
    value: 'Toledo Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5149',
    value: 'Toledo, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5150',
    value: 'Torrelavega Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5151',
    value: 'Torrent Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5152',
    value: 'Valencia Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5153',
    value: 'Valencia, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5154',
    value: 'Valladolid Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5155',
    value: 'Vc, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5156',
    value: 'Vigo Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5157',
    value: 'Vitoria-Gasteiz Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5158',
    value: 'Zamora Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5159',
    value: 'Zamora, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5160',
    value: 'Zaragoza Area, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5161',
    value: 'Zaragoza, Spain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5162',
    value: 'Aix-En-Provence Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5163',
    value: 'Ajaccio Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5164',
    value: 'Albi Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5165',
    value: 'Amiens Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5166',
    value: 'Angers Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5167',
    value: 'Aquitaine, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5168',
    value: 'Aulnay-Sous-Bois Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5169',
    value: 'Auvergne, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5170',
    value: 'Avignon Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5171',
    value: 'Basse-Normandie, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5172',
    value: 'Besançon Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5173',
    value: 'Blois Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5174',
    value: 'Bordeaux Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5175',
    value: 'Boulogne-Billancourt Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5176',
    value: 'Bourges Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5177',
    value: 'Bourgogne, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5178',
    value: 'Brest Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5179',
    value: 'Bretagne, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5180',
    value: 'Brive-La-Gaillarde Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5181',
    value: 'Béziers Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5182',
    value: 'Caen Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5183',
    value: 'Calais Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5184',
    value: 'Cannes Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5185',
    value: 'Centre, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5186',
    value: 'Chambéry Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5187',
    value: 'Champagne-Ardenne, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5188',
    value: 'Champigny-Sur-Marne Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5189',
    value: 'Charleville-Mézières Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5190',
    value: 'Châlons-Sur-Marne Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5191',
    value: 'Châteauroux Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5192',
    value: 'Clermont-Ferrand Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5193',
    value: 'Colmar Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5194',
    value: 'Corse, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5195',
    value: 'Créteil Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5196',
    value: 'Dijon Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5197',
    value: 'Franche-Comte, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5198',
    value: 'Fréjus Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5199',
    value: 'Grenoble Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5200',
    value: 'La Rochelle Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5201',
    value: 'Languedoc-Roussillon, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5202',
    value: 'Laval Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5203',
    value: 'Le Havre Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5204',
    value: 'Le Mans Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5205',
    value: 'Lille Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5206',
    value: 'Limoges Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5207',
    value: 'Limousin, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5208',
    value: 'Lorient Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5209',
    value: 'Lorraine, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5210',
    value: 'Lyon Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5211',
    value: 'Marseille Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5212',
    value: 'Meaux Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5213',
    value: 'Metz Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5214',
    value: 'Midi-Pyrenees, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5215',
    value: 'Montauban Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5216',
    value: 'Montpellier Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5217',
    value: 'Montreuil Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5218',
    value: 'Mulhouse Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5219',
    value: 'Nancy Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5220',
    value: 'Nantes Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5221',
    value: 'Nice Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5222',
    value: 'Niort Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5223',
    value: 'Noisy-Le-Grand Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5224',
    value: 'Nord - Pas-De-Calais, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5225',
    value: 'Nîmes Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5226',
    value: 'Orléans Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5227',
    value: 'Paris Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5228',
    value: 'Pau Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5229',
    value: 'Perpignan (Perpinyà) Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5230',
    value: 'Pessac Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5231',
    value: 'Picardie, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5232',
    value: 'Poitiers Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5233',
    value: 'Poitou-Charentes, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5234',
    value: 'Provence-Alpes-Cote D’Azur, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5235',
    value: 'Quimper Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5236',
    value: 'Reims Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5237',
    value: 'Rennes Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5238',
    value: 'Rhone-Alpes, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5239',
    value: 'Rouen Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5240',
    value: 'Saint-Brieuc Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5241',
    value: 'Saint-Denis Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5242',
    value: 'Saint-Malo Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5243',
    value: 'Saint-Nazaire Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5244',
    value: 'Saint-Quentin Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5245',
    value: 'Saint-Étienne Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5246',
    value: 'Strasbourg Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5247',
    value: 'Tarbes Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5248',
    value: 'Toulon Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5249',
    value: 'Toulouse Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5250',
    value: 'Tours Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5251',
    value: 'Troyes Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5252',
    value: 'Valence Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5253',
    value: 'Vannes Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5254',
    value: 'Versailles Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5255',
    value: 'Villeurbanne Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5256',
    value: 'Évreux Area, France',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5260',
    value: 'Ahmadābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5263',
    value: 'Allahābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5265',
    value: 'Alīgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5266',
    value: 'Ambikāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5269',
    value: 'Amrāvati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5271',
    value: 'Andaman And Nicobar, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5273',
    value: 'Arunachal Pradesh, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5275',
    value: 'Bada Barabīl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5280',
    value: 'Balāngīr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5281',
    value: 'Bengaluru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5282',
    value: 'Barddhamān Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5285',
    value: 'Belagavi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5286',
    value: 'Ballari Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5287',
    value: 'Benares Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5288',
    value: 'Beāwar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5290',
    value: 'Bhadrāvati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5292',
    value: 'Bharūch Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5294',
    value: 'Bhawānipatna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5296',
    value: 'Bhiwāni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5297',
    value: 'Bhopāl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5300',
    value: 'Bhāgalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5301',
    value: 'Bhātpāra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5302',
    value: 'Bhātāpāra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5303',
    value: 'Bhāvnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5304',
    value: 'Bhīlwāra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5305',
    value: 'Bihar, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5306',
    value: 'Bijāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5307',
    value: 'Bilāspur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5309',
    value: 'Bokāro Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5312',
    value: 'Botād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5315',
    value: 'Burhānpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5316',
    value: 'Bāgalkot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5317',
    value: 'Bālotra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5318',
    value: 'Bālāghāt Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5320',
    value: 'Bānswāra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5321',
    value: 'Bāramūla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5322',
    value: 'Bārmer Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5323',
    value: 'Bārsi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5324',
    value: 'Bārān Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5325',
    value: 'Bīdar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5326',
    value: 'Bīkāner Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5330',
    value: 'Chandīgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5331',
    value: 'Chattisgarh, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5333',
    value: 'Chhindwāra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5335',
    value: 'Chāībāsa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5336',
    value: 'Chūru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5341',
    value: 'Dehra Dūn Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5343',
    value: 'Delhi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5344',
    value: 'Dewās Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5346',
    value: 'Dhanbād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5347',
    value: 'Dhenkānāl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5349',
    value: 'Dhār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5351',
    value: 'Dimāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5353',
    value: 'Durgāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5354',
    value: 'Dāhod Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5355',
    value: 'Dāltenganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5356',
    value: 'Elūru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5357',
    value: 'Etāwah Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5358',
    value: 'Faizābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5359',
    value: 'Farrukhābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5361',
    value: 'Fīrozpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5362',
    value: 'Fīrozābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5363',
    value: 'Gangānagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5364',
    value: 'Gangāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5365',
    value: 'Gangāwati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5367',
    value: 'Ghāziābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5371',
    value: 'Gujarat, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5372',
    value: 'Kalburgi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5374',
    value: 'Guntūr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5375',
    value: 'Guwāhāti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5378',
    value: 'Haridwār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5380',
    value: 'Hazārībāg Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5381',
    value: 'Himachal Pradesh, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5385',
    value: 'Hisār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5386',
    value: 'Hoshangābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5387',
    value: 'Hoshiārpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5390',
    value: 'Hyderābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5391',
    value: 'Hāora Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5393',
    value: 'Imphāl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5395',
    value: 'Ingrāj Bāzār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5396',
    value: 'Itārsi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5399',
    value: 'Jagtiāl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5403',
    value: 'Jalpāiguri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5404',
    value: 'Jammu And Kashmir, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5407',
    value: 'Jatani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5410',
    value: 'Jharkhand, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5411',
    value: 'Jhunjhunūn Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5412',
    value: 'Jhālāwār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5413',
    value: 'Jhānsi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5414',
    value: 'Jhārsuguda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5416',
    value: 'Jorhāt Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5417',
    value: 'Jālgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5418',
    value: 'Jālna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5419',
    value: 'Jāmnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5420',
    value: 'Jūnāgadh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5421',
    value: 'Karnataka, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5422',
    value: 'Karīmganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5423',
    value: 'Karīmnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5424',
    value: 'Katihār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5428',
    value: 'Koch Bihār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5429',
    value: 'Kohīma Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5430',
    value: 'Kolhāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5431',
    value: 'Kolār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5435',
    value: 'Kānpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5436',
    value: 'Kārwār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5438',
    value: 'Lohārdaga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5440',
    value: 'Ludhiāna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5442',
    value: 'Lātūr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5443',
    value: 'Madhya Pradesh, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5446',
    value: 'Mahbūbnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5447',
    value: 'Mahāsamund Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5448',
    value: 'Makrāna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5457',
    value: 'Morena Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5458',
    value: 'Morādābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5459',
    value: 'Murwāra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5461',
    value: 'Mysuru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5462',
    value: 'Mālegaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5463',
    value: 'Nadiād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5465',
    value: 'Nandurbār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5466',
    value: 'Nandyāl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5467',
    value: 'Nangi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5470',
    value: 'Nizāmābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5472',
    value: 'Nāgaur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5473',
    value: 'Nāgpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5474',
    value: 'Nāsik Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5477',
    value: 'Orisa, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5481',
    value: 'Pathānkot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5482',
    value: 'Patiāla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5483',
    value: 'Puducherry Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5486',
    value: 'Proddatūr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5489',
    value: 'Punāsa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5490',
    value: 'Pālanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5491',
    value: 'Pāli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5492',
    value: 'Pāloncha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5493',
    value: 'Pānīpat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5494',
    value: 'Pātan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5495',
    value: 'Pūrnia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5498',
    value: 'Rajasthan, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5499',
    value: 'Ratlām Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5500',
    value: 'Ratnāgiri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5501',
    value: 'Renukūt Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5505',
    value: 'Rāe Bareli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5506',
    value: 'Rāichūr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5507',
    value: 'Rāiganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5508',
    value: 'Rāj Nāndgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5509',
    value: 'Rājahmundry Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5510',
    value: 'Rājkot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5512',
    value: 'Rāmpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5513',
    value: 'Rānībennur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5514',
    value: 'Sahāranpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5517',
    value: 'Sardārshahr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5519',
    value: 'Seonī Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5524',
    value: 'Shivamoga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5526',
    value: 'Shivpurī Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5527',
    value: 'Shāhjahānpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5528',
    value: 'Shājāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5529',
    value: 'Sibsāgar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5533',
    value: 'Solāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5534',
    value: 'Srīkākulam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5535',
    value: 'Srīnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5538',
    value: 'Sātāra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5539',
    value: 'Sīkar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5540',
    value: 'Sītāpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5541',
    value: 'Sūrat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5544',
    value: 'Thanjāvūr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5547',
    value: 'Tiruchchirāppalli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5550',
    value: 'Tiruvannāmalai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5552',
    value: 'Tripura, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5553',
    value: 'Tumkūr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5559',
    value: 'Uttaranchal, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5562',
    value: 'Verāval Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5564',
    value: 'Vijayawāda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5565',
    value: 'Virār Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5566',
    value: 'Vishākhapatnam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5569',
    value: 'West Bengal, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5570',
    value: 'Wāpi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5571',
    value: 'Yavatmāl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5572',
    value: 'Ābu Road Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5573',
    value: 'Ādilābād Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5574',
    value: 'Āgra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5575',
    value: 'Āsansol Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5576',
    value: 'Āvadi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5577',
    value: 'Āīzawl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5578',
    value: 'Altamura Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5579',
    value: 'Andria Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5580',
    value: 'Arezzo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5581',
    value: 'Asti Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5582',
    value: 'Aversa Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5583',
    value: 'Bari Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5584',
    value: 'Battipaglia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5585',
    value: 'Benevento Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5586',
    value: 'Bergamo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5587',
    value: 'Bologna Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5588',
    value: 'Bolzano Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5589',
    value: 'Brescia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5590',
    value: 'Cagliari Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5591',
    value: 'Caltanissetta Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5592',
    value: 'Caserta Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5593',
    value: 'Catania Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5594',
    value: 'Catanzaro Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5595',
    value: 'Chieti Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5596',
    value: 'Como Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5597',
    value: 'Cosenza Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5598',
    value: 'Cuneo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5599',
    value: 'Florence Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5600',
    value: 'Foggia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5601',
    value: 'Gela Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5602',
    value: 'Genoa Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5603',
    value: 'Giugliano In Campania Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5604',
    value: 'Grosseto Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5605',
    value: 'Gidonia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5606',
    value: 'L’Aquila Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5607',
    value: 'La Spezia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5608',
    value: 'Latina Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5609',
    value: 'Lazio, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5610',
    value: 'Lecce Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5611',
    value: 'Livorno Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5612',
    value: 'Lombardia, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5613',
    value: 'Marsala Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5614',
    value: 'Matera Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5615',
    value: 'Messina Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5616',
    value: 'Milan Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5617',
    value: 'Modena Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5618',
    value: 'Monza and Brianza Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5619',
    value: 'Naples Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5620',
    value: 'Nicastro Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5621',
    value: 'Novara Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5622',
    value: 'Padova Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5623',
    value: 'Palermo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5624',
    value: 'Parma Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5625',
    value: 'Perugia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5626',
    value: 'Pescara Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5627',
    value: 'Piemonte Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5628',
    value: 'Pinocchio Di Ancona Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5629',
    value: 'Pordenone Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5630',
    value: 'Potenza Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5631',
    value: 'Prato Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5632',
    value: 'Ravenna Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5633',
    value: 'Reggio Calabria Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5634',
    value: 'Rimini Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5635',
    value: 'Rivoli Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5636',
    value: 'Rome Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5637',
    value: 'Taranto Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5638',
    value: 'San Remo, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5639',
    value: 'San Severo, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5640',
    value: 'Sardegna, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5641',
    value: 'Sassari Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5642',
    value: 'Savona Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5643',
    value: 'Sicilia, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5644',
    value: 'Siracusa Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5645',
    value: 'Salerno Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5646',
    value: 'Terni Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5647',
    value: 'Toscana, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5648',
    value: 'Trentino-Alto Adige, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5649',
    value: 'Trento Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5650',
    value: 'Treviso Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5651',
    value: 'Trieste Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5652',
    value: 'Turin Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5653',
    value: 'Udine Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5654',
    value: 'Valle D’Aosta, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5655',
    value: 'Varese Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5656',
    value: 'Veneto, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5657',
    value: 'Venice Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5658',
    value: 'Verona Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5659',
    value: 'Vicenza Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5660',
    value: 'Viterbo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5661',
    value: 'De Velden, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5662',
    value: 'Alkmaar Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5663',
    value: 'Almere Stad Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5664',
    value: 'Amsterdam Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5665',
    value: 'Apeldoorn Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5666',
    value: 'Bergen Op Zoom Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5667',
    value: 'Delft Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5668',
    value: 'Eindhoven Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5669',
    value: 'Enschede Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5670',
    value: 'Friesland, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5671',
    value: 'Ge, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5672',
    value: 'Gr, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5673',
    value: 'Groningen Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5674',
    value: 'Groningen, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5675',
    value: 'Haarlem Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5676',
    value: 'Leeuwarden Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5677',
    value: 'Li, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5679',
    value: 'Nb, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5680',
    value: 'Nh, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5681',
    value: 'Nijmegen Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5682',
    value: 'Noord Holland, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5683',
    value: 'Ov, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5684',
    value: 'Overijssel, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5685',
    value: 'Roosendaal Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5686',
    value: 'Spijkenisse Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5687',
    value: 'Terneuzen Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5688',
    value: 'The Hague Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5689',
    value: 'Ut, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5690',
    value: 'Utrecht Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5691',
    value: 'Vlaardingen Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5692',
    value: 'Zaanstad Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5693',
    value: 'Zh, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5694',
    value: 'Zwolle Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5695',
    value: 'Akershus Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5696',
    value: 'Aust-Agder County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5697',
    value: 'Bergen Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5698',
    value: 'Bjornoya, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5699',
    value: 'Buskerud County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5700',
    value: 'Drammen Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5701',
    value: 'Finnmark County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5702',
    value: 'Hedmark County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5703',
    value: 'Hopen, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5704',
    value: 'Hordaland County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5705',
    value: 'Jan Mayen, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5706',
    value: 'Kristiansand Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5707',
    value: 'More og Romsdal County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5708',
    value: 'Nord-Trondelag County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5709',
    value: 'Nordland County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5710',
    value: 'Ostfold County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5711',
    value: 'Oppland County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5712',
    value: 'Oslo Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5713',
    value: 'Rogaland County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5714',
    value: 'Sor-Trondelag County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5715',
    value: 'Sogn og Fjordane County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5716',
    value: 'Spitsbergen, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5717',
    value: 'Stavanger Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5718',
    value: 'Telemark County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5719',
    value: 'Troms County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5720',
    value: 'Tromso Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5721',
    value: 'Trondheim Area, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5722',
    value: 'Vest-Agder County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5723',
    value: 'Vestfold County, Norway',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5724',
    value: 'Biała Podlaska Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5725',
    value: 'Białystok Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5726',
    value: 'Bielsko-Biała Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5727',
    value: 'Brodnicki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5728',
    value: 'Bydgoszcz Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5729',
    value: 'Chełm Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5730',
    value: 'Chojnicki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5731',
    value: 'Czarnkowsko-Trzcianecki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5732',
    value: 'Częstochowa Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5733',
    value: 'Człuchowski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5734',
    value: 'Drawski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5735',
    value: 'Elblag Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5736',
    value: 'Ełk Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5737',
    value: 'Gdańsk Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5738',
    value: 'Gdynia Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5739',
    value: 'Giżycki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5740',
    value: 'Gliwice Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5741',
    value: 'Gorzów Wielkopolski Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5742',
    value: 'Grudziądz Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5743',
    value: 'Gryficki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5744',
    value: 'Głogów Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5745',
    value: 'Hajnowski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5746',
    value: 'Hrubieszowski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5747',
    value: 'Iławski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5748',
    value: 'Jelenia Góra Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5749',
    value: 'Kalisz Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5750',
    value: 'Kartuski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5751',
    value: 'Katowice Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5752',
    value: 'Kielce Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5753',
    value: 'Koszalin Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5754',
    value: 'Kołobrzeski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5755',
    value: 'Kościerski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5756',
    value: 'Kraków Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5757',
    value: 'Krośnieński, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5758',
    value: 'Kętrzyński, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5759',
    value: 'Legionowo Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5760',
    value: 'Legnica Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5761',
    value: 'Leszno Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5762',
    value: 'Lubin Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5763',
    value: 'Lublin Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5764',
    value: 'Międzychodzki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5765',
    value: 'Mrągowski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5766',
    value: 'Mławski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5767',
    value: 'Nowomiejski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5768',
    value: 'Nowy Sącz Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5769',
    value: 'Olsztyn Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5770',
    value: 'Opole Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5771',
    value: 'Ostrowiec Świętokrzyski Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5772',
    value: 'Ostrołęka Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5773',
    value: 'Piotrków Trybunalski Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5774',
    value: 'Piła Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5775',
    value: 'Poznań Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5776',
    value: 'Przemyśl Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5777',
    value: 'Płock Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5778',
    value: 'Radom Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5779',
    value: 'Rzeszów Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5780',
    value: 'Siedlce Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5781',
    value: 'Stargard Szczeciński Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5782',
    value: 'Suwałki Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5783',
    value: 'Szczecin Pogodno Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5784',
    value: 'Słubicki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5785',
    value: 'Słupsk Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5786',
    value: 'Tarnów Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5787',
    value: 'Tatrzański, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5788',
    value: 'Tomaszów Mazowiecki Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5789',
    value: 'Toruń Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5790',
    value: 'Warsaw Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5791',
    value: 'Wałbrzych Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5792',
    value: 'Wejherowski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5793',
    value: 'Wrocław Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5794',
    value: 'Włocławek Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5795',
    value: 'Zamość Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5796',
    value: 'Zgorzelecki, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5797',
    value: 'Zielona Góra Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5798',
    value: 'Łomża Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5799',
    value: 'Łódź Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5800',
    value: 'Świdnica Area, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5801',
    value: 'Żarski, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5841',
    value: 'Atlantis Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5842',
    value: 'Barberton Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5843',
    value: 'Benoni Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5844',
    value: 'Bethal Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5845',
    value: 'Bethlehem Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5846',
    value: 'Bisho Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5847',
    value: 'Bloemfontein Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5848',
    value: 'Bothaville Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5849',
    value: 'Botshabelo Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5850',
    value: 'Brakpan Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5851',
    value: 'Brits Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5852',
    value: 'Cape Town Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5853',
    value: 'Carletonville Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5854',
    value: 'Dundee Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5855',
    value: 'Durban Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5856',
    value: 'Embalenhle Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5857',
    value: 'Fort Beaufort Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5858',
    value: 'George Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5859',
    value: 'Graaff-Reinet Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5860',
    value: 'Grahamstown Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5861',
    value: 'Heilbron Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5862',
    value: 'Johannesburg Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5863',
    value: 'Kimberley Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5864',
    value: 'Klerksdorp Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5865',
    value: 'Knysna Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5866',
    value: 'Kroonstad Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5867',
    value: 'Krugersdorp Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5868',
    value: 'Louis Trichardt Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5869',
    value: 'Mabopane Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5870',
    value: 'Middelburg Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5871',
    value: 'Mmabatho Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5872',
    value: 'Mpumalanga Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5873',
    value: 'Nelspruit Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5874',
    value: 'Newcastle Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5875',
    value: 'Noorder-Paarl Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5876',
    value: 'Orkney Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5877',
    value: 'Oudtshoorn Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5878',
    value: 'Phalaborwa Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5879',
    value: 'Phuthaditjhaba Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5880',
    value: 'Piet Retief Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5881',
    value: 'Pietermaritzburg Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5882',
    value: 'Polokwane Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5883',
    value: 'Port Elizabeth Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5884',
    value: 'Port Shepstone Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5885',
    value: 'Potgietersrus Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5886',
    value: 'Pretoria Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5887',
    value: 'Queenstown Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5888',
    value: 'Richards Bay Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5889',
    value: 'Rustenburg Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5890',
    value: 'Saldanha Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5891',
    value: 'Schweizer-Reineke Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5892',
    value: 'Somerset West Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5893',
    value: 'Standerton Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5894',
    value: 'Stanger Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5895',
    value: 'Uitenhage Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5896',
    value: 'Umtata Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5897',
    value: 'Upington Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5898',
    value: 'Vanderbijlpark Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5899',
    value: 'Vereeniging Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5900',
    value: 'Virginia Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5901',
    value: 'Vryheid Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5902',
    value: 'Warmbaths Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5903',
    value: 'Welkom Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5904',
    value: 'Witbank Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5905',
    value: 'Worcester Area, South Africa',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5906',
    value: 'Breda Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5907',
    value: 'Tilburg Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5908',
    value: 'Rotterdam Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5909',
    value: 'Tlalnepantla Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5910',
    value: 'Aguascalientes Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5911',
    value: 'Cancún Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5912',
    value: 'Durango Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5913',
    value: 'Ciudad Victoria Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5914',
    value: 'Campeche Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5915',
    value: 'Ecatepec Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5916',
    value: 'Ciudad Nezahualcóyotl Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5917',
    value: 'Mérida Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5918',
    value: 'Torreón Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5919',
    value: 'Coatzacoalcos Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5920',
    value: 'Nuevo Laredo Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5921',
    value: 'Mexico City Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5922',
    value: 'San Nicolás de los Garzas Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5923',
    value: 'Veracruz Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5924',
    value: 'Querétaro Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5925',
    value: 'Puebla de Zaragoza Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5926',
    value: 'Los Mochis Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5927',
    value: 'Oaxaca de Juárez Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5928',
    value: 'Tuxtla Gutiérrez Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5929',
    value: 'Tampico Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5930',
    value: 'Tlaquepaque Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5931',
    value: 'Matamoros Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5932',
    value: 'Reynosa Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5933',
    value: 'Tijuana Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5934',
    value: 'Culiacán Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5935',
    value: 'Acapulco de Juárez Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5936',
    value: 'Tepic Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5937',
    value: 'León Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5938',
    value: 'Ciudad López Mateos Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5939',
    value: 'Zapopan Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5940',
    value: 'Tehuacán Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5941',
    value: 'Jalapa Enríquez Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5942',
    value: 'Mexicali Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5943',
    value: 'Guadalupe Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5944',
    value: 'Monterrey Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5945',
    value: 'Ciudad Obregón Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5946',
    value: 'Ensenada Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5947',
    value: 'Guadalajara Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5948',
    value: 'Saltillo Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5949',
    value: 'San Luis Potosí Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5950',
    value: 'Gómez Palacio Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5951',
    value: 'Mazatlán Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5952',
    value: 'Pachuca de Soto Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5953',
    value: 'Villahermosa Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5954',
    value: 'General Escobedo Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5955',
    value: 'Naucalpan de Juárez Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5956',
    value: 'Chihuahua Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5957',
    value: 'Morelia Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5958',
    value: 'Toluca Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5959',
    value: 'Hermosillo Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5960',
    value: 'Uruapan del Progreso Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5961',
    value: 'Tonalá Area, Mexico',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5962',
    value: 'North Pole, Canada',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5963',
    value: 'Mococa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5964',
    value: 'Ipirá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5965',
    value: 'Formiga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5966',
    value: 'Ipiaú Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5967',
    value: 'Altamira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5968',
    value: 'Alta Floresta Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5969',
    value: 'Assis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5970',
    value: 'Laguna Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5971',
    value: 'Ipatinga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5972',
    value: 'Rondonópolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5973',
    value: 'Belém Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5974',
    value: 'São João del Rei Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5975',
    value: 'Taquara Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5976',
    value: 'Florianópolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5977',
    value: 'São João da Boa Vista Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5978',
    value: 'Coxim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5979',
    value: 'Inhumas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5980',
    value: 'Paranavaí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5981',
    value: 'Porangatu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5982',
    value: 'Xique Xique Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5983',
    value: 'Paranaíba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5984',
    value: 'Paranaguá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5985',
    value: 'Capão da Canoa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5986',
    value: 'Beberibe Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5987',
    value: 'Capâo Bonito Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5988',
    value: 'Alenquer Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5989',
    value: 'Xanxerê Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5990',
    value: 'Coruripe Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5991',
    value: 'Conceição do Araguaia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5992',
    value: 'Tefé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5993',
    value: 'Mineiros Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5994',
    value: 'Bom Jesus da Lapa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5995',
    value: 'Corumbá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5996',
    value: 'Pontes e Lacerda Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5997',
    value: 'Mamanguape Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5998',
    value: 'Volta Redonda Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:5999',
    value: 'Ponte Nova Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6000',
    value: 'Naviraí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6001',
    value: 'Santa Inês Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6002',
    value: 'Pirassununga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6003',
    value: 'Paraguaçu Paulista Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6004',
    value: 'Vitória da Conquista Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6005',
    value: 'Lagoa da Prata Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6006',
    value: 'Pará de Minas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6007',
    value: 'Vitória Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6008',
    value: 'Paracatu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6009',
    value: 'Patos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6010',
    value: 'Bom Despacho Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6011',
    value: 'Jaboatão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6012',
    value: 'Santa Cruz do Capibaribe Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6013',
    value: 'São Gotardo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6014',
    value: 'Ponta Porã Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6015',
    value: 'Santa Cruz Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6016',
    value: 'Colinas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6017',
    value: 'Cajazeiras Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6018',
    value: 'Alagoinhas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6019',
    value: 'Imbituba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6020',
    value: 'Tabatinga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6021',
    value: 'Ponta Grossa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6022',
    value: 'Coelho Neto Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6023',
    value: 'São Gabriel Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6024',
    value: 'Lagarto Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6025',
    value: 'Codó Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6026',
    value: 'Itapipoca Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6027',
    value: 'Ilhéus Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6028',
    value: 'Bocaiúva Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6029',
    value: 'Vila Velha Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6030',
    value: 'Betim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6031',
    value: 'Itapecuru Mirim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6032',
    value: 'Panambi Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6033',
    value: 'Itapagé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6034',
    value: 'Rio de Janeiro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6035',
    value: 'Pompéu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6036',
    value: 'Canavieiras Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6037',
    value: 'Arcos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6038',
    value: 'Ijuí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6039',
    value: 'Araxá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6040',
    value: 'Carazinho Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6041',
    value: 'Itaituba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6042',
    value: 'Barreiros Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6043',
    value: 'Caicó Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6044',
    value: 'São Francisco Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6045',
    value: 'Rio Claro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6046',
    value: 'Araruama Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6047',
    value: 'São Fidélis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6048',
    value: 'Brejo da Madre de Deus Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6049',
    value: 'Poços de Caldas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6050',
    value: 'Igarapava Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6051',
    value: 'Salinópolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6052',
    value: 'Videira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6053',
    value: 'Serra Talhada Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6054',
    value: 'Viçosa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6055',
    value: 'União dos Palmares Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6056',
    value: 'Afogados da Ingazeira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6057',
    value: 'Camaquã Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6058',
    value: 'Contagem Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6059',
    value: 'Poções Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6060',
    value: 'Araranguá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6061',
    value: 'Campos do Jordão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6062',
    value: 'Conselheiro Lafaiete Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6063',
    value: 'Campos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6064',
    value: 'Ibitinga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6065',
    value: 'Santa Cruz do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6066',
    value: 'Macapá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6067',
    value: 'Juiz de Fora Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6068',
    value: 'Palmeira das Missões Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6069',
    value: 'Ribeirão Preto Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6070',
    value: 'Santa Cruz do Rio Pardo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6071',
    value: 'Mafra Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6072',
    value: 'Campo Mourão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6073',
    value: 'Açu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6074',
    value: 'Araguari Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6075',
    value: 'Piripiri Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6076',
    value: 'Petrópolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6077',
    value: 'Ipu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6078',
    value: 'Campo Grande Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6079',
    value: 'Matão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6080',
    value: 'Natal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6081',
    value: 'Planaltina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6082',
    value: 'Barra do Corda Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6083',
    value: 'Acaraú Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6084',
    value: 'Araçatuba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6085',
    value: 'Chapadinha Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6086',
    value: 'Palhoça Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6087',
    value: 'Ribeira do Pombal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6088',
    value: 'Concórdia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6089',
    value: 'Ibaiti Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6090',
    value: 'Piuí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6091',
    value: 'Aracaju Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6092',
    value: 'Presidente Epitácio Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6093',
    value: 'São Bento do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6094',
    value: 'Euclides da Cunha Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6095',
    value: 'Conceição do Coité Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6096',
    value: 'Aquidauana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6097',
    value: 'Carangola Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6098',
    value: 'Apucarana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6099',
    value: 'Abaetetuba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6100',
    value: 'Pinheiro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6101',
    value: 'Vera Cruz Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6102',
    value: 'Tucuruí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6103',
    value: 'José Bonifácio Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6104',
    value: 'Campinas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6105',
    value: 'Gurupi Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6106',
    value: 'Quixadá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6107',
    value: 'Sorocaba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6108',
    value: 'Pirapora Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6109',
    value: 'Boa Esperança Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6110',
    value: 'Santo Estêvão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6111',
    value: 'Imperatriz Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6112',
    value: 'Joinville Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6113',
    value: 'Guaxupé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6114',
    value: 'Maringá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6115',
    value: 'Blumenau Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6116',
    value: 'Marau Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6117',
    value: 'Coari Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6118',
    value: 'Marília Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6119',
    value: 'Birigui Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6120',
    value: 'Bom Jesus do Itabapoana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6121',
    value: 'Limoeiro do Norte Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6122',
    value: 'Santo Antônio de Pádua Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6123',
    value: 'Santo Antônio de Jesus Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6124',
    value: 'Limoeiro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6125',
    value: 'Ouro Preto Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6126',
    value: 'Santo Antônio da Platina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6127',
    value: 'Iguatu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6128',
    value: 'Vilhena Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6129',
    value: 'Mariana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6130',
    value: 'João Pinheiro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6131',
    value: 'Piracicaba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6132',
    value: 'Russas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6133',
    value: 'Balsas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6134',
    value: 'Várzea da Palma Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6135',
    value: 'João Monlevade Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6136',
    value: 'Colatina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6137',
    value: 'Estância Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6138',
    value: 'Botucatu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6139',
    value: 'Guaratinguetá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6140',
    value: 'Icó Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6141',
    value: 'Marechal Cândido Rondon Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6142',
    value: 'Rio Branco Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6143',
    value: 'Varginha Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6144',
    value: 'Ourinhos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6145',
    value: 'Petrolina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6146',
    value: 'Guarapuava Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6147',
    value: 'Bento Gonçalves Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6148',
    value: 'Pesqueira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6149',
    value: 'Vargem Grande do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6150',
    value: 'Camaçari Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6151',
    value: 'Guarapari Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6152',
    value: 'Osvaldo Cruz Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6153',
    value: 'Resende Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6154',
    value: 'Montes Claros Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6155',
    value: 'Osório Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6156',
    value: 'Belo Horizonte Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6157',
    value: 'Angra dos Reis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6158',
    value: 'Horizonte Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6159',
    value: 'Registro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6160',
    value: 'Coaraci Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6161',
    value: 'Caldas Novas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6162',
    value: 'Sete Lagoas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6163',
    value: 'Palmas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6164',
    value: 'Guanambi Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6165',
    value: 'Valença Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6166',
    value: 'Valença Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6167',
    value: 'Castanhal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6168',
    value: 'Santo Ângelo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6169',
    value: 'Jequié Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6170',
    value: 'São Carlos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6171',
    value: 'São Miguel do Guamá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6172',
    value: 'Espírito Santo do Pinhal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6173',
    value: 'Cascavel Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6174',
    value: 'Ribeirão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6175',
    value: 'Andradina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6176',
    value: 'Patos de Minas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6177',
    value: 'Cajati Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6178',
    value: 'Andradas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6179',
    value: 'Erechim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6180',
    value: 'Serrinha Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6181',
    value: 'Pindamonhangaba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6182',
    value: 'Lajedo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6183',
    value: 'Santa Vitória do Palmar Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6184',
    value: 'São Mateus do Maranhão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6185',
    value: 'Caruaru Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6186',
    value: 'Manhuaçu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6187',
    value: 'Parintins Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6188',
    value: 'Oliveira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6189',
    value: 'Jaú Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6190',
    value: 'Tomé Açu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6191',
    value: 'Porto Velho Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6192',
    value: 'Pimenta Bueno Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6193',
    value: 'Uruguaiana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6194',
    value: 'Olímpia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6195',
    value: 'Caraguatatuba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6196',
    value: 'Bebedouro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6197',
    value: 'Cianorte Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6198',
    value: 'Morada Nova Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6199',
    value: 'Bauru Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6200',
    value: 'Gravataí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6201',
    value: 'São Luís Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6202',
    value: 'Uruaçu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6203',
    value: 'Ouro Preto do Oeste Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6204',
    value: 'Santa Rosa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6205',
    value: 'Jataí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6206',
    value: 'Carpina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6207',
    value: 'Serra Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6208',
    value: 'São Borja Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6209',
    value: 'União da Vitória Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6210',
    value: 'Santa Rita do Sapucaí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6211',
    value: 'Lago da Pedra Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6212',
    value: 'Unaí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6213',
    value: 'Jaraguá do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6214',
    value: 'Jaraguá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6215',
    value: 'Breves Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6216',
    value: 'Umuarama Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6217',
    value: 'Quirinópolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6218',
    value: 'Timbaúba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6219',
    value: 'Caetité Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6220',
    value: 'Senhor do Bonfim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6221',
    value: 'Brejo Santo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6222',
    value: 'Gravatá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6223',
    value: 'Jaru Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6224',
    value: 'Granja Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6225',
    value: 'Januária Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6226',
    value: 'Chapecó Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6227',
    value: 'Novo Horizonte Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6228',
    value: 'Bacabal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6229',
    value: 'Novo Hamburgo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6230',
    value: 'Uberlândia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6231',
    value: 'Janaúba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6232',
    value: 'Uberaba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6233',
    value: 'Santana do Livramento Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6234',
    value: 'Tianguá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6235',
    value: 'Guajará Mirim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6236',
    value: 'Boa Vista Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6237',
    value: 'Ubatuba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6238',
    value: 'Cachoeiro de Itapemirim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6239',
    value: 'Jales Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6240',
    value: 'Ubaitaba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6241',
    value: 'Ubá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6242',
    value: 'Nova Viçosa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6243',
    value: 'Canoinhas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6244',
    value: 'Peruíbe Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6245',
    value: 'Nova Venécia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6246',
    value: 'Barretos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6247',
    value: 'Jaguariaíva Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6248',
    value: 'Campo Belo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6249',
    value: 'Mossoró Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6250',
    value: 'Teresina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6251',
    value: 'Guaíra Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6252',
    value: 'Almenara Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6253',
    value: 'Palmas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6254',
    value: 'Jaguaquara Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6255',
    value: 'Cachoeira do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6256',
    value: 'Nova Iguaçu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6257',
    value: 'Machado Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6258',
    value: 'Nova Friburgo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6259',
    value: 'Governador Valadares Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6260',
    value: 'Tupã Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6261',
    value: 'Tauá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6262',
    value: 'Grajaú Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6263',
    value: 'Bragança Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6264',
    value: 'Humaitá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6265',
    value: 'Macaé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6266',
    value: 'Alfenas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6267',
    value: 'Cacoal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6268',
    value: 'Paulo Afonso Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6269',
    value: 'Tucano Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6270',
    value: 'Além Paraíba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6271',
    value: 'Goiatuba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6272',
    value: 'Capanema Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6273',
    value: 'Goiânia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6274',
    value: 'Goianésia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6275',
    value: 'Vacaria Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6276',
    value: 'Barreiras Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6277',
    value: 'Jacobina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6278',
    value: 'Alegrete Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6279',
    value: 'Dourados Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6280',
    value: 'Penedo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6281',
    value: 'Maceió Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6282',
    value: 'Caçador Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6283',
    value: 'Penápolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6284',
    value: 'Niquelândia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6285',
    value: 'Pelotas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6286',
    value: 'Recife Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6287',
    value: 'Caxias do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6288',
    value: 'Ariquemes Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6289',
    value: 'Rio Pardo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6290',
    value: 'Três Pontas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6291',
    value: 'Cabo Frio Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6292',
    value: 'Santiago Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6293',
    value: 'Três Lagoas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6294',
    value: 'Santa Maria Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6295',
    value: 'Jaboticabal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6296',
    value: 'Lucas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6297',
    value: 'Santa Helena de Goiás Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6298',
    value: 'Dom Pedrito Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6299',
    value: 'Arcoverde Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6300',
    value: 'Barra do Garças Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6301',
    value: 'Capitão Poço Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6302',
    value: 'Santa Fé do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6303',
    value: 'Lorena Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6304',
    value: 'Três Corações Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6305',
    value: 'Barra do Bugres Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6306',
    value: 'Piraju Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6307',
    value: 'Araripina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6308',
    value: 'Nanuque Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6309',
    value: 'Londrina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6310',
    value: 'Garanhuns Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6311',
    value: 'Delmiro Gouveia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6312',
    value: 'São Sebastião do Paraíso Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6313',
    value: 'Ituverava Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6314',
    value: 'Manaus Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6315',
    value: 'Iturama Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6316',
    value: 'Presidente Prudente Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6317',
    value: 'Itumbiara Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6318',
    value: 'Ituiutaba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6319',
    value: 'Garça Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6320',
    value: 'Quixeramobim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6321',
    value: 'Anápolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6322',
    value: 'Lins Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6323',
    value: 'São Sebastião Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6324',
    value: 'Linhares Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6325',
    value: 'Catanduva Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6326',
    value: 'Catalão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6327',
    value: 'Cataguases Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6328',
    value: 'Viamão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6329',
    value: 'Castro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6330',
    value: 'Tramandaí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6331',
    value: 'Entre Rios Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6332',
    value: 'Arapiraca Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6333',
    value: 'Itaúna Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6334',
    value: 'Parnaíba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6335',
    value: 'Divinópolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6336',
    value: 'Muriaé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6337',
    value: 'Guarabira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6338',
    value: 'Barbacena Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6339',
    value: 'Itararé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6340',
    value: 'Rio Grande Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6341',
    value: 'Limeira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6342',
    value: 'Aracati Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6343',
    value: 'Itaqui Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6344',
    value: 'Torres Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6345',
    value: 'Diamantina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6346',
    value: 'Juazeiro do Norte Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6347',
    value: 'Fernandópolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6348',
    value: 'Presidente Venceslau Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6349',
    value: 'Itápolis Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6350',
    value: 'Bandeirantes Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6351',
    value: 'Currais Novos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6352',
    value: 'Bom Conselho Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6353',
    value: 'Leopoldina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6354',
    value: 'Paragominas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6355',
    value: 'São Pedro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6356',
    value: 'Lençóis Paulista Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6357',
    value: 'Canindé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6358',
    value: 'Jaguarão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6359',
    value: 'Itapira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6360',
    value: 'Brumado Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6361',
    value: 'Cascavel Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6362',
    value: 'Rio do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6363',
    value: 'Toledo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6364',
    value: 'Leme Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6365',
    value: 'Pouso Alegre Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6366',
    value: 'Itapeva Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6367',
    value: 'Araras Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6368',
    value: 'São Paulo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6369',
    value: 'Itapetininga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6370',
    value: 'Itapetinga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6371',
    value: 'Itaperuna Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6372',
    value: 'Tobias Barreto Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6373',
    value: 'Lavras Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6374',
    value: 'Carmo do Paranaíba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6375',
    value: 'João Pessoa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6376',
    value: 'Manacapuru Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6377',
    value: 'Timóteo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6378',
    value: 'Salvador Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6379',
    value: 'Curvelo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6380',
    value: 'São Mateus Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6381',
    value: 'Itamaraju Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6382',
    value: 'Morrinhos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6383',
    value: 'Porto Seguro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6384',
    value: 'Rancharia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6385',
    value: 'Itajubá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6386',
    value: 'São Luiz Gonzaga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6387',
    value: 'Surubim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6388',
    value: 'Caratinga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6389',
    value: 'Itajaí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6390',
    value: 'Palmares Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6391',
    value: 'Presidente Dutra Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6392',
    value: 'Fortaleza Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6393',
    value: 'Vigia Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6394',
    value: 'Brasília Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6395',
    value: 'Frutal Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6396',
    value: 'Adamantina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6397',
    value: 'Salgueiro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6398',
    value: 'Curitibanos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6399',
    value: 'Curitiba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6400',
    value: 'São Lourenço do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6401',
    value: 'Viana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6402',
    value: 'Lajeado Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6403',
    value: 'Bragança Paulista Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6404',
    value: 'São Lourenço Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6405',
    value: 'Patrocínio Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6406',
    value: 'Campo Maior Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6407',
    value: 'Salinas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6408',
    value: 'São José dos Campos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6409',
    value: 'Santa Cruz das Palmeiras Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6410',
    value: 'São José do Rio Preto Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6411',
    value: 'São José do Rio Pardo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6412',
    value: 'Sousa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6413',
    value: 'Feira de Santana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6414',
    value: 'Crato Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6415',
    value: 'Crateús Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6416',
    value: 'Cuiabá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6417',
    value: 'Monte Carmelo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6418',
    value: 'Floriano Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6419',
    value: 'Francisco Beltrão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6420',
    value: 'Itabuna Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6421',
    value: 'Bagé Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6422',
    value: 'Teófilo Otoni Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6423',
    value: 'Itabira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6424',
    value: 'Pato Branco Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6425',
    value: 'Franca Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6426',
    value: 'Itaberaba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6427',
    value: 'Porto Ferreira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6428',
    value: 'Monte Alto Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6429',
    value: 'Itabaiana Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6430',
    value: 'Lages Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6431',
    value: 'Foz do Iguaçu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6432',
    value: 'Campina Grande Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6433',
    value: 'Telêmaco Borba Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6434',
    value: 'Passos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6435',
    value: 'Pacajus Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6436',
    value: 'Cruzeiro Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6437',
    value: 'Passo Fundo Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6438',
    value: 'Cruz das Almas Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6439',
    value: 'Cruz Alta Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6440',
    value: 'Irecê Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6441',
    value: 'Irati Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6442',
    value: 'Taubaté Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6443',
    value: 'Sobral Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6444',
    value: 'Tatuí Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6445',
    value: 'Camocim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6446',
    value: 'Santarém Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6447',
    value: 'Ouricuri Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6448',
    value: 'Marabá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6449',
    value: 'Cametá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6450',
    value: 'Avaré Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6451',
    value: 'Oriximiná Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6452',
    value: 'Santana do Ipanema Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6453',
    value: 'Cristalina Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6454',
    value: 'Rosário do Sul Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6455',
    value: 'Medianeira Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6456',
    value: 'Taquaritinga Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6457',
    value: 'Iporá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6458',
    value: 'Formosa Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6459',
    value: 'Coroatá Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6460',
    value: 'Mogi Guaçu Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6461',
    value: 'Moji das Cruzes Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6462',
    value: 'Picos Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6463',
    value: 'Criciúma Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6464',
    value: 'Belo Jardim Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6465',
    value: 'São Joaquim da Barra Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6466',
    value: 'Tubarão Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6467',
    value: 'Porto Alegre Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6468',
    value: 'Taquari Area, Brazil',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6469',
    value: 'Indapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6470',
    value: 'Sanchor Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6471',
    value: 'Mansa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6472',
    value: 'Coimbatore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6473',
    value: 'Sundarnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6474',
    value: 'Gadwal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6475',
    value: 'Sundargarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6476',
    value: 'Bhinmal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6477',
    value: 'Cochin Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6478',
    value: 'Kumbakonam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6479',
    value: 'Bhind Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6480',
    value: 'Ashoknagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6481',
    value: 'Ilkal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6482',
    value: 'Varanasi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6483',
    value: 'Churu Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6484',
    value: 'Gadarwara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6485',
    value: 'Gadag Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6486',
    value: 'Karimnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6487',
    value: 'Mannarakkat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6488',
    value: 'Karimganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6489',
    value: 'Kulti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6490',
    value: 'Churachandpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6491',
    value: 'Sanawad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6492',
    value: 'Bhilwara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6493',
    value: 'Suluru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6494',
    value: 'Vaniyambadi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6495',
    value: 'Mankachar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6496',
    value: 'Nawada Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6497',
    value: 'Forbesganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6498',
    value: 'Bhilai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6499',
    value: 'Manjlegaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6500',
    value: 'Etawah Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6501',
    value: 'Sambalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6502',
    value: 'Bada Barabil Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6503',
    value: 'Firozpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6504',
    value: 'Firozabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6505',
    value: 'Manjeri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6506',
    value: 'Samastipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6507',
    value: 'Kareli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6508',
    value: 'Hyderabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6509',
    value: 'Kukshi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6510',
    value: 'Huvinabadgalli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6511',
    value: 'Valsad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6512',
    value: 'Valparai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6513',
    value: 'Navadwip Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6514',
    value: 'Husainabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6515',
    value: 'Fatehpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6516',
    value: 'Moga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6517',
    value: 'Hunsur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6518',
    value: 'Salur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6519',
    value: 'Kohima Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6520',
    value: 'Deoria Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6521',
    value: 'Tiptur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6522',
    value: 'Bhawaniganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6523',
    value: 'Bhavnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6524',
    value: 'Azamgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6525',
    value: 'Farrukhabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6526',
    value: 'Begusarai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6527',
    value: 'Mangrol Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6528',
    value: 'Karanja Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6529',
    value: 'Faridabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6530',
    value: 'Seram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6531',
    value: 'Bhatpara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6532',
    value: 'Salem Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6533',
    value: 'Hubli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6534',
    value: 'Bhatkal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6535',
    value: 'Chodavaram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6536',
    value: 'Chodavaram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6537',
    value: 'Bhatinda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6538',
    value: 'Faizabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6539',
    value: 'Vaijapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6540',
    value: 'Karaikkudi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6541',
    value: 'Hosur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6542',
    value: 'Hospet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6543',
    value: 'Hoshiarpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6544',
    value: 'Hoshangabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6545',
    value: 'Narsipatnam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6546',
    value: 'Chittaurgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6547',
    value: 'Narsimhapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6548',
    value: 'Sagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6549',
    value: 'Karad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6550',
    value: 'Charkhi Dadri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6551',
    value: 'Bharuch Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6552',
    value: 'Vadodara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6553',
    value: 'Mandya Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6554',
    value: 'Krishnagiri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6555',
    value: 'Chitradurga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6556',
    value: 'Narnaul Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6557',
    value: 'Erode Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6558',
    value: 'Begamganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6559',
    value: 'Mandvi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6560',
    value: 'Calicut Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6561',
    value: 'Srinagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6562',
    value: 'Erattupetta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6563',
    value: 'Punasa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6564',
    value: 'Mandsaur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6565',
    value: 'Sri Madhopur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6566',
    value: 'Attur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6567',
    value: 'Srikakulam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6568',
    value: 'Bharatpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6569',
    value: 'Hole Narsipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6570',
    value: 'Kovilpatti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6571',
    value: 'Sri Dungargarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6572',
    value: 'Nargund Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6573',
    value: 'Hojai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6574',
    value: 'Mandla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6575',
    value: 'Emmiganuru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6576',
    value: 'Eluru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6577',
    value: 'Aligarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6578',
    value: 'Mandi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6579',
    value: 'Kottayam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6580',
    value: 'Atmakur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6581',
    value: 'Chirala Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6582',
    value: 'Narayanpet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6583',
    value: 'Athni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6584',
    value: 'Bhimavaram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6585',
    value: 'Hisar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6586',
    value: 'Dhar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6587',
    value: 'Nawalgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6588',
    value: 'Hiriyur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6589',
    value: 'Chintamani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6590',
    value: 'Kottagudem Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6591',
    value: 'Atarra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6592',
    value: 'Sainthia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6593',
    value: 'Narasaraopet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6594',
    value: 'Kanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6595',
    value: 'Narasapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6596',
    value: 'Bhandara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6597',
    value: 'Lunglei Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6598',
    value: 'Sailu Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6599',
    value: 'Phaltan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6600',
    value: 'Egra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6601',
    value: 'Kot Putli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6602',
    value: 'Kannauj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6603',
    value: 'Kotma Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6604',
    value: 'Kannangad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6605',
    value: 'Sitapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6606',
    value: 'Dungarpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6607',
    value: 'Hingoli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6608',
    value: 'Dindigul Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6609',
    value: 'Hinganghat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6610',
    value: 'Hindupur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6611',
    value: 'Mancheral Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6612',
    value: 'Sahibganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6613',
    value: 'Manawar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6614',
    value: 'Sahaswan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6615',
    value: 'Bangaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6616',
    value: 'Hindaun Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6617',
    value: 'Saharsa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6618',
    value: 'Nanpara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6619',
    value: 'Saharanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6620',
    value: 'Songadh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6621',
    value: 'Himatnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6622',
    value: 'Ashta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6623',
    value: 'karaikal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6624',
    value: 'Bhaisa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6625',
    value: 'Kanigiri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6626',
    value: 'Kotdwara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6627',
    value: 'Nangal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6628',
    value: 'Chilakalurupet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6629',
    value: 'Punch Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6630',
    value: 'Nandyal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6631',
    value: 'Upleta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6632',
    value: 'Bargarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6633',
    value: 'Asansol Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6634',
    value: 'Nandurbar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6635',
    value: 'Chikmagalur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6636',
    value: 'Durgapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6637',
    value: 'Mirzapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6638',
    value: 'Durg Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6639',
    value: 'Unjha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6640',
    value: 'Perambalur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6641',
    value: 'Sagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6642',
    value: 'Chikhli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6643',
    value: 'Sambhal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6644',
    value: 'Kota Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6645',
    value: 'Aruppukkottai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6646',
    value: 'Nandikotkur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6647',
    value: 'Sadri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6648',
    value: 'Bhagalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6649',
    value: 'Kandukur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6650',
    value: 'Chidambaram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6651',
    value: 'Tehri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6652',
    value: 'Arsikere Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6653',
    value: 'Sultanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6654',
    value: 'Hazaribag Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6655',
    value: 'Sardarshahr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6656',
    value: 'Una Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6657',
    value: 'Kosi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6658',
    value: 'Un Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6659',
    value: 'Haveri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6660',
    value: 'Kandla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6661',
    value: 'Ajmer Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6662',
    value: 'Malpura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6663',
    value: 'Dumraon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6664',
    value: 'Bhadrakh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6665',
    value: 'Kandi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6666',
    value: 'Bhadrachalam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6667',
    value: 'Hatta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6668',
    value: 'Bhadra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6669',
    value: 'Mudhol Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6670',
    value: 'Dumka Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6671',
    value: 'Sojat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6672',
    value: 'Bhawanipatna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6673',
    value: 'Ichalkaranji Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6674',
    value: 'Godda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6675',
    value: 'Kanchipuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6676',
    value: 'Chhindwara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6677',
    value: 'Bhadravati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6678',
    value: 'Sabalgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6679',
    value: 'Korba Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6680',
    value: 'Malkangiri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6681',
    value: 'Koratla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6682',
    value: 'Yavatmal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6683',
    value: 'Muddebihal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6684',
    value: 'Koraput Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6685',
    value: 'Bhabua Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6686',
    value: 'Rajapalaiyam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6687',
    value: 'Payyannur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6688',
    value: 'Hassan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6689',
    value: 'Chhatarpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6690',
    value: 'Suratgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6691',
    value: 'Umarkot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6692',
    value: 'Betul Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6693',
    value: 'Umaria Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6694',
    value: 'Bettiah Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6695',
    value: 'Koppal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6696',
    value: 'Umarkhed Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6697',
    value: 'Araria Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6698',
    value: 'Arantangi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6699',
    value: 'Kopargaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6700',
    value: 'Nalgonda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6701',
    value: 'Kunigal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6702',
    value: 'Arani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6703',
    value: 'Siwan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6704',
    value: 'Puliyangudi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6705',
    value: 'Akot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6706',
    value: 'Bareilly Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6707',
    value: 'Arambagh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6708',
    value: 'Abohar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6709',
    value: 'Mukher Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6710',
    value: 'Jodhpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6711',
    value: 'Agar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6712',
    value: 'Sivakasi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6713',
    value: 'Parli Vaijnath Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6714',
    value: 'Harpanahalli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6715',
    value: 'Rawatsar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6716',
    value: 'Ara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6717',
    value: 'Koch Bihar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6718',
    value: 'Ujjain Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6719',
    value: 'Najibabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6720',
    value: 'Jais Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6721',
    value: 'Berasia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6722',
    value: 'Kamareddi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6723',
    value: 'Naini Tal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6724',
    value: 'Kondagaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6725',
    value: 'Konch Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6726',
    value: 'Kaman Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6727',
    value: 'Talaja Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6728',
    value: 'Anupgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6729',
    value: 'Sitamarhi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6730',
    value: 'Shujalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6731',
    value: 'Patna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6732',
    value: 'Udumalaippettai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6733',
    value: 'Shahjahanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6734',
    value: 'Udipi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6735',
    value: 'Udhampur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6736',
    value: 'Contai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6737',
    value: 'Udgir Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6738',
    value: 'Nahan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6739',
    value: 'Patiala Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6740',
    value: 'Harihar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6741',
    value: 'Makrana Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6742',
    value: 'Haridwar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6743',
    value: 'Nim ka Thana Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6744',
    value: 'Sindhnur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6745',
    value: 'Rohtak Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6746',
    value: 'Ballari Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6747',
    value: 'Bela Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6748',
    value: 'Chiplun Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6749',
    value: 'Udaipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6750',
    value: 'Udaipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6751',
    value: 'Nagpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6752',
    value: 'Fatehpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6753',
    value: 'Sirsilla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6754',
    value: 'Pathankot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6755',
    value: 'Pathanamthitta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6756',
    value: 'Anjar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6757',
    value: 'Sirsi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6758',
    value: 'Nautanwa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6759',
    value: 'Sirsa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6760',
    value: 'Belagavi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6761',
    value: 'Nagina Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6762',
    value: 'Nagercoil Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6763',
    value: 'Robertsonpet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6764',
    value: 'Robertsganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6765',
    value: 'Sironj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6766',
    value: 'Kolar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6767',
    value: 'Karauli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6768',
    value: 'Hanumangarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6769',
    value: 'Nagaur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6770',
    value: 'Risod Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6771',
    value: 'Patan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6772',
    value: 'Patamundai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6773',
    value: 'Nagar Karnul Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6774',
    value: 'Kallakkurichchi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6775',
    value: 'Kokrajhar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6776',
    value: 'Balasore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6777',
    value: 'Tura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6778',
    value: 'Hangal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6779',
    value: 'Mainpuri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6780',
    value: 'Tuni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6781',
    value: 'Sira Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6782',
    value: 'Malkapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6783',
    value: 'Pasighat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6784',
    value: 'Pasan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6785',
    value: 'Nagappattinam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6786',
    value: 'Mahasamund Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6787',
    value: 'Hamirpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6788',
    value: 'Chas Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6789',
    value: 'Vite Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6790',
    value: 'Parvatipuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6791',
    value: 'Tumsar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6792',
    value: 'Disa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6793',
    value: 'Rewari Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6794',
    value: 'Partur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6795',
    value: 'Khed Brahma Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6796',
    value: 'Rewa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6797',
    value: 'Tumakuru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6798',
    value: 'Anantnag Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6799',
    value: 'Diphu Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6800',
    value: 'Anantapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6801',
    value: 'Dinhata Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6802',
    value: 'Beawar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6803',
    value: 'Renukut Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6804',
    value: 'Anakapalle Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6805',
    value: 'Haldwani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6806',
    value: 'Mahoba Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6807',
    value: 'Dimapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6808',
    value: 'Sherghati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6809',
    value: 'Bayana Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6810',
    value: 'Bolpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6811',
    value: 'Kodar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6812',
    value: 'Mysuru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6813',
    value: 'Kishangarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6814',
    value: 'Tuensang Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6815',
    value: 'Amritsar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6816',
    value: 'Umarga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6817',
    value: 'Muzaffarpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6818',
    value: 'Amreli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6819',
    value: 'Muzaffarnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6820',
    value: 'Amravati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6821',
    value: 'Hailakandi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6822',
    value: 'Kalamb Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6823',
    value: 'Banda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6824',
    value: 'Rehli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6825',
    value: 'Parlakimidi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6826',
    value: 'Mahendragarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6827',
    value: 'Tiruppur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6828',
    value: 'Jodhpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6829',
    value: 'Haflong Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6830',
    value: 'Channarayapatna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6831',
    value: 'Thiruvananthapuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6832',
    value: 'Digras Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6833',
    value: 'Sindgi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6834',
    value: 'Mahbubnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6835',
    value: 'Mahbubabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6836',
    value: 'Hadgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6837',
    value: 'Diglur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6838',
    value: 'Changanacheri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6839',
    value: 'Trichur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6840',
    value: 'Shimla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6841',
    value: 'Mushabani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6842',
    value: 'Batala Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6843',
    value: 'Maharajganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6844',
    value: 'Rayadrug Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6845',
    value: 'Rayachoti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6846',
    value: 'Parbhani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6847',
    value: 'Raxaul Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6848',
    value: 'Amla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6849',
    value: 'Kishanganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6850',
    value: 'Murwara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6851',
    value: 'Didwana Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6852',
    value: 'Simdega Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6853',
    value: 'Krishnanagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6854',
    value: 'Jaora Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6855',
    value: 'Dibrugarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6856',
    value: 'Gwalior Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6857',
    value: 'Sillod Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6858',
    value: 'Faridkot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6859',
    value: 'Parasia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6860',
    value: 'Kakinada Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6861',
    value: 'Basmat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6862',
    value: 'Kinwat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6863',
    value: 'Rawatbhata Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6864',
    value: 'Gursarai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6865',
    value: 'Gorakhpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6866',
    value: 'Silchar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6867',
    value: 'Tonk Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6868',
    value: 'Ratnagiri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6869',
    value: 'Paramagudi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6870',
    value: 'Ratlam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6871',
    value: 'Mahad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6872',
    value: 'Ambikapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6873',
    value: 'Basavana Bagevadi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6874',
    value: 'Basavakalyan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6875',
    value: 'Rath Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6876',
    value: 'Kaithal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6877',
    value: 'Dhule Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6878',
    value: 'Dhuburi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6879',
    value: 'Chandigarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6880',
    value: 'Auraiya Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6881',
    value: 'Dhrangadhra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6882',
    value: 'Ratangarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6883',
    value: 'Munnar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6884',
    value: 'Sikar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6885',
    value: 'Basti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6886',
    value: 'Madurantakam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6887',
    value: 'Madurai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6888',
    value: 'Barwani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6889',
    value: 'Ambala Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6890',
    value: 'Guntakal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6891',
    value: 'Chennai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6892',
    value: 'Dhoraji Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6893',
    value: 'Munger Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6894',
    value: 'Mungeli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6895',
    value: 'Pavagada Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6896',
    value: 'Madikeri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6897',
    value: 'Baruni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6898',
    value: 'Gundlupet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6899',
    value: 'Chandrapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6900',
    value: 'Titlagarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6901',
    value: 'Pauri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6902',
    value: 'Panna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6903',
    value: 'Madhupur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6904',
    value: 'Madhubani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6905',
    value: 'Sidhi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6906',
    value: 'Malegaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6907',
    value: 'Akbarpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6908',
    value: 'Kadur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6909',
    value: 'Guna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6910',
    value: 'Barsi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6911',
    value: 'Siddipet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6912',
    value: 'Madhipura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6913',
    value: 'Panipat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6914',
    value: 'Pratapgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6915',
    value: 'Daund Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6916',
    value: 'Tiruvalla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6917',
    value: 'Kadiri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6918',
    value: 'Gumla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6919',
    value: 'Barpeta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6920',
    value: 'Sibsagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6921',
    value: 'Chamrajnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6922',
    value: 'Kadi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6923',
    value: 'Arangaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6924',
    value: 'Madgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6925',
    value: 'Nasik Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6926',
    value: 'Champa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6927',
    value: 'Amalapuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6928',
    value: 'Tirupati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6929',
    value: 'Mangalore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6930',
    value: 'Barnala Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6931',
    value: 'Kalburgi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6932',
    value: 'Alwar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6933',
    value: 'Kadaiyanallur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6934',
    value: 'Barmer Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6935',
    value: 'Ranibennur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6936',
    value: 'Gulabpura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6937',
    value: 'Madanapalle Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6938',
    value: 'Challakere Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6939',
    value: 'Shorapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6940',
    value: 'Muktsar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6941',
    value: 'Solapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6942',
    value: 'Tiruchchendur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6943',
    value: 'Gudur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6944',
    value: 'Jangipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6945',
    value: 'Rangia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6946',
    value: 'Machilipatnam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6947',
    value: 'Almora Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6948',
    value: 'Pandhurna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6949',
    value: 'Pandharpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6950',
    value: 'Shivpuri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6951',
    value: 'Macherla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6952',
    value: 'Alleppey Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6953',
    value: 'Dharmavaram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6954',
    value: 'Kakching Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6955',
    value: 'Dharmapuri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6956',
    value: 'Gudiyattam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6957',
    value: 'Gudivada Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6958',
    value: 'Allahabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6959',
    value: 'Dharmanagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6960',
    value: 'Bari Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6961',
    value: 'Junnar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6962',
    value: 'Shirpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6963',
    value: 'Warora Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6964',
    value: 'Ranchi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6965',
    value: 'Tinsukia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6966',
    value: 'Gudalur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6967',
    value: 'Junagadh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6968',
    value: 'Jumri Tilaiya Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6969',
    value: 'Dharapuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6970',
    value: 'Sawai Madhopur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6971',
    value: 'Tindivanam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6972',
    value: 'Jalandhar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6973',
    value: 'Chaibasa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6974',
    value: 'Pattukkottai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6975',
    value: 'Gopichettipalaiyam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6976',
    value: 'Arakkonam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6977',
    value: 'Ambur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6978',
    value: 'Lunavada Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6979',
    value: 'Malaut Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6980',
    value: 'Panaji Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6981',
    value: 'Shivamoga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6982',
    value: 'Lumding Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6983',
    value: 'Shillong Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6984',
    value: 'Shiliguri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6985',
    value: 'Chhabra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6986',
    value: 'Imphal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6987',
    value: 'Jorhat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6988',
    value: 'Rampur Hat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6989',
    value: 'Ludhiana Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6990',
    value: 'Shikarpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6991',
    value: 'Palwal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6992',
    value: 'Luckeesarai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6993',
    value: 'Jolarpettai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6994',
    value: 'Padrauna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6995',
    value: 'Barddhaman Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6996',
    value: 'Rampur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6997',
    value: 'Canning Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6998',
    value: 'Cannanore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:6999',
    value: 'Siuri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7000',
    value: 'Motihari Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7001',
    value: 'Jogbani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7002',
    value: 'Paloncha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7003',
    value: 'Kolkata Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7004',
    value: 'Palni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7005',
    value: 'Akola Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7006',
    value: 'Palmaner Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7007',
    value: 'Tikamgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7008',
    value: 'Dhandhuka Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7009',
    value: 'Buxar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7010',
    value: 'Ramnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7011',
    value: 'Gorakhpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7012',
    value: 'Ramnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7013',
    value: 'Sheopur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7014',
    value: 'Dhanbad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7015',
    value: 'Morsi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7016',
    value: 'Sheoganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7017',
    value: 'Aurangabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7018',
    value: 'Baran Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7019',
    value: 'Baramula Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7020',
    value: 'Palkonda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7021',
    value: 'Aurangabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7022',
    value: 'Jintur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7023',
    value: 'Palitana Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7024',
    value: 'Dhamtari Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7025',
    value: 'Dhulian Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7026',
    value: 'Palia Kalan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7027',
    value: 'Jind Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7028',
    value: 'Pali Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7029',
    value: 'Kashipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7030',
    value: 'Dhamnod Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7031',
    value: 'Harda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7032',
    value: 'Jhunjhunun Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7033',
    value: 'Gopalganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7034',
    value: 'Morbi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7035',
    value: 'Palghat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7036',
    value: 'Palghar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7037',
    value: 'Burhanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7038',
    value: 'Thiruvarur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7039',
    value: 'Shantipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7040',
    value: 'Ramganj Mandi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7041',
    value: 'Gangarampur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7042',
    value: 'Lohardaga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7043',
    value: 'Aizawl Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7044',
    value: 'Rameswaram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7045',
    value: 'Gondia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7046',
    value: 'Moradabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7047',
    value: 'Chakradharpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7048',
    value: 'Gonda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7049',
    value: 'Budaun Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7050',
    value: 'Bundi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7051',
    value: 'Palasa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7052',
    value: 'Palanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7053',
    value: 'Dewas Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7054',
    value: 'Buldana Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7055',
    value: 'Khargon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7056',
    value: 'Jharsuguda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7057',
    value: 'Ramanathapuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7058',
    value: 'Shajapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7059',
    value: 'Palakollu Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7060',
    value: 'Thanjavur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7061',
    value: 'Lingsugur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7062',
    value: 'Ahmadpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7063',
    value: 'Ahmadnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7064',
    value: 'Jhargram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7065',
    value: 'Ahmedabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7066',
    value: 'Fazilka Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7067',
    value: 'Gola Gokarannath Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7068',
    value: 'Golaghat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7069',
    value: 'Jhansi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7070',
    value: 'Shahpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7071',
    value: 'Venkatagiri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7072',
    value: 'Devarkonda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7073',
    value: 'Pakaur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7074',
    value: 'Gokak Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7075',
    value: 'Banswara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7076',
    value: 'Shahganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7077',
    value: 'Kharagpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7078',
    value: 'Paithan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7079',
    value: 'Shahdol Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7080',
    value: 'Devakottai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7081',
    value: 'Rajula Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7082',
    value: 'Zunheboto Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7083',
    value: 'Shahada Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7084',
    value: 'Bansi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7085',
    value: 'Tezpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7086',
    value: 'Nagda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7087',
    value: 'Brajarajnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7088',
    value: 'Jhabua Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7089',
    value: 'Brahmapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7090',
    value: 'Rajpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7091',
    value: 'Godhra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7092',
    value: 'Guntur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7093',
    value: 'Lucknow Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7094',
    value: 'Raj Nandgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7095',
    value: 'Leh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7096',
    value: 'Botad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7097',
    value: 'Ambajogai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7098',
    value: 'Khandwa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7099',
    value: 'Tenkasi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7100',
    value: 'Teni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7101',
    value: 'Zaidpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7102',
    value: 'Chandausi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7103',
    value: 'Rajkot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7104',
    value: 'Jaypur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7105',
    value: 'Adoni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7106',
    value: 'Banka Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7107',
    value: 'Dhone Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7108',
    value: 'Modasa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7109',
    value: 'Goalpara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7110',
    value: 'Adilabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7111',
    value: 'Ponnani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7112',
    value: 'Rajgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7113',
    value: 'Phulabani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7114',
    value: 'Latur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7115',
    value: 'Sihora Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7116',
    value: 'Seoni Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7117',
    value: 'Rajgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7118',
    value: 'Tellicherry Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7119',
    value: 'Khammam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7120',
    value: 'Giridih Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7121',
    value: 'Yellandu Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7122',
    value: 'Rajauri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7123',
    value: 'Khamgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7124',
    value: 'Jaunpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7125',
    value: 'Khambhat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7126',
    value: 'Khambhaliya Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7127',
    value: 'Bengaluru Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7128',
    value: 'Sendhwa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7129',
    value: 'Vyara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7130',
    value: 'Achalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7131',
    value: 'Pachora Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7132',
    value: 'Bulandshahr Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7133',
    value: 'Abu Road Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7134',
    value: 'Mirialguda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7135',
    value: 'Rajampet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7136',
    value: 'Kalyan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7137',
    value: 'Giddalur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7138',
    value: 'Bandipura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7139',
    value: 'Kolhapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7140',
    value: 'Rajahmundry Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7141',
    value: 'Bongaigaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7142',
    value: 'Paradip Garh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7143',
    value: 'Jasdan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7144',
    value: 'Yamunanagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7145',
    value: 'Panruti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7146',
    value: 'Kuchaman Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7147',
    value: 'Chirawa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7148',
    value: 'Raisinghnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7149',
    value: 'Tasgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7150',
    value: 'Mumbai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7151',
    value: 'New Delhi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7152',
    value: 'Khajuraho Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7153',
    value: 'Osmanabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7154',
    value: 'Banda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7155',
    value: 'Yadgir Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7156',
    value: 'Mettur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7157',
    value: 'Dehri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7158',
    value: 'Dehra Dun Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7159',
    value: 'Bokaro Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7160',
    value: 'Mokokchung Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7161',
    value: 'Raipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7162',
    value: 'Wokha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7163',
    value: 'Orai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7164',
    value: 'Kendraparha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7165',
    value: 'Ongole Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7166',
    value: 'Lalsot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7167',
    value: 'Taranagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7168',
    value: 'Raigarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7169',
    value: 'Balrampur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7170',
    value: 'Raiganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7171',
    value: 'Bamor Kalan Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7172',
    value: 'Dhupgari Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7173',
    value: 'Raichur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7174',
    value: 'Lalitpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7175',
    value: 'Thanesar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7176',
    value: 'Jangaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7177',
    value: 'Ghazipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7178',
    value: 'Shahpura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7179',
    value: 'Bobbili Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7180',
    value: 'Merta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7181',
    value: 'Saundatti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7182',
    value: 'Dausa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7183',
    value: 'Keshod Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7184',
    value: 'Satyamangalam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7185',
    value: 'Obra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7186',
    value: 'Kollegal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7187',
    value: 'Utraula Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7188',
    value: 'Wanparti Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7189',
    value: 'Jamui Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7190',
    value: 'Washim Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7191',
    value: 'Daudnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7192',
    value: 'Siruguppa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7193',
    value: 'Raghogarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7194',
    value: 'Ghatal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7195',
    value: 'Jamshedpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7196',
    value: 'Nuzvid Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7197',
    value: 'Warud Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7198',
    value: 'Rae Bareli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7199',
    value: 'Radhanpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7200',
    value: 'Satna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7201',
    value: 'Balurghat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7202',
    value: 'Jamnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7203',
    value: 'Bishnupur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7204',
    value: 'Jammu Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7205',
    value: 'Rabkavi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7206',
    value: 'Hardoi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7207',
    value: 'Wardha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7208',
    value: 'Jamkhandi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7209',
    value: 'Quilon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7210',
    value: 'Warangal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7211',
    value: 'Talcher Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7212',
    value: 'Wapi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7213',
    value: 'Balotra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7214',
    value: 'Lakhimpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7215',
    value: 'Mehekar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7216',
    value: 'Tandur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7217',
    value: 'Lakheri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7218',
    value: 'Satara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7219',
    value: 'Wani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7220',
    value: 'Kalpetta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7221',
    value: 'Meerut Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7222',
    value: 'Tanda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7223',
    value: 'Birmitrapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7224',
    value: 'Puttur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7225',
    value: 'Gevrai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7226',
    value: 'Udagamandalam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7227',
    value: 'Darjiling Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7228',
    value: 'North Lakhimpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7229',
    value: 'Medak Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7230',
    value: 'Sangola Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7231',
    value: 'Kekri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7232',
    value: 'Jalpaiguri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7233',
    value: 'Gaya Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7234',
    value: 'Jalor Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7235',
    value: 'Gokarn Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7236',
    value: 'Pusad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7237',
    value: 'Darbhanga Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7238',
    value: 'Wai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7239',
    value: 'Puruliya Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7240',
    value: 'Purnia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7241',
    value: 'Lahar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7242',
    value: 'Agra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7243',
    value: 'Kawardha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7244',
    value: 'Nokha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7245',
    value: 'Puri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7246',
    value: 'Nohar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7247',
    value: 'Ladnun Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7248',
    value: 'Guwahati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7249',
    value: 'Puranpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7250',
    value: 'Medinipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7251',
    value: 'Jalalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7252',
    value: 'Bina-Etawa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7253',
    value: 'Kavali Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7254',
    value: 'Mauganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7255',
    value: 'Garwa Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7256',
    value: 'Maudaha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7257',
    value: 'Dhenkanal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7258',
    value: 'Nizamabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7259',
    value: 'Balangir Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7260',
    value: 'Mau Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7261',
    value: 'Taliparamba Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7262',
    value: 'Talikota Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7263',
    value: 'Jhalawar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7264',
    value: 'Vriddhachalam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7265',
    value: 'Vizianagaram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7266',
    value: 'Balaghat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7267',
    value: 'Amalner Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7268',
    value: 'Phalodi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7269',
    value: 'Mattanur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7270',
    value: 'Jajpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7271',
    value: 'Dandeli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7272',
    value: 'Visnagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7273',
    value: 'Nirmal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7274',
    value: 'Gariadhar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7275',
    value: 'Vishakhapatnam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7276',
    value: 'Damoh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7277',
    value: 'Bilaspur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7278',
    value: 'Bilasipara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7279',
    value: 'Bilara Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7280',
    value: 'Pulivendla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7281',
    value: 'Katoya Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7282',
    value: 'Jaisalmer Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7283',
    value: 'Mathura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7284',
    value: 'Katol Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7285',
    value: 'Bikramganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7286',
    value: 'Pilibhit Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7287',
    value: 'Jaipur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7288',
    value: 'Rajsamand Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7289',
    value: 'Bikaner Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7290',
    value: 'Sirohi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7291',
    value: 'Sarangpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7292',
    value: 'Tirunelveli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7293',
    value: 'Katihar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7294',
    value: 'Daltenganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7295',
    value: 'Vinukonda Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7296',
    value: 'Bijnor Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7297',
    value: 'Pudukkottai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7298',
    value: 'Sangamner Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7299',
    value: 'Villupuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7300',
    value: 'Proddatur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7301',
    value: 'Gangapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7302',
    value: 'Bairagnia Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7303',
    value: 'Vijayapura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7304',
    value: 'Angul Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7305',
    value: 'Nimbahera Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7306',
    value: 'Jagtial Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7307',
    value: 'Vikarabad Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7308',
    value: 'Vijayawada Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7309',
    value: 'Jaggayyapeta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7310',
    value: 'Bihar Sharif Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7311',
    value: 'Jha Jha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7312',
    value: 'Jagdalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7313',
    value: 'Mulbagal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7314',
    value: 'Gangtok Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7315',
    value: 'Vidisha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7316',
    value: 'Mannargudi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7317',
    value: 'Bidhuna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7318',
    value: 'Tadpatri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7319',
    value: 'Dahod Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7320',
    value: 'Supaul Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7321',
    value: 'Bidar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7322',
    value: 'Mayiladuthurai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7323',
    value: 'Agartala Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7324',
    value: 'Tadepallegudem Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7325',
    value: 'Bahraich Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7326',
    value: 'Dahanu Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7327',
    value: 'Gangawati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7328',
    value: 'Biaora Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7329',
    value: 'Jalna Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7330',
    value: 'Veraval Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7331',
    value: 'Baheri Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7332',
    value: 'Port Blair Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7333',
    value: 'Kanker Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7334',
    value: 'Baharampur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7335',
    value: 'Nongstoin Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7336',
    value: 'Jabalpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7337',
    value: 'Bhusawal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7338',
    value: 'Porbandar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7339',
    value: 'Kasganj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7340',
    value: 'Ganganagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7341',
    value: 'Marmagao Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7342',
    value: 'Kumta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7343',
    value: 'Markapur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7344',
    value: 'Dabwali Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7345',
    value: 'Rajpipla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7346',
    value: 'Itarsi Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7347',
    value: 'Itanagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7348',
    value: 'Pithoragarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7349',
    value: 'Puducherry Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7350',
    value: 'Pune Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7351',
    value: 'Bhuj Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7352',
    value: 'Vellore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7353',
    value: 'Marhaura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7354',
    value: 'Bhubaneshwar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7355',
    value: 'Suriapet Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7356',
    value: 'Tiruvannamalai Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7357',
    value: 'Karwar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7358',
    value: 'Cuttack Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7359',
    value: 'Surendranagar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7360',
    value: 'Jetpur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7361',
    value: 'Chalisgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7362',
    value: 'Sangrur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7363',
    value: 'Jalgaon Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7364',
    value: 'Tiruchchirappalli Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7365',
    value: 'Karur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7366',
    value: 'Bhopal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7367',
    value: 'Surat Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7368',
    value: 'Kundla Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7369',
    value: 'Cuddapah Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7370',
    value: 'Dwarka Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7371',
    value: 'Cuddalore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7372',
    value: 'Srivilliputtur Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7373',
    value: 'Rajgarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7374',
    value: 'Vedaranniyam Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7375',
    value: 'Nellore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7376',
    value: 'Ingraj Bazar Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7377',
    value: 'Mapuca Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7378',
    value: 'Bankura Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7379',
    value: 'Bagasra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7380',
    value: 'Coondapoor Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7381',
    value: 'Gajendragarh Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7382',
    value: 'Indore Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7383',
    value: 'Bagalkot Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7384',
    value: 'Chatra Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7385',
    value: 'Bagaha Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7386',
    value: 'Pithapuram Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7387',
    value: 'Baramati Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7388',
    value: 'Bhiwani Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7389',
    value: 'Karnal Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7390',
    value: 'Nayudupeta Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7391',
    value: 'Gurgaon, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7392',
    value: 'Noida Area, India',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7393',
    value: 'Guimarães Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7394',
    value: 'Faro Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7395',
    value: 'Olhão Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7396',
    value: 'Setúbal Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7397',
    value: 'Porto Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7398',
    value: 'Portimão Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7399',
    value: 'Viseu Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7400',
    value: 'Castelo Branco Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7401',
    value: 'Bragança Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7402',
    value: 'Ponte do Lima Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7403',
    value: 'Marinha Grande Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7404',
    value: 'Braga Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7405',
    value: 'Lisbon Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7406',
    value: 'Aveiro Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7407',
    value: 'Leiria Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7408',
    value: 'Évora Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7409',
    value: 'Monsanto Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7410',
    value: 'Santarém Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7411',
    value: 'Sequeira Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7412',
    value: 'Coimbra Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7413',
    value: 'Caldas da Rainha Area, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7414',
    value: 'Azores, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7415',
    value: 'Madeira, Portugal',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7416',
    value: 'Baltimore, Maryland Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7417',
    value: 'Maastricht Area, Netherlands',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7423',
    value: 'Ulyanovsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7424',
    value: 'Chuvash Republic, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7425',
    value: 'Orenburg Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7426',
    value: 'Republic of Mordovia, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7427',
    value: 'Udmurtian Republic, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7428',
    value: 'Kirov Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7429',
    value: 'Republic of Mari El, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7430',
    value: 'Perm Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7431',
    value: 'Republic of Tatarstan, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7432',
    value: 'Nizhny Novgorod Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7433',
    value: 'Samara Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7434',
    value: 'Saratov Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7435',
    value: 'Penza Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7436',
    value: 'Republic of Bashkortostan, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7437',
    value: 'Republic of Khakassia, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7438',
    value: 'Republic of Buryatia, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7439',
    value: 'Altai Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7440',
    value: 'Trans-Baikal Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7441',
    value: 'Irkutsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7442',
    value: 'Republic of Tuva, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7443',
    value: 'Tomsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7444',
    value: 'Republic of Altai, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7445',
    value: 'Omsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7446',
    value: 'Krasnodar Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7447',
    value: 'Novosibirsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7448',
    value: 'Kemerovo Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7449',
    value: 'Republic of Sakha (Yakutia), Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7450',
    value: 'Sakhalin Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7451',
    value: 'Kamchatka Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7452',
    value: 'Chukotka Autonomous Area, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7453',
    value: 'Magadan Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7454',
    value: 'Jewish Autonomous Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7455',
    value: 'Primorye Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7456',
    value: 'Amur Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7457',
    value: 'Khabarovsk Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7458',
    value: 'Leningrad Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7459',
    value: 'Republic of Karelia, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7460',
    value: 'Vologda Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7461',
    value: 'Kaliningrad Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7462',
    value: 'Novgorod Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7463',
    value: 'Saint Petersburg, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7464',
    value: 'Murmansk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7465',
    value: 'Arkhangelsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7466',
    value: 'Komi Republic, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7467',
    value: 'Pskov Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7468',
    value: 'Tyumen Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7469',
    value: 'Sverdlovsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7470',
    value: 'Kurgan Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7471',
    value: 'Chelyabinsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7472',
    value: 'Ryazan Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7473',
    value: 'Ivanovo Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7474',
    value: 'Tula Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7475',
    value: 'Belgorod Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7476',
    value: 'Yaroslavl Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7477',
    value: 'Kursk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7478',
    value: 'Tver Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7479',
    value: 'Bryansk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7480',
    value: 'Orel Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7481',
    value: 'Moscow Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7482',
    value: 'Lipetsk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7483',
    value: 'Kostroma Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7484',
    value: 'Kaluga Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7485',
    value: 'Vladimir Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7486',
    value: 'Tambov Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7487',
    value: 'Moscow, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7488',
    value: 'Voronezh Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7489',
    value: 'Smolensk Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7490',
    value: 'Krasnoyarsk Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7491',
    value: 'Volgograd Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7492',
    value: 'Rostov Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7493',
    value: 'Chechen Republic, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7494',
    value: 'Stavropol Territory, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7495',
    value: 'Kabardino-Balkarian Republic, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7496',
    value: 'Republic of North Ossetia - Alania, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7497',
    value: 'Republic of Daghestan, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7498',
    value: 'Republic of Kalmykia, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7499',
    value: 'Republic of Ingushetia, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7500',
    value: 'Republic of Adygeya, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7501',
    value: 'Astrakhan Region, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7502',
    value: 'Karachayevo-Circassian Republic, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7503',
    value: 'Nenets Autonomous Area, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7504',
    value: 'Khanty-Mansi Autonomous Area - Yugra, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7505',
    value: 'Yamal-Nenets Autonomous Area, Russian Federation',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7516',
    value: 'Gorj County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7517',
    value: 'Valcea County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7518',
    value: 'Olt County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7519',
    value: 'Dolj County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7520',
    value: 'Mehedinti County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7521',
    value: 'Giurgiu County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7522',
    value: 'Ialomita County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7523',
    value: 'Dambovita County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7524',
    value: 'Arges County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7525',
    value: 'Teleorman County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7526',
    value: 'Prahova County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7527',
    value: 'Bihor County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7528',
    value: 'Satu Mare County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7529',
    value: 'Cluj County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7530',
    value: 'Salaj County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7531',
    value: 'Bistrita-Nasaud County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7532',
    value: 'Caras-Severin County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7533',
    value: 'Timis County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7534',
    value: 'Arad County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7535',
    value: 'Hunedoara County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7536',
    value: 'Bucharest, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7537',
    value: 'Ilfov County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7538',
    value: 'Sibiu County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7539',
    value: 'Alba County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7540',
    value: 'Covasna County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7541',
    value: 'Harghita County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7542',
    value: 'Mures County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7543',
    value: 'Brasov County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7544',
    value: 'Galati County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7545',
    value: 'Braila County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7546',
    value: 'Vrancea County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7547',
    value: 'Tulcea County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7548',
    value: 'Constantza County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7549',
    value: 'Buzau County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7550',
    value: 'Bacau County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7551',
    value: 'Suceava County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7552',
    value: 'Iasi County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7553',
    value: 'Botosani County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7554',
    value: 'Neamt County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7555',
    value: 'Vaslui County, Romania',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7556',
    value: 'Mardin, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7557',
    value: 'Gaziantep, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7558',
    value: 'Sirnak, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7559',
    value: 'Siirt, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7560',
    value: 'Batman, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7561',
    value: 'Adiyaman, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7562',
    value: 'Kilis, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7563',
    value: 'Sanliurfa, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7564',
    value: 'Diyarbakir, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7565',
    value: 'Cankiri, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7566',
    value: 'Konya, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7567',
    value: 'Kayseri, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7568',
    value: 'Nevsehir, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7569',
    value: 'Ankara, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7570',
    value: 'Yozgat, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7571',
    value: 'Aksaray, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7572',
    value: 'Eskisehir, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7573',
    value: 'Sivas, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7574',
    value: 'Karaman, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7575',
    value: 'Kirsehir, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7576',
    value: 'Kirikkale, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7577',
    value: 'Nigde, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7578',
    value: 'Bursa, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7579',
    value: 'Canakkale, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7580',
    value: 'Bilecik, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7581',
    value: 'Kirklareli, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7582',
    value: 'Yalova, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7583',
    value: 'Kocaeli, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7584',
    value: 'Balikesir, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7585',
    value: 'Istanbul, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7586',
    value: 'Tekirdag, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7587',
    value: 'Edirne, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7588',
    value: 'Sakarya, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7589',
    value: 'Burdur, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7590',
    value: 'Hatay, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7591',
    value: 'Antalya, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7592',
    value: 'Isparta, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7593',
    value: 'Kahramanmaras, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7594',
    value: 'Mersin, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7595',
    value: 'Adana, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7596',
    value: 'Osmaniye, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7597',
    value: 'Erzurum, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7598',
    value: 'Mus, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7599',
    value: 'Erzincan, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7600',
    value: 'Malatya, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7601',
    value: 'Van, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7602',
    value: 'Elazig, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7603',
    value: 'Hakkari, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7604',
    value: 'Ardahan, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7605',
    value: 'Bingol, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7606',
    value: 'Kars, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7607',
    value: 'Tunceli, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7608',
    value: 'Igdir, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7609',
    value: 'Bitlis, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7610',
    value: 'Agri, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7611',
    value: 'Manisa, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7612',
    value: 'Usak, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7613',
    value: 'Izmir, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7614',
    value: 'Mugla, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7615',
    value: 'Kutahya, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7616',
    value: 'Afyonkarahisar, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7617',
    value: 'Denizli, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7618',
    value: 'Aydin, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7619',
    value: 'Giresun, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7620',
    value: 'Bayburt, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7621',
    value: 'Duzce, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7622',
    value: 'Zonguldak, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7623',
    value: 'Corum, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7624',
    value: 'Karabuk, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7625',
    value: 'Artvin, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7626',
    value: 'Amasya, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7627',
    value: 'Kastamonu, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7628',
    value: 'Trabzon, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7629',
    value: 'Tokat, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7630',
    value: 'Rize, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7631',
    value: 'Gumushane, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7632',
    value: 'Bartin, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7633',
    value: 'Sinop, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7634',
    value: 'Samsun, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7635',
    value: 'Ordu, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7636',
    value: 'Bolu, Turkey',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7637',
    value: 'Yamaguchi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7638',
    value: 'Shimane, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7639',
    value: 'Tottori, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7640',
    value: 'Hiroshima, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7641',
    value: 'Okayama, , Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7642',
    value: 'Kumamoto, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7643',
    value: 'Miyazaki, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7644',
    value: 'Nagasaki, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7645',
    value: 'Fukuoka, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7646',
    value: 'Saga, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7647',
    value: 'Okinawa, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7648',
    value: 'Kagoshima, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7649',
    value: 'Oita, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7650',
    value: 'Yamanashi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7651',
    value: 'Ishikawa, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7652',
    value: 'Fukui, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7653',
    value: 'Shizuoka, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7654',
    value: 'Aichi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7655',
    value: 'Gifu, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7656',
    value: 'Toyama, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7657',
    value: 'Nagano, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7658',
    value: 'Niigata, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7659',
    value: 'Akita, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7660',
    value: 'Yamagata, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7661',
    value: 'Aomori, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7662',
    value: 'Fukushima, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7663',
    value: 'Miyagi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7664',
    value: 'Iwate, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7665',
    value: 'Tokushima, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7666',
    value: 'Ehime, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7667',
    value: 'Kochi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7668',
    value: 'Kagawa, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7669',
    value: 'Ibaraki, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7670',
    value: 'Gunma, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7671',
    value: 'Chiba, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7672',
    value: 'Tokyo, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7673',
    value: 'Kanagawa, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7674',
    value: 'Tochigi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7675',
    value: 'Saitama, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7676',
    value: 'Hokkaido, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7677',
    value: 'Hyogo, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7678',
    value: 'Kyoto, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7679',
    value: 'Nara, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7680',
    value: 'Osaka, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7681',
    value: 'Mie, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7682',
    value: 'Shiga, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7683',
    value: 'Wakayama, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7684',
    value: 'Hamamatsu, Shizuoka, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7685',
    value: 'Nagoya, Aichi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7686',
    value: 'Sendai, Miyagi, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7687',
    value: 'Within 23 wards, Tokyo, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7688',
    value: 'Outside 23 wards, Tokyo, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7689',
    value: 'Yokohama, Kanagawa, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7690',
    value: 'Kawasaki, Kanagawa, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7691',
    value: 'Saitama, Saitama, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7692',
    value: 'Sapporo, Hokkaido, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7693',
    value: 'Kobe, Hyogo, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7694',
    value: 'Kyoto, Kyoto, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7695',
    value: 'Osaka, Osaka, Japan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7696',
    value: 'Songpa-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7697',
    value: 'Jongro-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7698',
    value: 'Dongdaemun-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7699',
    value: 'Jung-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7700',
    value: 'Gangnam-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7701',
    value: 'Mapo-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7702',
    value: 'Yangcheon-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7703',
    value: 'Nowon-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7704',
    value: 'Gwangjin-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7705',
    value: 'Yeongdeungpo-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7706',
    value: 'Dobong-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7707',
    value: 'Geumcheon-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7708',
    value: 'Seongdong-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7709',
    value: 'Gwanak-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7710',
    value: 'Gangbuk-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7711',
    value: 'Jungrang-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7712',
    value: 'Seongbuk-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7713',
    value: 'Seocho-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7714',
    value: 'Guro-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7715',
    value: 'Gangdong-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7716',
    value: 'Yongsan-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7717',
    value: 'Seodaemun-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7718',
    value: 'Gangseo-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7719',
    value: 'Dongjak-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7720',
    value: 'Eunpyeong-gu, Seoul, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7721',
    value: 'Uljin-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7722',
    value: 'Goryeong-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7723',
    value: 'Chilgok-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7724',
    value: 'Yeongyang-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7725',
    value: 'Uiseong-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7726',
    value: 'Bonghwa-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7727',
    value: 'Sangju, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7728',
    value: 'Gumi, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7729',
    value: 'Yecheon-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7730',
    value: 'Ulleung-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7731',
    value: 'Gyeongju, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7732',
    value: 'Gimcheon, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7733',
    value: 'Yeongcheon, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7734',
    value: 'Yeongdeok-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7735',
    value: 'Gyeongsan, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7736',
    value: 'Cheongdo-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7737',
    value: 'Pohang, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7738',
    value: 'Andong, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7739',
    value: 'Cheongsong-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7740',
    value: 'Yeongju, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7741',
    value: 'Gunwi-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7742',
    value: 'Mungyeong, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7743',
    value: 'Seongju-gun, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7744',
    value: 'Pohang, Gyeongsangbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7745',
    value: 'Suyeong-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7746',
    value: 'Yeonje-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7747',
    value: 'Jung-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7748',
    value: 'Saha-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7749',
    value: 'Gijang-gun, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7750',
    value: 'Buk-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7751',
    value: 'Seo-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7752',
    value: 'Geumjeong-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7753',
    value: 'Dong-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7754',
    value: 'Busanjin-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7755',
    value: 'Sasang-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7756',
    value: 'Nam-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7757',
    value: 'Yeongdo-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7758',
    value: 'Gangseo-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7759',
    value: 'Haeundae-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7760',
    value: 'Dongrae-gu, Busan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7761',
    value: 'Namdong-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7762',
    value: 'Jung-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7763',
    value: 'Seo-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7764',
    value: 'Gyeyang-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7765',
    value: 'Ongjin-gun, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7766',
    value: 'Dong-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7767',
    value: 'Ganghwa-gun, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7768',
    value: 'Nam-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7769',
    value: 'Yeonsu-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7770',
    value: 'Bupyeong-gu, Incheon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7771',
    value: 'Imsil-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7772',
    value: 'Gochang-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7773',
    value: 'Namwon, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7774',
    value: 'Jeongeup, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7775',
    value: 'Jeonju, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7777',
    value: 'Jangsu-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7778',
    value: 'Iksan, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7779',
    value: 'Buan-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7780',
    value: 'Gimje, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7781',
    value: 'Sunchang-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7782',
    value: 'Gunsan, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7783',
    value: 'Jinan-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7784',
    value: 'Wanju-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7785',
    value: 'Muju-gun, Jeollabuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7786',
    value: 'Jung-gu, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7787',
    value: 'Buk-gu, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7788',
    value: 'Seo-gu, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7789',
    value: 'Dalseo-gu, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7790',
    value: 'Dong-gu, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7791',
    value: 'Nam-gu, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7792',
    value: 'Dalseong-gun, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7793',
    value: 'Suseong-gu, Daegu, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7794',
    value: 'Tongyeong, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7795',
    value: 'Changnyeong-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7796',
    value: 'Namhae-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7797',
    value: 'Hadong-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7798',
    value: 'Geoje, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7799',
    value: 'Hapcheon-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7800',
    value: 'Miryang, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7801',
    value: 'Jinju, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7802',
    value: 'Yangsan, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7803',
    value: 'Gimhae, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7804',
    value: 'Haman-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7805',
    value: 'Uiryeong-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7806',
    value: 'Geochang-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7807',
    value: 'Sacheon, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7808',
    value: 'Hamyang-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7809',
    value: 'Goseong-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7810',
    value: 'Sancheong-gun, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7811',
    value: 'Jinhae, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7812',
    value: 'Changwon, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7813',
    value: 'Masan, Gyeongsangnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7814',
    value: 'Yuseong-gu, Daejeon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7815',
    value: 'Jung-gu, Daejeon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7816',
    value: 'Daedeok-gu, Daejeon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7817',
    value: 'Seo-gu, Daejeon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7818',
    value: 'Dong-gu, Daejeon, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7819',
    value: 'Jung-gu, Ulsan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7820',
    value: 'Ulju-gun, Ulsan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7821',
    value: 'Buk-gu, Ulsan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7822',
    value: 'Dong-gu, Ulsan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7823',
    value: 'Nam-gu, Ulsan, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7824',
    value: 'Yongin, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7825',
    value: 'Bucheon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7826',
    value: 'Dongducheon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7827',
    value: 'Yangpyeong-gun, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7828',
    value: 'Gwacheon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7829',
    value: 'Gimpo, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7830',
    value: 'Suwon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7831',
    value: 'Osan, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7832',
    value: 'Yangju, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7833',
    value: 'Hanam, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7834',
    value: 'Pocheon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7835',
    value: 'Hwaseong, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7836',
    value: 'Yeoncheon-gun, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7837',
    value: 'Seongnam, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7838',
    value: 'Anyang, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7839',
    value: 'Uiwang, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7840',
    value: 'Guri, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7841',
    value: 'Suwon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7842',
    value: 'Namyangju, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7843',
    value: 'Seongnam, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7844',
    value: 'Bucheon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7845',
    value: 'Suwon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7846',
    value: 'Yongin, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7847',
    value: 'Goyang, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7848',
    value: 'Gapyeong-gun, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7849',
    value: 'Yeoju-gun, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7850',
    value: 'Anyang, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7851',
    value: 'Suwon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7852',
    value: 'Anseong, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7853',
    value: 'Yongin, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7854',
    value: 'Pyeongtaek, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7855',
    value: 'Uijeongbu, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7856',
    value: 'Ansan, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7857',
    value: 'Bucheon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7858',
    value: 'Seongnam, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7859',
    value: 'Icheon, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7860',
    value: 'Paju, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7861',
    value: 'Goyang, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7862',
    value: 'Siheung, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7863',
    value: 'Goyang, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7865',
    value: 'Gunpo, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7866',
    value: 'Gwangmyeong, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7867',
    value: 'Gwangju, Gyeonggi-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7868',
    value: 'Eumseong-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7869',
    value: 'Cheongwon-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7870',
    value: 'Yeongdong-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7871',
    value: 'Chungju, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7872',
    value: 'Jincheon-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7873',
    value: 'Jecheon, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7874',
    value: 'Okcheon-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7875',
    value: 'Cheongju, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7876',
    value: 'Jeungpyeong-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7877',
    value: 'Boeun-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7878',
    value: 'Cheongju, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7879',
    value: 'Danyang-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7880',
    value: 'Goesan-gun, Chungcheongbuk-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7881',
    value: 'Damyang-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7882',
    value: 'Muan-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7883',
    value: 'Wando-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7884',
    value: 'Naju, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7885',
    value: 'Sinan-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7886',
    value: 'Jangheung-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7887',
    value: 'Yeonggwang-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7888',
    value: 'Yeongam-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7889',
    value: 'Gokseong-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7890',
    value: 'Mokpo, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7891',
    value: 'Hwasun-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7892',
    value: 'Gurye-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7893',
    value: 'Haenam-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7894',
    value: 'Boseong-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7895',
    value: 'Gwangyang, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7896',
    value: 'Jindo-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7897',
    value: 'Goheung-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7898',
    value: 'Jangseong-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7899',
    value: 'Suncheon, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7900',
    value: 'Yeosu, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7901',
    value: 'Hampyeong-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7902',
    value: 'Gangjin-gun, Jeollanam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7903',
    value: 'Hongcheon-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7904',
    value: 'Gangreung, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7905',
    value: 'Yeongwol-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7906',
    value: 'Wonju, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7907',
    value: 'Chuncheon, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7908',
    value: 'Yangyang-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7909',
    value: 'Inje-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7910',
    value: 'Hoengseong-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7911',
    value: 'Hwacheon-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7912',
    value: 'Goseong-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7913',
    value: 'Pyeongchang-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7914',
    value: 'Taebaek, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7915',
    value: 'Donghae, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7916',
    value: 'Sokcho, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7917',
    value: 'Samcheok, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7918',
    value: 'Jeongseon-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7919',
    value: 'Yanggu-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7920',
    value: 'Cheorwon-gun, Gangwon-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7921',
    value: 'Asan, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7922',
    value: 'Geumsan-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7923',
    value: 'Gongju, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7924',
    value: 'Hongseong-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7925',
    value: 'Seocheon-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7926',
    value: 'Boryeong, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7927',
    value: 'Dangjin-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7928',
    value: 'Seosan, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7929',
    value: 'Gyeryong, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7930',
    value: 'Nonsan, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7931',
    value: 'Yesan-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7932',
    value: 'Buyeo-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7933',
    value: 'Cheonan, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7935',
    value: 'Taean-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7936',
    value: 'Yeongi-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7937',
    value: 'Cheongyang-gun, Chungcheongnam-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7938',
    value: 'Buk-gu, Gwangju, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7939',
    value: 'Seo-gu, Gwangju, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7940',
    value: 'Dong-gu, Gwangju, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7941',
    value: 'Gwangsan-gu, Gwangju, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7942',
    value: 'Nam-gu, Gwangju, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7943',
    value: 'Seogwipo, Jeju-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7944',
    value: 'Jeju, Jeju-do, Korea',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7945',
    value: 'Selangor, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7946',
    value: 'Labuan, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7947',
    value: 'Perlis, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7948',
    value: 'Negeri Sembilan, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7949',
    value: 'Putra Jaya, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7950',
    value: 'Sabah, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7951',
    value: 'Terengganu, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7952',
    value: 'Johor, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7953',
    value: 'Kedah, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7954',
    value: 'Malacca, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7955',
    value: 'Sarawak, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7956',
    value: 'Perak, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7957',
    value: 'Pahang, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7958',
    value: 'Kelantan, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7959',
    value: 'Penang, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7960',
    value: 'Kuala Lumpur, Malaysia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7961',
    value: 'Rezina Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7962',
    value: 'Floresti Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7963',
    value: 'Telenesti Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7964',
    value: 'Soroca Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7965',
    value: 'Briceni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7966',
    value: 'Bender Municipality, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7967',
    value: 'Balti Municipality, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7968',
    value: 'Ialoveni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7969',
    value: 'Falesti Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7970',
    value: 'Ocnita Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7971',
    value: 'Criuleni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7972',
    value: 'Cantemir Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7973',
    value: 'Leova Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7974',
    value: 'Drochia Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7975',
    value: 'Straseni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7976',
    value: 'Hancesti Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7977',
    value: 'Dubasari Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7978',
    value: 'Sangerei Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7979',
    value: 'Taraclia Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7980',
    value: 'Ungheni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7981',
    value: 'Anenii Noi Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7982',
    value: 'Glodeni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7983',
    value: 'Cahul Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7984',
    value: 'Calarasi Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7985',
    value: 'Soldanesti Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7986',
    value: 'Edinet Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7987',
    value: 'Donduseni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7988',
    value: 'ATU Gagauzia, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7989',
    value: 'Cimislia Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7990',
    value: 'Stefan Voda Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7991',
    value: 'ATU Transnistria, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7992',
    value: 'Causeni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7993',
    value: 'Basarabeasca Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7994',
    value: 'Chisinau Municipality, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7995',
    value: 'Nisporeni Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7996',
    value: 'Rascani Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7997',
    value: 'Orhei Region, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7998',
    value: 'Rezina, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:7999',
    value: 'Floresti, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8000',
    value: 'Telenesti, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8001',
    value: 'Soroca, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8002',
    value: 'Briceni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8003',
    value: 'Bender, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8004',
    value: 'Balti, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8005',
    value: 'Ialoveni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8006',
    value: 'Falesti, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8007',
    value: 'Ocnita, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8008',
    value: 'Criuleni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8009',
    value: 'Cantemir, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8010',
    value: 'Leova, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8011',
    value: 'Drochia, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8012',
    value: 'Straseni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8013',
    value: 'Hancesti, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8014',
    value: 'Sangerei, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8015',
    value: 'Taraclia, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8016',
    value: 'Ungheni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8017',
    value: 'Anenii Noi, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8018',
    value: 'Glodeni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8019',
    value: 'Cahul, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8020',
    value: 'Calarasi, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8021',
    value: 'Soldanesti, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8022',
    value: 'Edinet, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8023',
    value: 'Donduseni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8024',
    value: 'Comrat, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8025',
    value: 'Cimislia, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8026',
    value: 'Stefan Voda, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8027',
    value: 'Tiraspol, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8028',
    value: 'Causeni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8029',
    value: 'Basarabeasca, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8030',
    value: 'Chisinau, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8031',
    value: 'Nisporeni, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8032',
    value: 'Rascani, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8033',
    value: 'Orhei, Moldova',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8034',
    value: 'Jamtland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8035',
    value: 'Vastmanland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8036',
    value: 'Gotland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8037',
    value: 'Varmland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8038',
    value: 'Blekinge County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8039',
    value: 'Stockholm County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8040',
    value: 'Vasterbotten County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8041',
    value: 'Jonkoping County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8042',
    value: 'Vasternorrland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8043',
    value: 'Kronoberg County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8044',
    value: 'Skane County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8045',
    value: 'Kalmar County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8046',
    value: 'Halland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8047',
    value: 'Sodermanland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8048',
    value: 'Norrbotten County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8049',
    value: 'Uppsala County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8050',
    value: 'Dalarna County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8051',
    value: 'Gavleborg County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8052',
    value: 'Vastra Gotaland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8053',
    value: 'Orebro County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8054',
    value: 'Ostergotland County, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8055',
    value: 'Ostersund, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8056',
    value: 'Vasteras, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8057',
    value: 'Koping, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8058',
    value: 'Visby, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8059',
    value: 'Karlstad, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8060',
    value: 'Kristinehamn, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8061',
    value: 'Karlskrona, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8062',
    value: 'Karlshamn, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8063',
    value: 'Ronneby, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8064',
    value: 'Stockholm, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8065',
    value: 'Sodertalje, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8066',
    value: 'Umea, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8067',
    value: 'Skelleftea, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8068',
    value: 'Jonkoping, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8069',
    value: 'Varnamo, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8070',
    value: 'Tranas, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8071',
    value: 'Vetlanda, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8072',
    value: 'Sundsvall, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8073',
    value: 'Ornskoldsvik, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8074',
    value: 'Harnosand, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8075',
    value: 'Vaxjo, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8076',
    value: 'Ljungby, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8077',
    value: 'Malmo, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8078',
    value: 'Helsingborg, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8079',
    value: 'Kristianstad, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8080',
    value: 'Landskrona, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8081',
    value: 'Trelleborg, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8082',
    value: 'Angelholm, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8083',
    value: 'Lund, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8084',
    value: 'Ystad, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8085',
    value: 'Hassleholm, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8086',
    value: 'Kalmar, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8087',
    value: 'Vastervik, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8088',
    value: 'Oskarshamn, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8089',
    value: 'Halmstad, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8090',
    value: 'Kungsbacka, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8091',
    value: 'Varberg, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8092',
    value: 'Falkenberg, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8093',
    value: 'Eskilstuna, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8094',
    value: 'Nykoping, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8095',
    value: 'Strangnas, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8096',
    value: 'Katrineholm, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8097',
    value: 'Pitea, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8098',
    value: 'Lulea, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8099',
    value: 'Kiruna, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8100',
    value: 'Uppsala, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8101',
    value: 'Enkoping, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8102',
    value: 'Borlange, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8103',
    value: 'Falun, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8104',
    value: 'Avesta, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8105',
    value: 'Ludvika, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8106',
    value: 'Gavle, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8107',
    value: 'Sandviken, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8108',
    value: 'Hudiksvall, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8109',
    value: 'Bollnas, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8110',
    value: 'Soderhamn, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8111',
    value: 'Gothenburg, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8112',
    value: 'Boras, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8113',
    value: 'Skovde, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8114',
    value: 'Uddevalla, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8115',
    value: 'Alingsas, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8116',
    value: 'Lidkoping, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8117',
    value: 'Kungalv, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8118',
    value: 'Orebro, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8119',
    value: 'Karlskoga, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8120',
    value: 'Norrkoping, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8121',
    value: 'Linkoping, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8122',
    value: 'Finspang, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8123',
    value: 'Mjolby, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8124',
    value: 'Motala, Sweden',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8125',
    value: 'Podlaskie District, Wysokie Mazowieckie County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8126',
    value: 'Podlaskie District, Lomza County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8127',
    value: 'Podlaskie District, Grajewo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8128',
    value: 'PodlaskieDistrict, Hajnowka County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8129',
    value: 'Podlaskie District, Bialystok County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8130',
    value: 'Podlaskie District, Kolno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8131',
    value: 'Podlaskie District, Sejny County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8132',
    value: 'Suwalki, Podlaskie District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8133',
    value: 'Podlaskie District, Suwalki County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8134',
    value: 'Podlaskie District, Sokolka County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8135',
    value: 'Podlaskie District, Siemiatycze County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8136',
    value: 'Bialystok, Podlaskie District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8137',
    value: 'Podlaskie District, Zambrow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8138',
    value: 'Podlaskie District, Bielsko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8139',
    value: 'Podlaskie District, Augustow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8140',
    value: 'Podlaskie District, Monki County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8141',
    value: 'Lomza, Podlaskie District,, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8142',
    value: 'Masovian District, Wegrow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8143',
    value: 'Masovian District, Makow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8144',
    value: 'Masovian District, Sierpc County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8145',
    value: 'Masovian District, Przasnysz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8146',
    value: 'Masovian District, Pruszkow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8147',
    value: 'Masovian District, Losice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8148',
    value: 'Masovian District, Lipsko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8149',
    value: 'Masovian District, Ostrow Mazowiecka County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8150',
    value: 'Masovian District, Mlawa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8151',
    value: 'Masovian District, Ostroleka County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8152',
    value: 'Masovian District, Garwolin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8153',
    value: 'Masovian District, Otwock County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8154',
    value: 'Masovian District, Piaseczno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8155',
    value: 'Ostroleka, Masovian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8156',
    value: 'Masovian District, Siedlce County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8157',
    value: 'Masovian District, Sokolow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8158',
    value: 'Masovian District, Gostynin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8159',
    value: 'Masovian District, Sochaczew County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8160',
    value: 'Masovian District, Bialobrzegi County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8161',
    value: 'Masovian District, Wyszkow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8162',
    value: 'Masovian District, Nowy Dwor Mazowiecki County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8163',
    value: 'Masovian District, Grojec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8164',
    value: 'Masovian District, Minsk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8165',
    value: 'Masovian District, Grodzisk Mazowiecki County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8166',
    value: 'Masovian District, Pultusk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8167',
    value: 'Masovian District, Warsaw West County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8168',
    value: 'Masovian District, Plonsk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8169',
    value: 'Radom, Masovian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8170',
    value: 'Masovian District, Wolomin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8171',
    value: 'Masovian District, Ciechanow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8172',
    value: 'Warsaw, Masovian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8173',
    value: 'Masovian District, Zwolen County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8174',
    value: 'Masovian District, Radom County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8175',
    value: 'Masovian District, Przysucha County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8176',
    value: 'Masovian District, Szydlowiec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8177',
    value: 'Plock, Masovian District , Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8178',
    value: 'Masovian District, Zuromin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8179',
    value: 'Masovian District, Legionowo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8180',
    value: 'Masovian District, Zyrardow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8181',
    value: 'Masovian District, Kozienice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8182',
    value: 'Masovian District, Siedlce County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8183',
    value: 'Masovian District, Plock County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8184',
    value: 'Silesian District, Raciborz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8185',
    value: 'Silesian District, Wodzislaw County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8186',
    value: 'Jastrzebie-Zdroj, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8187',
    value: 'Sosnowiec, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8188',
    value: 'Silesian District, Bierun-Ledziny County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8189',
    value: 'Silesian District, Pszczyna County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8190',
    value: 'Silesian District, Tarnow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8191',
    value: 'Silesian District, Rybnik County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8192',
    value: 'Bytom, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8193',
    value: 'Ruda Slaska, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8194',
    value: 'Czestochowa, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8195',
    value: 'Zabrze, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8196',
    value: 'Tychy, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8197',
    value: 'Silesian District, Mikolow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8198',
    value: 'Rybnik, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8199',
    value: 'Silesian District, Bedzin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8200',
    value: 'Bielsko-Biala, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8201',
    value: 'Dabrowa Gornicza, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8202',
    value: 'Jaworzno, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8203',
    value: 'Silesian District, Zywiec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8204',
    value: 'Silesian District, Czestochowa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8205',
    value: 'Swietochlowice, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8206',
    value: 'Piekary Slaskie, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8207',
    value: 'Silesian District, Bielsk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8208',
    value: 'Silesian District, Tarnowskie Gory County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8209',
    value: 'Silesian District, Siemianowice Slaskie, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8210',
    value: 'Silesian District, Lubliniec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8211',
    value: 'Silesian District, Gliwice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8212',
    value: 'Myslowice, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8213',
    value: 'Chorzow, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8214',
    value: 'Silesian District, Zawiercie County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8215',
    value: 'Zory, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8216',
    value: 'Katowice, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8217',
    value: 'Silesian District, Myszkow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8218',
    value: 'Gliwice, Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8219',
    value: 'Silesian District, Klobuck County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8220',
    value: 'Silesian District, Cieszyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8221',
    value: 'Kuyavian-Pomeranian District, Torun County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8222',
    value: 'Kuyavian-Pomeranian District, Aleksandrow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8223',
    value: 'Kuyavian-Pomeranian District, Sepolno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8224',
    value: 'Kuyavian-Pomeranian District, Naklo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8225',
    value: 'Kuyavian-Pomeranian District, Wabrzezno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8226',
    value: 'Kuyavian-Pomeranian District, Chelmno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8227',
    value: 'Grudziadz, Kuyavian-Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8228',
    value: 'Kuyavian-Pomeranian District, Lipno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8229',
    value: 'Kuyavian-Pomeranian District, Brodnica County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8230',
    value: 'Kuyavian-Pomeranian District, Grudziadz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8231',
    value: 'Kuyavian-Pomeranian District, Golub-Dobrzyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8232',
    value: 'Kuyavian-Pomeranian District, Rypin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8233',
    value: 'Kuyavian-Pomeranian District, Swiecie County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8234',
    value: 'Kuyavian-Pomeranian District, Wloclawek County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8235',
    value: 'Kuyavian-Pomeranian District, Znin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8236',
    value: 'Kuyavian-Pomeranian District, Bydgoszcz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8237',
    value: 'Bydgoszcz, Kuyavian-Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8238',
    value: 'Torun, Kuyavian-Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8239',
    value: 'Kuyavian-Pomeranian District, Mogilno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8240',
    value: 'Kuyavian-Pomeranian District, Radziejow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8241',
    value: 'Kuyavian-Pomeranian District, Tuchola County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8242',
    value: 'Kuyavian-Pomeranian District, Inowroclaw County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8243',
    value: 'Wloclawek, Kuyavian-Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8244',
    value: 'Lublin, Lublin District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8245',
    value: 'Lublin District, Parczew County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8246',
    value: 'Lublin District, Bilgoraj County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8247',
    value: 'Biala Podlaska, Lublin District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8248',
    value: 'Lublin District, Janow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8249',
    value: 'Lublin District, Lublin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8250',
    value: 'Lublin District, Swidnik County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8251',
    value: 'Lublin District, Opole County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8252',
    value: 'Lublin District, Krasnik County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8253',
    value: 'Lublin District, Lubartow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8254',
    value: 'Lublin District, Krasnystaw County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8255',
    value: 'Lublin District, Hrubieszow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8256',
    value: 'Lublin District, Chelm County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8257',
    value: 'Lublin District, Tomaszow Lubelski County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8258',
    value: 'Zamosc, Lublin District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8259',
    value: 'Lublin District, Wlodawa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8260',
    value: 'Lublin District, Zamosc County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8261',
    value: 'Lublin District, Biala Podlaska County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8262',
    value: 'Lublin District, Lukow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8263',
    value: 'Lublin District, Leczna County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8264',
    value: 'Chelm, Lublin District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8265',
    value: 'Lublin District, Pulawy County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8266',
    value: 'Lublin District, Ryki County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8267',
    value: 'Lublin District, Radzyn Podlaski County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8268',
    value: 'Lodz District, Opoczno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8269',
    value: 'Lodz District, Lask County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8270',
    value: 'Lodz District, Zgierz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8271',
    value: 'Lodz District, Lodz East County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8272',
    value: 'Lodz District, Rawa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8273',
    value: 'Lodz District, Wielun County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8274',
    value: 'Lodz, Lodz District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8275',
    value: 'Piotrkow Trybunalski, Lodz District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8276',
    value: 'Lodz District, Lowicz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8277',
    value: 'Lodz District, Skierniewice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8278',
    value: 'Lodz District, Pajeczno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8279',
    value: 'Lodz District, Piotrkow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8280',
    value: 'Lodz District, Tomaszow Mazowiecki County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8281',
    value: 'Lodz District, Radomsko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8282',
    value: 'Lodz District, Pabianice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8283',
    value: 'Lodz District, Sieradz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8284',
    value: 'Lodz District, Belchatow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8285',
    value: 'Lodz District, Wieruszow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8286',
    value: 'Lodz District, Zdunska Wola County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8287',
    value: 'Lodz District, Leczyca County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8288',
    value: 'Skierniewice, Lodz District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8289',
    value: 'Lodz District, Brzeziny County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8290',
    value: 'Lodz District, Poddebice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8291',
    value: 'Lodz District, Kutno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8292',
    value: 'West Pomeranian District, Bialogard County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8293',
    value: 'West Pomeranian District, Police County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8294',
    value: 'West Pomeranian District, Swidwin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8295',
    value: 'West Pomeranian District, Pyrzyce County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8296',
    value: 'West Pomeranian District, Mysliborz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8297',
    value: 'West Pomeranian District, Szczecinek County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8298',
    value: 'West Pomeranian District, Choszczno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8299',
    value: 'West Pomeranian District, Lobez County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8300',
    value: 'West Pomeranian District, Gryfino County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8301',
    value: 'West Pomeranian District, Kamien County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8302',
    value: 'Szczecin, West Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8303',
    value: 'West Pomeranian District, Kolobrzeg County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8304',
    value: 'West Pomeranian District, Goleniow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8305',
    value: 'West Pomeranian District, Slawno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8306',
    value: 'West Pomeranian District, Gryfice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8307',
    value: 'West Pomeranian District, Drawsko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8308',
    value: 'West Pomeranian District, Stargard County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8309',
    value: 'Koszalin, West Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8310',
    value: 'West Pomeranian District, Koszalin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8311',
    value: 'West Pomeranian District, Walcz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8312',
    value: 'Swinoujscie, West Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8313',
    value: 'Pomeranian District, Kwidzyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8314',
    value: 'Pomeranian District, Malbork County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8315',
    value: 'Pomeranian District, Bytow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8316',
    value: 'Slupsk, Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8317',
    value: 'Pomeranian District, Gdansk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8318',
    value: 'Pomeranian District, Lebork County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8319',
    value: 'Pomeranian District, Kartuzy County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8320',
    value: 'Gdansk, Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8321',
    value: 'Pomeranian District, Slupsk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8322',
    value: 'Pomeranian District, Koscierzyna County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8323',
    value: 'Pomeranian District, Nowy Dwor Gdanski County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8324',
    value: 'Pomeranian District, Wejherowo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8325',
    value: 'Pomeranian District, Puck County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8326',
    value: 'Pomeranian District, Tczew County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8327',
    value: 'Sopot, Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8328',
    value: 'Pomeranian District, Sztum County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8329',
    value: 'Pomeranian District, Starogard County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8330',
    value: 'Pomeranian District, Czluchow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8331',
    value: 'Pomeranian District, Chojnice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8332',
    value: 'Gdynia, Pomeranian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8333',
    value: 'Konin, Greater Poland District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8334',
    value: 'Greater Poland District, Jarocin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8335',
    value: 'Greater Poland District, Ostrzeszow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8336',
    value: 'Greater Poland District, Wolsztyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8337',
    value: 'Greater Poland District, Miedzychod County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8338',
    value: 'Greater Poland District, Ostrow Wielkopolski County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8339',
    value: 'Greater Poland District, Czarnkow-Trzcianka County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8340',
    value: 'Greater Poland District, Turek County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8341',
    value: 'Greater Poland District, Srem County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8342',
    value: 'Greater Poland District, Poznan County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8343',
    value: 'Greater Poland District, Chodziez County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8344',
    value: 'Greater Poland District, Wrzesnia County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8345',
    value: 'Greater Poland District, Rawicz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8346',
    value: 'Greater Poland District, Konin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8347',
    value: 'Greater Poland District, Koscian County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8348',
    value: 'Greater Poland District, Gostyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8349',
    value: 'Greater Poland District, Grodzisk Wielkopolski County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8350',
    value: 'Greater Poland District, Pila County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8351',
    value: 'Greater Poland District, Wagrowiec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8352',
    value: 'Greater Poland District, Pleszew County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8353',
    value: 'Greater Poland District, Leszno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8354',
    value: 'Greater Poland District, Zlotow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8355',
    value: 'Poznan, Greater Poland District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8356',
    value: 'Greater Poland District, Slupca County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8357',
    value: 'Leszno, Greater Poland District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8358',
    value: 'Greater Poland District, Sroda Wielkopolska County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8359',
    value: 'Greater Poland District, Kepno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8360',
    value: 'Greater Poland District, Krotoszyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8361',
    value: 'Greater Poland District, Nowy Tomysl County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8362',
    value: 'Kalisz, Greater Poland District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8363',
    value: 'Greater Poland District, Szamotuly County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8364',
    value: 'Greater Poland District, Gniezno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8365',
    value: 'Greater Poland District, Kalisz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8366',
    value: 'Greater Poland District, Oborniki County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8367',
    value: 'Greater Poland District, Kolo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8368',
    value: 'Lubusz District, Slubice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8369',
    value: 'Lubusz District, Wschow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8370',
    value: 'Lubusz District, Zielona Gora County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8371',
    value: 'Lubusz District, Zagan County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8372',
    value: 'Lubusz District, Swiebodzin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8373',
    value: 'Lubusz District, Strzelece-Drezdenko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8374',
    value: 'Lubusz District, Nowa Sol County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8375',
    value: 'Lubusz District, Gorzow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8376',
    value: 'Lubusz District, Sulecin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8377',
    value: 'Gorzow Wielkopolski, Lubusz District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8378',
    value: 'Zielona Gora, Lubusz District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8379',
    value: 'Lubusz District, Miedzyrzecz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8380',
    value: 'Lubusz District, Krosno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8381',
    value: 'Lubusz District, Zary County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8382',
    value: 'Warmian-Masurian District, Nowe Miasto County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8383',
    value: 'Warmian-Masurian District, Goldap County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8384',
    value: 'Warmian-Masurian District, Bartoszyce County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8385',
    value: 'Warmian-Masurian District, Olecko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8386',
    value: 'Olsztyn, Warmian-Masurian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8387',
    value: 'Warmian-Masurian District, Elblag County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8388',
    value: 'Warmian-Masurian District, Elk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8389',
    value: 'Warmian-Masurian District, Szczytno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8390',
    value: 'Warmian-Masurian District, Lidzbark County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8391',
    value: 'Warmian-Masurian District, Mragowo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8392',
    value: 'Warmian-Masurian District, Ostroda County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8393',
    value: 'Warmian-Masurian District, Gizycko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8394',
    value: 'Warmian-Masurian District, Olsztyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8395',
    value: 'Warmian-Masurian District, Pisz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8396',
    value: 'Warmian-Masurian District, Braniewo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8397',
    value: 'Warmian-Masurian District, Ketrzyn County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8398',
    value: 'Warmian-Masurian District, Dzialdowo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8399',
    value: 'Warmian-Masurian District, Wegorzewo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8400',
    value: 'Warmian-Masurian District, Ilawa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8401',
    value: 'Warmian-Masurian District, Nidzica County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8402',
    value: 'Elblag, Warmian-Masurian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8403',
    value: 'Lower Silesian District, Strzelin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8404',
    value: 'Wroclaw, Lower Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8405',
    value: 'Lower Silesian District, Kamienna Gora County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8406',
    value: 'Lower Silesian District, Polkowice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8407',
    value: 'Lower Silesian District, Wolow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8408',
    value: 'Jelenia Gora, Lower Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8409',
    value: 'Lower Silesian District, Jawor County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8410',
    value: 'Lower Silesian District, Milicz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8411',
    value: 'Lower Silesian District, Trzebnica County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8412',
    value: 'Lower Silesian District, Zabkowice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8413',
    value: 'Lower Silesian District, Glogow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8414',
    value: 'Lower Silesian District, Swidnica County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8415',
    value: 'Lower Silesian District, Wroclaw County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8416',
    value: 'Lower Silesian District, Gora County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8417',
    value: 'Lower Silesian District, Dzierzoniow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8418',
    value: 'Lower Silesian District, Luban County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8419',
    value: 'Lower Silesian District, Jelenia Gora County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8420',
    value: 'Lower Silesian District, Zgorzelec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8421',
    value: 'Lower Silesian District, Olawa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8422',
    value: 'Legnica, Lower Silesian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8423',
    value: 'Lower Silesian District, Walbrzych County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8424',
    value: 'Lower Silesian District, Olesnica County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8425',
    value: 'Lower Silesian District, Zlotoryja County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8426',
    value: 'Lower Silesian District, Sroda Slaska County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8427',
    value: 'Lower Silesian District, Boleslawiec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8428',
    value: 'Lower Silesian District, Lubin County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8429',
    value: 'Lower Silesian District, Lwowek County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8430',
    value: 'Lower Silesian District, Klodzko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8431',
    value: 'Lower Silesian District, Legnica County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8432',
    value: 'Swietokrzyskie District, Ostrowiec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8433',
    value: 'Swietokrzyskie District, Skarzysko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8434',
    value: 'Swietokrzyskie District, Jedrzejow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8435',
    value: 'Swietokrzyskie District, Opatow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8436',
    value: 'Swietokrzyskie District, Starachowice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8437',
    value: 'Kielce, Swietokrzyskie District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8438',
    value: 'Swietokrzyskie District, Sandomierz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8439',
    value: 'Swietokrzyskie District, Kazimierza County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8440',
    value: 'Swietokrzyskie District, Konskie County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8441',
    value: 'Swietokrzyskie District, Busko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8442',
    value: 'Swietokrzyskie District, Pinczow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8443',
    value: 'Swietokrzyskie District, Wloszczowa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8444',
    value: 'Swietokrzyskie District, Kielce County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8445',
    value: 'Swietokrzyskie District, Staszow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8446',
    value: 'Lesser Poland District, Nowy Sacz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8447',
    value: 'Cracow, Lesser Poland District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8448',
    value: 'Lesser Poland District, Sucha County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8449',
    value: 'Lesser Poland District, Brzeg County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8450',
    value: 'Lesser Poland District, Tarnow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8451',
    value: 'Lesser Poland District, Bochnia County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8452',
    value: 'Lesser Poland District, Wadowice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8453',
    value: 'Lesser Poland District, Limanowa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8454',
    value: 'Lesser Poland District, Myslenice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8455',
    value: 'Lesser Poland District, Proszowice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8456',
    value: 'Lesser Poland District, Dabrowa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8457',
    value: 'Lesser Poland District, Cracow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8458',
    value: 'Lesser Poland District, Nowy Targ County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8459',
    value: 'Tarnow, Lesser Poland District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8460',
    value: 'Lesser Poland District, Gorlice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8461',
    value: 'Lesser Poland District, Tatra County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8462',
    value: 'Lesser Poland District, Wieliczka County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8463',
    value: 'Lesser Poland District, Chrzanow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8464',
    value: 'Lesser Poland District, Oswiecim County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8465',
    value: 'Lesser Poland District, Olkusz County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8466',
    value: 'Lesser Poland District, Miechow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8467',
    value: 'Nowy Sacz, Lesser Poland District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8468',
    value: 'Opole District, Namyslow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8469',
    value: 'Opole District, Brzeg Opolski County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8470',
    value: 'Opole District, Glubczyce County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8471',
    value: 'Opole District, Krapkowice County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8472',
    value: 'Opole District, Prudnik County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8473',
    value: 'Opole District, Olesno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8474',
    value: 'Opole District, Strzelce County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8475',
    value: 'Opole District, Nysa County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8476',
    value: 'Opole District, Opole County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8477',
    value: 'Opole District, Kedzierzyn-Kozle County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8478',
    value: 'Opole District, Kluczbork County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8479',
    value: 'Opole, Opole District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8480',
    value: 'Subcarpathian District, Strzyzow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8481',
    value: 'Subcarpathian District, Przemysl County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8482',
    value: 'Subcarpathian District, Kolbuszow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8483',
    value: 'Subcarpathian District, Lubaczow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8484',
    value: 'Subcarpathian District, Rzeszow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8485',
    value: 'Subcarpathian District, Przeworsk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8486',
    value: 'Subcarpathian District, Jaslo County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8487',
    value: 'Subcarpathian District, Debica County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8488',
    value: 'Subcarpathian District, Stalowa Wola County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8489',
    value: 'Subcarpathian District, Brzozow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8490',
    value: 'Subcarpathian District, Mielec County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8491',
    value: 'Subcarpathian District, Lancut County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8492',
    value: 'Subcarpathian District, Sanok County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8493',
    value: 'Tarnobrzeg, Subcarpathian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8494',
    value: 'Subcarpathian District, Tarnobrzeg County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8495',
    value: 'Subcarpathian District, Lezajsk County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8496',
    value: 'Subcarpathian District, Nisko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8497',
    value: 'Subcarpathian District, Przemysl, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8498',
    value: 'Krosno, Subcarpathian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8499',
    value: 'Rzeszow, Subcarpathian District, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8500',
    value: 'Sanok, Subcarpathian District,, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8501',
    value: 'Subcarpathian District, Jaroslaw County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8502',
    value: 'Subcarpathian District, Ropczyce-Sedziszow County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8503',
    value: 'Subcarpathian District, Krosno County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8504',
    value: 'Subcarpathian District, Bieszczady County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8505',
    value: 'Subcarpathian District, Lesko County, Poland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8577',
    value: 'Bengkulu Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8578',
    value: 'West Papua Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8579',
    value: 'Aceh Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8580',
    value: 'East Kalimantan Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8581',
    value: 'West Sulawesi Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8582',
    value: 'Papua Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8583',
    value: 'East Java Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8584',
    value: 'Jambi Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8585',
    value: 'Bangka-Belitung Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8586',
    value: 'Central Java Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8587',
    value: 'West Kalimantan Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8588',
    value: 'Riau Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8589',
    value: 'North Maluku Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8590',
    value: 'Riau Islands Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8591',
    value: 'Central Kalimantan Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8592',
    value: 'East Nusa Tenggara Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8593',
    value: 'South Sumatera Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8594',
    value: 'Greater Jakarta Area, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8595',
    value: 'West Nusa Tenggara Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8596',
    value: 'Maluku Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8597',
    value: 'South East Sulawesi Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8598',
    value: 'Lampung Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8599',
    value: 'North Sulawesi Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8600',
    value: 'North Sumatera Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8601',
    value: 'Yogyakarta Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8602',
    value: 'Banten Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8603',
    value: 'South Sulawesi Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8604',
    value: 'Gorontalo Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8605',
    value: 'West Java Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8606',
    value: 'South Kalimantan Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8607',
    value: 'West Sumatera Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8608',
    value: 'Central Sulawesi Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8609',
    value: 'Bali Province, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8610',
    value: 'Bengkulu Area, Bengkulu, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8611',
    value: 'Manokwari Area, West Papua, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8612',
    value: 'Banda Aceh Area, Aceh, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8613',
    value: 'Samarinda Area, East Kalimantan, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8614',
    value: 'Mamuju Area, West Sulawesi, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8615',
    value: 'Jayapura Area, Papua, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8616',
    value: 'Surabaya Area, East Java, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8617',
    value: 'Malang Area, East Java, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8618',
    value: 'Jambi Area, Jambi, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8619',
    value: 'Pangkal Pinang Area, Bangka-Belitung, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8620',
    value: 'Semarang Area, Central Java, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8621',
    value: 'Pontianak Area, West Kalimantan, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8622',
    value: 'Pekanbaru Area, Riau, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8623',
    value: 'Ternate Area, North Maluku, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8624',
    value: 'Tanjung Pinang Area, Riau Islands, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8625',
    value: 'Palangka Raya Area, Central Kalimantan, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8626',
    value: 'Kupang Area, East Nusa Tenggara, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8627',
    value: 'Palembang Area, South Sumatera, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8628',
    value: 'Mataram Area, West Nusa Tenggara, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8629',
    value: 'Ambon Area, Maluku, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8630',
    value: 'Kendari Area, South East Sulawesi, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8631',
    value: 'Bandar Lampung Area, Lampung, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8632',
    value: 'Manado Area, North Sulawesi, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8633',
    value: 'Medan Area, North Sumatera, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8634',
    value: 'Yogyakarta Area, Yogyakarta, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8635',
    value: 'Serang Area, Banten, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8636',
    value: 'Makassar Area, South Sulawesi, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8637',
    value: 'Gorontalo Area, Gorontalo, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8638',
    value: 'Bandung Area, West Java, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8639',
    value: 'Banjarmasin Area, South Kalimantan, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8640',
    value: 'Padang Area, West Sumatera, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8641',
    value: 'Palu Area, Central Sulawesi, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8642',
    value: 'Denpasar Area, Bali, Indonesia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8643',
    value: 'Prague, The Capital, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8644',
    value: 'District Prague-East, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8645',
    value: 'District Prague-West, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8646',
    value: 'District Klatovy, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8647',
    value: 'District Plzen-North, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8648',
    value: 'District Plzen-City, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8649',
    value: 'District Rokycany, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8650',
    value: 'District Domazlice, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8651',
    value: 'District Tachov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8652',
    value: 'District Plzen-South, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8653',
    value: 'District Cheb, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8654',
    value: 'District Karlovy Vary, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8655',
    value: 'District Sokolov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8656',
    value: 'District Ostrava-City, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8657',
    value: 'District Bruntal, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8658',
    value: 'District Frydek-Mistek, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8659',
    value: 'District Novy Jicin, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8660',
    value: 'District Karvina, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8661',
    value: 'District Opava, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8662',
    value: 'District Most, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8663',
    value: 'District Litomerice, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8664',
    value: 'District Teplice, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8665',
    value: 'District Louny, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8666',
    value: 'District Decin, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8667',
    value: 'District Usti nad Labem, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8668',
    value: 'District Chomutov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8669',
    value: 'District Prostejov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8670',
    value: 'District Prerov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8671',
    value: 'District Jesenik, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8672',
    value: 'District Sumperk, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8673',
    value: 'District Olomouc, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8674',
    value: 'District Usti nad Orlici, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8675',
    value: 'District Pardubice, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8676',
    value: 'District Chrudim, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8677',
    value: 'District Svitavy, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8678',
    value: 'District Nachod, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8679',
    value: 'District Jicin, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8680',
    value: 'District Trutnov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8681',
    value: 'District Rychnov nad Kneznou, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8682',
    value: 'District Hradec Kralove, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8683',
    value: 'District Prachatice, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8684',
    value: 'District Strakonice, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8685',
    value: 'District Ceske Budejovice, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8686',
    value: 'District Cesky Krumlov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8687',
    value: 'District Tabor, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8688',
    value: 'District Pisek, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8689',
    value: 'District Jindrichuv Hradec, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8690',
    value: 'District Zlin, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8691',
    value: 'District Uherske Hradiste, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8692',
    value: 'District Vsetin, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8693',
    value: 'District Kromeriz, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8694',
    value: 'District Vyskov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8695',
    value: 'District Blansko, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8696',
    value: 'District Hodonin, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8697',
    value: 'District Breclav, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8698',
    value: 'District Znojmo, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8699',
    value: 'District Brno-City, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8700',
    value: 'District Brno-Country, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8701',
    value: 'District Semily, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8702',
    value: 'District Liberec, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8703',
    value: 'District Ceska Lipa, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8704',
    value: 'District Jablonec nad Nisou, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8705',
    value: 'District Pelhrimov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8706',
    value: 'District Zdar nad Sazavou, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8707',
    value: 'District Havlickuv Brod, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8708',
    value: 'District Trebic, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8709',
    value: 'District Jihlava, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8710',
    value: 'District Rakovnik, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8711',
    value: 'District Beroun, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8712',
    value: 'District Kladno, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8713',
    value: 'District Benesov, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8714',
    value: 'District Nymburk, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8715',
    value: 'District Kutna Hora, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8716',
    value: 'District Pribram, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8717',
    value: 'District Mlada Boleslav, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8718',
    value: 'District Melnik, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8719',
    value: 'District Kolin, Czech Republic',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8827',
    value: 'Agrigento Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8828',
    value: 'Alessandria Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8829',
    value: 'Ancona Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8830',
    value: 'Aosta Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8831',
    value: 'Ascoli Piceno Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8832',
    value: 'Avellino Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8833',
    value: 'Barletta-Andria-Trani Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8834',
    value: 'Belluno Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8835',
    value: 'Biella Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8836',
    value: 'Brindisi Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8837',
    value: 'Campobasso Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8838',
    value: 'Carbonia Iglesias Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8839',
    value: 'Cremona Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8840',
    value: 'Crotone Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8841',
    value: 'Enna Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8842',
    value: 'Fermo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8843',
    value: 'Ferrara Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8844',
    value: 'Forlì Cesena Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8845',
    value: 'Frosinone Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8846',
    value: 'Gorizia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8847',
    value: 'Imperia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8848',
    value: 'Isernia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8849',
    value: 'Lecco Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8850',
    value: 'Lodi Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8851',
    value: 'Lucca Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8852',
    value: 'Macerata Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8853',
    value: 'Mantova Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8854',
    value: 'Massa Carrara Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8855',
    value: 'Medio Campidano Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8856',
    value: 'Nuoro Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8857',
    value: 'Ogliastra Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8858',
    value: 'Olbia Tempio Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8859',
    value: 'Oristano Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8860',
    value: 'Pavia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8861',
    value: 'Pesaro Urbino Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8862',
    value: 'Piacenza Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8863',
    value: 'Pisa Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8864',
    value: 'Pistoia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8865',
    value: 'Ragusa Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8866',
    value: 'Reggio Emilia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8867',
    value: 'Rieti Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8868',
    value: 'Rovigo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8869',
    value: 'Siena Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8870',
    value: 'Sondrio Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8871',
    value: 'Teramo Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8872',
    value: 'Trapani Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8873',
    value: 'Verbano-Cusio-Ossola Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8874',
    value: 'Vercelli Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8875',
    value: 'Vibo Valentia Area, Italy',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8876',
    value: 'Rest of Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8877',
    value: 'Rest of Yunnan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8878',
    value: 'Rest of Xinjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8879',
    value: 'Rest of Tibet, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8880',
    value: 'Tianjin Suburb, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8881',
    value: 'Rest of Sichuan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8882',
    value: 'Rest of Shanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8883',
    value: 'Shanghai Suburb, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8884',
    value: 'Rest of Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8885',
    value: 'Rest of Shaanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8886',
    value: 'Rest of Qinghai, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8887',
    value: 'Rest of Ningxia, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8888',
    value: 'Rest of Liaoning, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8889',
    value: 'Rest of Jilin, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8890',
    value: 'Rest of Jiangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8891',
    value: 'Rest of Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8892',
    value: 'Rest of Inner Mongolia, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8893',
    value: 'Rest of Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8894',
    value: 'Rest of Hubei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8895',
    value: 'Rest of Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8896',
    value: 'Rest of Heilongjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8897',
    value: 'Rest of Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8898',
    value: 'Rest of Hainan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8899',
    value: 'Rest of Guizhou, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8900',
    value: 'Rest of Guangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8901',
    value: 'Rest of Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8902',
    value: 'Rest of Gansu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8903',
    value: 'Rest of Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8904',
    value: 'Chongqing Suburb, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8905',
    value: 'Beijing Suburb, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8906',
    value: 'Rest of Anhui, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8907',
    value: 'Tianjin City, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8908',
    value: 'Chongqing City, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8909',
    value: 'Shanghai City, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8910',
    value: 'Shenzhen, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8911',
    value: 'Beijing City, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8912',
    value: 'Fuzhou, Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8913',
    value: 'Xiamen, Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8914',
    value: 'Nanping, Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8915',
    value: 'Quanzhou, Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8916',
    value: 'Longyan, Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8917',
    value: 'Zhangzhou, Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8918',
    value: 'Putian, Fujian, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8919',
    value: 'Guangzhou, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8920',
    value: 'Hefei, Anhui, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8921',
    value: 'Wuhu, Anhui, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8922',
    value: 'Bengbu, Anhui, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8923',
    value: 'Foshan, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8924',
    value: 'Dongguan, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8925',
    value: 'Huizhou, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8926',
    value: 'Jieyang, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8927',
    value: 'Shantou, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8928',
    value: 'Zhanjiang, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8929',
    value: 'Maoming, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8930',
    value: 'Zhuhai, Guangdong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8931',
    value: 'Hangzhou, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8932',
    value: 'Ningbo, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8933',
    value: 'Jiaxing, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8934',
    value: 'Huzhou, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8935',
    value: 'Jinhua, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8936',
    value: 'Shaoxing, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8937',
    value: 'Taizhou, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8938',
    value: 'Wenzhou, Zhejiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8939',
    value: 'Nanjing, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8940',
    value: 'Changzhou, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8941',
    value: 'Huaian, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8942',
    value: 'Lianyungang, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8943',
    value: 'Nantong, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8944',
    value: 'Suzhou, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8945',
    value: 'Wuxi, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8946',
    value: 'Xuzhou, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8948',
    value: 'Yancheng, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8949',
    value: 'Yangzhou, Jiangsu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8950',
    value: 'Lanzhou, Gansu, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8951',
    value: 'Nanning, Guangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8952',
    value: 'Guilin, Guangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8953',
    value: 'Yulin, Guangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8954',
    value: 'Lasa, Tibet, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8955',
    value: 'Haikou, Hainan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8956',
    value: 'Sanya, Hainan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8957',
    value: 'Guiyang, Guizhou, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8958',
    value: 'Zunyi, Guizhou, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8959',
    value: 'Baotou, Inner Mongolia, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8961',
    value: 'Huhehaote, Inner Mongolia, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8962',
    value: 'Chifeng, Inner Mongolia, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8963',
    value: 'Chengdu, Sichuan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8964',
    value: 'Mianyang, Sichuan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8965',
    value: 'Nanchong, Sichuan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8966',
    value: 'Deyang, Sichuan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8967',
    value: 'Luzhou, Sichuan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8968',
    value: 'Yibin, Sichuan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8969',
    value: 'Shijiazhuang, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8970',
    value: 'Baoding, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8971',
    value: 'Handan, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8972',
    value: 'Hengshui, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8973',
    value: 'Langfang, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8974',
    value: 'Tangshan, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8975',
    value: 'Xingtai, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8976',
    value: 'Zhangjiakou, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8977',
    value: 'Qinhuangdao, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8978',
    value: 'Chengde, Hebei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8979',
    value: 'Wuhan, Hubei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8981',
    value: 'Huanggang, Hubei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8982',
    value: 'Jingzhou, Hubei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8983',
    value: 'Xiaogan, Hubei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8984',
    value: 'Yichang, Hubei, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8985',
    value: 'Changsha, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8986',
    value: 'Changde, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8987',
    value: 'Chenzhou, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8988',
    value: 'Hengyang, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8989',
    value: 'Huaihua, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8990',
    value: 'Shaoyang, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8991',
    value: 'Yiyang, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8992',
    value: 'Yongzhou, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8993',
    value: 'Yueyang, Hunan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8994',
    value: 'Haerbin, Heilongjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8995',
    value: 'Qiqihaer, Heilongjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8996',
    value: 'Suihua, Heilongjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8997',
    value: 'Daqing, Heilongjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8998',
    value: 'Zhengzhou, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:8999',
    value: 'Anyang, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9000',
    value: 'Kaifeng, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9001',
    value: 'Luoyang, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9002',
    value: 'Nanyang, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9003',
    value: 'Shangqiu, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9004',
    value: 'Zhoukou, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9005',
    value: 'Zhumadian, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9006',
    value: 'Pingdingshan, Henan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9007',
    value: 'Nanchang, Jiangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9009',
    value: 'Ganzhou, Jiangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9010',
    value: 'Shangrao, Jiangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9011',
    value: 'Yichun, Jiangxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9014',
    value: 'Changchun, Jilin, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9015',
    value: 'Jilin, Jilin, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9016',
    value: 'Siping, Jilin, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9017',
    value: 'Shenyang, Liaoning, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9018',
    value: 'Dalian, Liaoning, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9019',
    value: 'Anshan, Liaoning, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9020',
    value: 'Yinchuan, Ningxia, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9021',
    value: 'Xining, Qinghai, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9022',
    value: 'Xi‘an, Shaanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9024',
    value: 'Baoji, Shaanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9025',
    value: 'Weinan, Shaanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9026',
    value: 'Xianyang, Shaanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9027',
    value: 'Jinan, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9028',
    value: 'Qingdao, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9029',
    value: 'Weifang, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9030',
    value: 'Weihai, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9031',
    value: 'Yantai, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9032',
    value: 'Jining, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9033',
    value: 'Liaocheng, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9034',
    value: 'Linyi, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9035',
    value: 'Heze, Shandong, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9036',
    value: 'Taiyuan, Shanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9037',
    value: 'Linfen, Shanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9038',
    value: 'Yuncheng, Shanxi, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9039',
    value: 'Kunming, Yunnan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9040',
    value: 'Qujing, Yunnan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9041',
    value: 'Zhaotong, Yunnan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9042',
    value: 'Lijiang, Yunnan, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9043',
    value: 'Urumqi, Xinjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9044',
    value: 'Ili Prefecture, Xinjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9045',
    value: 'Kashgar Prefecture, Xinjiang, China',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9190',
    value: 'West Sussex, United Kingdom, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9191',
    value: 'South Lanarkshire, United Kingdom, United Kingdom',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9194',
    value: 'Auckland, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9195',
    value: 'Bay of Plenty, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9196',
    value: 'Canterbury & West Coast, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9197',
    value: 'Gisborne & Hawkes Bay, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9198',
    value: 'Nelson, Marlborough & Tasman, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9199',
    value: 'Northland, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9200',
    value: 'Otago & Southland, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9202',
    value: 'Taranaki, Wanganui & Manawatu, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9203',
    value: 'Waikato, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9204',
    value: 'Wellington & Wairarapa, New Zealand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9205',
    value: 'Changhua County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9207',
    value: 'Chiayi County/City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9208',
    value: 'Ang Thong, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9209',
    value: 'Hsinchu County/City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9210',
    value: 'Hualien County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9211',
    value: 'Kaohsiung City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9213',
    value: 'Keelung City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9214',
    value: 'Kinmen County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9215',
    value: 'Lienchiang County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9216',
    value: 'Miaoli County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9218',
    value: 'Nantou County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9219',
    value: 'Penghu County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9220',
    value: 'Pingtung County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9221',
    value: 'Taichung City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9223',
    value: 'Tainan City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9225',
    value: 'Taipei City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9226',
    value: 'New Taipei City, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9227',
    value: 'Taitung County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9228',
    value: 'Taoyuan County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9229',
    value: 'Yilan County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9230',
    value: 'Yunlin County, Taiwan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9231',
    value: 'ARMM - Autonomous Region in Muslim Mindanao, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9232',
    value: 'CAR - Cordillera Administrative Region, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9233',
    value: 'NCR - National Capital Region, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9234',
    value: 'Region I - Ilocos, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9235',
    value: 'Region II - Cagayan Valley, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9236',
    value: 'Region III - Central Luzon, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9237',
    value: 'Region IVA - Calabarzon, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9238',
    value: 'Region IVB - Mimaropa, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9239',
    value: 'Region IX - Zamboanga Peninsula, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9240',
    value: 'Region V - Bicol, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9241',
    value: 'Region VI - Western Visayas, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9242',
    value: 'Region VII - Central Visayas, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9243',
    value: 'Region VIII - Eastern Visayas, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9244',
    value: 'Region X - Northern Mindanao, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9245',
    value: 'Region XI - Davao, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9246',
    value: 'Region XII - Soccsksargen, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9247',
    value: 'Region XIII - Caraga, Philippines',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9250',
    value: 'Bangkok Metropolitan Area, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9251',
    value: 'Chai Nat, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9252',
    value: 'Kamphaeng Phet, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9253',
    value: 'Lopburi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9254',
    value: 'Nakhon Nayok, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9256',
    value: 'Nakhon Sawan, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9259',
    value: 'Phetchabun, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9260',
    value: 'Phichit, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9261',
    value: 'Phitsanulok, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9262',
    value: 'Phra Nakhon Si Ayutthaya, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9265',
    value: 'Samut Songkhram, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9266',
    value: 'Saraburi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9267',
    value: 'Sing Buri, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9268',
    value: 'Sukhothai, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9269',
    value: 'Suphan Buri, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9270',
    value: 'Uthai Thani, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9271',
    value: 'Chachoengsao, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9272',
    value: 'Chanthaburi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9273',
    value: 'Chonburi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9274',
    value: 'Prachin Buri, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9275',
    value: 'Rayong, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9276',
    value: 'Sa Kaeo, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9277',
    value: 'Trat, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9278',
    value: 'Chiang Mai, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9279',
    value: 'Chiang Rai, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9280',
    value: 'Lampang, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9281',
    value: 'Lamphun, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9282',
    value: 'Mae Hong Son, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9283',
    value: 'Nan, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9284',
    value: 'Phayao, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9285',
    value: 'Phrae, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9286',
    value: 'Uttaradit, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9287',
    value: 'Amnat Charoen, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9288',
    value: 'Bueng Kan, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9289',
    value: 'Buri Ram, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9290',
    value: 'Chaiyaphum, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9291',
    value: 'Kalasin, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9292',
    value: 'Khon Kaen, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9293',
    value: 'Loei, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9294',
    value: 'Maha Sarakham, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9295',
    value: 'Mukdahan, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9296',
    value: 'Nakhon Phanom, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9297',
    value: 'Nakhon Ratchasima, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9298',
    value: 'Nong Bua Lamphu, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9299',
    value: 'Nong Khai, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9300',
    value: 'Roi Et, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9301',
    value: 'Sakon Nakhon, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9302',
    value: 'Sisaket, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9303',
    value: 'Surin, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9304',
    value: 'Ubon Ratchathani, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9305',
    value: 'Udon Thani, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9306',
    value: 'Yasothon, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9307',
    value: 'Chumphon, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9308',
    value: 'Krabi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9309',
    value: 'Nakhon Si Thammarat, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9310',
    value: 'Narathiwat, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9311',
    value: 'Pattani, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9312',
    value: 'Phang Nga, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9313',
    value: 'Phatthalung, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9314',
    value: 'Phuket, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9315',
    value: 'Ranong, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9316',
    value: 'Satun, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9317',
    value: 'Songkhla, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9318',
    value: 'Surat Thani, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9319',
    value: 'Trang, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9320',
    value: 'Yala, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9321',
    value: 'Kanchanaburi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9322',
    value: 'Phetchaburi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9323',
    value: 'Prachuap Khiri Khan, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9324',
    value: 'Ratchaburi, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9325',
    value: 'Tak, Thailand',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9326',
    value: 'Northern Region, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9327',
    value: 'Central Region, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9328',
    value: 'Southern Region, Funen, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9329',
    value: 'Southern Region, South Jutland, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9330',
    value: 'Copenhagen Area, Capital Region, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9331',
    value: 'Sealand Region, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9332',
    value: 'Bornholm, Denmark',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9334',
    value: 'American Samoa area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9335',
    value: 'Austria area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9336',
    value: 'Bulgaria area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9337',
    value: 'Algeria area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9338',
    value: 'French Guiana area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9339',
    value: 'Guadeloupe area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9340',
    value: 'Guatemala area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9341',
    value: 'Guam area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9342',
    value: 'Hungary area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9343',
    value: 'Liechtenstein area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9344',
    value: 'Monaco area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9345',
    value: 'Marshall Islands area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9346',
    value: 'Northern Mariana Islands area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9347',
    value: 'Martinique area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9348',
    value: 'Saint Pierre and Miquelon area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9349',
    value: 'Puerto Rico area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9350',
    value: 'Reunion area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9351',
    value: 'Svalbard and Jan Mayen area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9352',
    value: 'Slovak Republic area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9353',
    value: 'Virgin Islands (U.S.) area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9354',
    value: 'Mayotte area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9355',
    value: 'Helsinki Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9356',
    value: 'Nummela Area , Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9357',
    value: 'Karkkila Area , Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9358',
    value: 'Sipoo Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9359',
    value: 'Kerava Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9360',
    value: 'Tuusula Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9361',
    value: 'Jrvenp Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9362',
    value: 'Hyvink Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9363',
    value: 'Porvoo Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9364',
    value: 'Lohja Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9365',
    value: 'Raseborg Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9366',
    value: 'Riihimki Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9367',
    value: 'Hmeenlinna Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9368',
    value: 'Lahti Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9369',
    value: 'Turku Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9370',
    value: 'Mariehamn Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9371',
    value: 'Pori Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9372',
    value: 'Forssa Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9373',
    value: 'Tampere Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9374',
    value: 'Jyvskyl Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9375',
    value: 'Kouvola Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9376',
    value: 'Kotka Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9377',
    value: 'Mikkeli Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9378',
    value: 'Lappeenranta Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9379',
    value: 'Imatra Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9380',
    value: 'Savonlinna Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9381',
    value: 'Parikkala Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9382',
    value: 'Seinjoki Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9383',
    value: 'Vaasa Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9384',
    value: 'Kokkola Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9385',
    value: 'Kuopio Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9386',
    value: 'Pieksmki Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9387',
    value: 'Pieksamki Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9388',
    value: 'Varkaus Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9389',
    value: 'Joensuu Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9390',
    value: 'Ylivieska Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9391',
    value: 'Kajaani Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9392',
    value: 'Oulu Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9393',
    value: 'Kemi Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9394',
    value: 'Rovaniemi Area, Finland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9400',
    value: 'Kiryat Ono Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9401',
    value: 'Tel Aviv Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9402',
    value: 'Ramat HaSharon Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9403',
    value: 'Ramat Gan Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9404',
    value: 'Or Yehuda Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9405',
    value: 'Azor Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9406',
    value: 'Kfar Shmaryahu Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9407',
    value: 'Holon Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9408',
    value: 'Herzliya Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9409',
    value: 'Giv’atayim Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9410',
    value: 'Bnei Brak Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9411',
    value: 'Bat Yam Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9412',
    value: 'Safed Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9413',
    value: 'Nazareth Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9414',
    value: 'Kinneret Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9415',
    value: 'Jezreel Valley Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9416',
    value: 'Golan Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9417',
    value: 'Acre Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9418',
    value: 'Rishon LeZion Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9419',
    value: 'Rehovot Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9420',
    value: 'Ramla Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9421',
    value: 'Petah Tikva Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9422',
    value: 'Netanya Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9423',
    value: 'Haifa Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9424',
    value: 'Hadera Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9425',
    value: 'Be’er Sheva Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9426',
    value: 'Ashkelon Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9427',
    value: 'Judea and Samaria Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9428',
    value: 'Jerusalem Area, Israel',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9429',
    value: 'Bend, Oregon Area',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9430',
    value: 'Simpson Area, KY',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9431',
    value: 'Allen Area, KY',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9432',
    value: 'Logan Area, KY',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9433',
    value: 'Todd Area, KY',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9434',
    value: 'Bowling Green Area, KY',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9435',
    value: 'Glasglow Area, KY',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9436',
    value: 'Adair Area, KY',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9437',
    value: 'Corrientes Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9438',
    value: 'Santa Fe Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9439',
    value: 'Greater Buenos Aires, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9440',
    value: 'Córdoba Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9441',
    value: 'San Juan Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9442',
    value: 'Jujuy Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9443',
    value: 'Mendoza Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9444',
    value: 'Salta Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9445',
    value: 'Entre Ríos Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9446',
    value: 'Santa Cruz Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9447',
    value: 'Tucumán Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9448',
    value: 'Tierra del Fuego Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9449',
    value: 'Chubut Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9450',
    value: 'San Luis Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9451',
    value: 'Río Negro Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9452',
    value: 'Catamarca Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9453',
    value: 'Santiago del Estero Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9454',
    value: 'La Pampa Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9455',
    value: 'La Rioja Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9456',
    value: 'Neuquén Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9457',
    value: 'Chaco Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9458',
    value: 'Formosa Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9459',
    value: 'Misiones Province, Argentina',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9460',
    value: 'Central Area, Bahrain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9461',
    value: 'Northern Area, Bahrain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9462',
    value: 'Capital Area, Bahrain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9463',
    value: 'Southern Area, Bahrain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9464',
    value: 'Muharraq Area, Bahrain',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9465',
    value: 'Llanquihue Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9466',
    value: 'Bío Bío Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9467',
    value: 'Melipilla Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9468',
    value: 'Aisén Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9469',
    value: 'San Antonio Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9470',
    value: 'Arica Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9471',
    value: 'Cordillera Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9472',
    value: 'Cautín Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9473',
    value: 'Chacabuco Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9474',
    value: 'Valdivia Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9475',
    value: 'Arauco Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9476',
    value: 'Colchagua Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9477',
    value: 'Cauquenes Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9478',
    value: 'Ñuble Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9479',
    value: 'El Loa Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9480',
    value: 'Huasco Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9481',
    value: 'Marga Marga Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9482',
    value: 'Petorca Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9483',
    value: 'Santiago Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9484',
    value: 'General Carrera Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9485',
    value: 'Talagante Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9486',
    value: 'Iquique Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9487',
    value: 'Copiapó Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9488',
    value: 'Cachapoal Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9489',
    value: 'Talca Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9490',
    value: 'Coihaique Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9491',
    value: 'Choapa Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9492',
    value: 'San Felipe de Aconcagua Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9493',
    value: 'Los Andes Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9494',
    value: 'Parinacota Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9495',
    value: 'Quillota Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9496',
    value: 'Última Esperanza Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9497',
    value: 'Linares Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9498',
    value: 'Chañaral Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9499',
    value: 'Tierra del Fuego Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9500',
    value: 'Magallanes Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9501',
    value: 'Tamarugal Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9502',
    value: 'Capitán Prat Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9503',
    value: 'Ranco Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9504',
    value: 'Isla de Pascua Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9505',
    value: 'Chiloé Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9506',
    value: 'Limarí Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9507',
    value: 'Elqui Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9508',
    value: 'Valparaíso Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9509',
    value: 'Malleco Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9510',
    value: 'Antártica Chilena Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9511',
    value: 'Palena Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9512',
    value: 'Maipo Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9513',
    value: 'Concepción Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9514',
    value: 'Cardenal Caro Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9515',
    value: 'Tocopilla Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9516',
    value: 'Curicó Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9517',
    value: 'Osorno Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9518',
    value: 'Antofagasta Province, Chile',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9519',
    value: 'Cauca Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9520',
    value: 'Huila Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9521',
    value:
      'Archipelago of San Andrés, Providencia and Santa Catalina, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9522',
    value: 'Cundinamarca Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9523',
    value: 'Antioquia Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9524',
    value: 'Cesar Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9525',
    value: 'Amazonas Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9526',
    value: 'Guaviare Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9527',
    value: 'Valle del Cauca Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9528',
    value: 'La Guajira Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9529',
    value: 'Norte de Santander Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9530',
    value: 'Meta Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9531',
    value: 'Caldas Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9532',
    value: 'Chocó Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9533',
    value: 'Casanare Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9534',
    value: 'Atlántico Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9535',
    value: 'Risaralda Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9536',
    value: 'Sucre Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9537',
    value: 'Caquetá Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9538',
    value: 'Magdalena Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9539',
    value: 'Vichada Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9540',
    value: 'Arauca Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9541',
    value: 'Boyacá Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9542',
    value: 'Santander Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9543',
    value: 'Córdoba Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9544',
    value: 'Vaupés Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9545',
    value: 'Guainía Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9546',
    value: 'Tolima Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9547',
    value: 'Putumayo Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9548',
    value: 'Nariño Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9549',
    value: 'Bogotá D.C. Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9550',
    value: 'Quindío Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9551',
    value: 'Bolívar Area, Colombia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9552',
    value: 'Azarbayejan-e Sharghi Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9553',
    value: 'Yazd Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9554',
    value: 'Khuzestan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9555',
    value: 'Fars Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9556',
    value: 'Kohgiluyeh and Boyerahmad Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9557',
    value: 'Kermanshah Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9558',
    value: 'Hamedan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9559',
    value: 'Qom Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9560',
    value: 'Bushehr Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9561',
    value: 'Mazandaran Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9562',
    value: 'Ilam Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9563',
    value: 'Markazi Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9564',
    value: 'Semnan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9565',
    value: 'Zanjan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9566',
    value: 'Tehran Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9567',
    value: 'Kordestan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9568',
    value: 'Azarbayejan-e Gharbi Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9569',
    value: 'Gilan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9570',
    value: 'Ardebil Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9571',
    value: 'Khorasan-e Jonubi Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9572',
    value: 'Khorasan-e Razavi Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9573',
    value: 'Chaharmahal and Bakhtiyari Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9574',
    value: 'Esfahan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9575',
    value: 'Hormozgan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9576',
    value: 'Khorasan-e Shomali Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9577',
    value: 'Qazvin Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9578',
    value: 'Golestan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9579',
    value: 'Sistan and Baluchestan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9580',
    value: 'Kerman Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9581',
    value: 'Alborz Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9582',
    value: 'Lorestan Province, Iran',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9583',
    value: 'Nador Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9584',
    value: 'Azilal Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9585',
    value: 'Al Haouz Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9586',
    value: 'El Kelaâ des Sraghna Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9587',
    value: 'Safi Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9588',
    value: 'Boujdour Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9589',
    value: 'M’diq-Fnideq Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9590',
    value: 'Khénifra Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9591',
    value: 'Al Hoceima Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9592',
    value: 'Béni Mellal Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9593',
    value: 'Tétouan Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9594',
    value: 'Taourirt Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9595',
    value: 'Khémisset Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9596',
    value: 'Fahs Anjra Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9597',
    value: 'Larache Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9598',
    value: 'El Jadida Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9599',
    value: 'Youssoufia Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9600',
    value: 'Fquih Ben Salah Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9601',
    value: 'Driouch Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9602',
    value: 'Kénitra Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9603',
    value: 'Berrechid Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9604',
    value: 'Mohammédia Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9605',
    value: 'Rehamna Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9606',
    value: 'Assa-Zag Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9607',
    value: 'Taounate Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9608',
    value: 'Guelmim Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9609',
    value: 'Sefrou Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9610',
    value: 'Meknès Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9611',
    value: 'Sidi Ifni Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9612',
    value: 'Berkane Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9613',
    value: 'Chefchaouen Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9614',
    value: 'Jerada Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9615',
    value: 'Tan-Tan Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9616',
    value: 'Figuig Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9617',
    value: 'Salé Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9618',
    value: 'Moulay Yacoub Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9619',
    value: 'Casablanca Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9620',
    value: 'Sidi Bennour Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9621',
    value: 'Ouarzazate Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9622',
    value: 'Nouaceur Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9623',
    value: 'Chtouka-Aït Baha Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9624',
    value: 'Ifrane Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9625',
    value: 'Sidi Kacem Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9626',
    value: 'Ben Slimane Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9627',
    value: 'Errachidia Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9628',
    value: 'Sidi Slimane Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9629',
    value: 'Midelt Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9630',
    value: 'Tarfaya Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9631',
    value: 'Ouezzane Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9632',
    value: 'Tanger-Asilah Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9633',
    value: 'Boulemane Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9634',
    value: 'Aousserd Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9635',
    value: 'Fès Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9636',
    value: 'Taroudant Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9637',
    value: 'Médiouna Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9638',
    value: 'Es-Semara Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9639',
    value: 'Marrakech Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9640',
    value: 'Inezgane-Aït Melloul Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9641',
    value: 'Oujda-Angad Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9642',
    value: 'Tiznit Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9643',
    value: 'Chichaoua Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9644',
    value: 'Zagora Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9645',
    value: 'Tata Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9646',
    value: 'Rabat Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9647',
    value: 'Laâyoune Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9648',
    value: 'Skhirat-Témara Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9649',
    value: 'Tinghir Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9650',
    value: 'El Hajeb Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9651',
    value: 'Taza Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9652',
    value: 'Khouribga Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9653',
    value: 'Essaouira Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9654',
    value: 'Guercif Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9655',
    value: 'Settat Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9656',
    value: 'Oued Ed-Dahab-Lagouira Province, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9657',
    value: 'Agadir Ida-Outanane Prefecture, Morocco',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9658',
    value: 'Víctor Fajardo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9659',
    value: 'Lucanas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9660',
    value: 'Caylloma Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9661',
    value: 'Huancabamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9662',
    value: 'Cañete Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9663',
    value: 'Huamalíes Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9664',
    value: 'Puerto Inca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9665',
    value: 'Barranca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9666',
    value: 'Leoncio Prado Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9667',
    value: 'Ferreñafe Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9668',
    value: 'Asunción Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9669',
    value: 'Rodríguez de Mendoza Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9670',
    value: 'Huarmey Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9671',
    value: 'Mariscal Nieto Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9672',
    value: 'Utcubamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9673',
    value: 'Antabamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9674',
    value: 'Huanta Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9675',
    value: 'Acomayo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9676',
    value: 'San Román Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9677',
    value: 'Marañón Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9678',
    value: 'Palpa Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9679',
    value: 'Yauli Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9680',
    value: 'Rioja Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9681',
    value: 'Loreto Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9682',
    value: 'Parinacochas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9683',
    value: 'Moho Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9684',
    value: 'Churcampa Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9685',
    value: 'Ambo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9686',
    value: 'Pomabamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9687',
    value: 'Grau Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9688',
    value: 'Yauyos Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9689',
    value: 'Yunguyo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9690',
    value: 'Huancavelica Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9691',
    value: 'Tacna Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9692',
    value: 'Pallasca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9693',
    value: 'Cañas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9694',
    value: 'Pisco Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9695',
    value: 'Gran Chimú Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9696',
    value: 'Chachapoyas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9697',
    value: 'Huaura Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9698',
    value: 'Espinar Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9699',
    value: 'Concepción Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9700',
    value: 'San Martín Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9701',
    value: 'Cusco Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9702',
    value: 'Celendín Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9703',
    value: 'Paita Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9704',
    value: 'Quispicanchi Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9705',
    value: 'Sullana Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9706',
    value: 'Acobamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9707',
    value: 'Tahuamanú Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9708',
    value: 'Sánchez Carrión Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9709',
    value: 'San Miguel Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9710',
    value: 'Ilo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9711',
    value: 'Cajamarca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9712',
    value: 'Chanchamayo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9713',
    value: 'Pachitea Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9714',
    value: 'Purús Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9715',
    value: 'Huari Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9716',
    value: 'Contumazá Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9717',
    value: 'Tambopata Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9718',
    value: 'Casma Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9719',
    value: 'Angaraes Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9720',
    value: 'San Ignacio Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9721',
    value: 'Páucar del Sara Sara Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9722',
    value: 'San Antonio de Putina Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9723',
    value: 'Yarowilca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9724',
    value: 'Sihuas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9725',
    value: 'Azángaro Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9726',
    value: 'Huancayo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9727',
    value: 'Paucartambo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9728',
    value: 'Anta Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9729',
    value: 'Jaén Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9730',
    value: 'Bolívar Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9731',
    value: 'Otuzco Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9732',
    value: 'Huaral Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9733',
    value: 'Tayacaja Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9734',
    value: 'Caravelí Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9735',
    value: 'Huaylas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9736',
    value: 'San Pablo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9737',
    value: 'Candarave Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9738',
    value: 'Chumbivilcas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9739',
    value: 'Lambayeque Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9740',
    value: 'Hualgayoc Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9741',
    value: 'Carlos Fermín Fitzcarrald Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9742',
    value: 'San Marcos Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9743',
    value: 'Puno Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9744',
    value: 'Virú Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9745',
    value: 'Huánuco Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9746',
    value: 'Huanca Sancos Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9747',
    value: 'Oyón Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9748',
    value: 'Abancay Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9749',
    value: 'Tarma Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9750',
    value: 'Arequipa Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9751',
    value: 'Cajatambo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9752',
    value: 'El Collao Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9753',
    value: 'Calca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9754',
    value: 'Maynas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9755',
    value: 'Carhuaz Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9756',
    value: 'Julcán Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9757',
    value: 'Zarumilla Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9758',
    value: 'Talara Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9759',
    value: 'Urubamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9760',
    value: 'El Dorado Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9761',
    value: 'Huarochirí Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9762',
    value: 'Huallaga Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9763',
    value: 'Camaná Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9764',
    value: 'Carabaya Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9765',
    value: 'General Sánchez Cerro Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9766',
    value: 'Pataz Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9767',
    value: 'Satipo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9768',
    value: 'Corongo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9769',
    value: 'Oxapampa Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9770',
    value: 'Castilla Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9771',
    value: 'Santa Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9772',
    value: 'Paruro Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9773',
    value: 'Mariscal Ramón Castilla Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9774',
    value: 'Recuay Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9775',
    value: 'Chiclayo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9776',
    value: 'Huancané Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9777',
    value: 'Chota Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9778',
    value: 'Bagua Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9779',
    value: 'Huamanga Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9780',
    value: 'Chincheros Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9781',
    value: 'Lauricocha Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9782',
    value: 'Chincha Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9783',
    value: 'Piura Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9784',
    value: 'Santa Cruz Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9785',
    value: 'Callao Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9786',
    value: 'Dos de Mayo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9787',
    value: 'Tocache Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9788',
    value: 'Cotabambas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9789',
    value: 'Requena Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9790',
    value: 'Islay Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9791',
    value: 'La Mar Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9792',
    value: 'Ica Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9793',
    value: 'Ayabaca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9794',
    value: 'Chepén Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9795',
    value: 'Sandia Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9796',
    value: 'Bolognesi Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9797',
    value: 'Canchis Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9798',
    value: 'Condorcanqui Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9799',
    value: 'Aymaraes Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9800',
    value: 'Bellavista Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9801',
    value: 'Chucuíto Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9802',
    value: 'La Unión Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9803',
    value: 'Pacasmayo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9804',
    value: 'Antonio Raymondi Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9805',
    value: 'Trujillo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9806',
    value: 'Lampa Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9807',
    value: 'Huacaybamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9808',
    value: 'Datem del Marañón Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9809',
    value: 'Aija Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9810',
    value: 'Tumbes Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9811',
    value: 'Cajabamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9812',
    value: 'Santiago de Chuco Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9813',
    value: 'Ocros Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9814',
    value: 'Tarata Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9815',
    value: 'Lamas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9816',
    value: 'Junín Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9817',
    value: 'Huaraz Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9818',
    value: 'Coronel Portillo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9819',
    value: 'Andahuaylas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9820',
    value: 'La Convención Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9821',
    value: 'Bongará Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9822',
    value: 'Nazca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9823',
    value: 'Canta Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9824',
    value: 'Sechura Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9825',
    value: 'Cangallo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9826',
    value: 'Contralmirante Villar Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9827',
    value: 'Yungay Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9828',
    value: 'Morropón Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9829',
    value: 'Huaytará Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9830',
    value: 'Moyobamba Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9831',
    value: 'Mariscal Luzuriaga Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9832',
    value: 'Padre Abad Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9833',
    value: 'Cutervo Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9834',
    value: 'Vilcas Huamán Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9835',
    value: 'Condesuyos Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9836',
    value: 'Mariscal Cáceres Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9837',
    value: 'Manu Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9838',
    value: 'Castrovirreyna Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9839',
    value: 'Chupaca Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9840',
    value: 'Alto Amazonas Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9841',
    value: 'Lima Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9842',
    value: 'Pasco Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9843',
    value: 'Jauja Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9844',
    value: 'Ascope Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9845',
    value: 'Atalaya Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9846',
    value: 'Luya Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9847',
    value: 'Picota Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9848',
    value: 'Ucayali Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9849',
    value: 'Jorge Basadre Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9850',
    value: 'Sucre Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9851',
    value: 'Daniel Alcides Carrión Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9852',
    value: 'Melgar Province, Peru',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9853',
    value: 'Ariana Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9854',
    value: 'Nabeul Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9855',
    value: 'Tozeur Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9856',
    value: 'Gafsa Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9857',
    value: 'Mannouba Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9858',
    value: 'Kasserine Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9859',
    value: 'Le Kef Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9860',
    value: 'Kairouan Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9861',
    value: 'Ben Arous Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9862',
    value: 'Tunis Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9863',
    value: 'Bizerte Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9864',
    value: 'Monastir Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9865',
    value: 'Médenine Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9866',
    value: 'Sidi Bouzid Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9867',
    value: 'Kebili Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9868',
    value: 'Gabès Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9869',
    value: 'Zaghouan Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9870',
    value: 'Siliana Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9871',
    value: 'Béja Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9872',
    value: 'Tataouine Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9873',
    value: 'Jendouba Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9874',
    value: 'Sfax Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9875',
    value: 'Sousse Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9876',
    value: 'Mahdia Governorate, Tunisia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9877',
    value: 'Guárico Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9878',
    value: 'Bolívar Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9879',
    value: 'Apure Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9880',
    value: 'Táchira Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9881',
    value: 'Barinas Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9882',
    value: 'Sucre Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9883',
    value: 'Miranda Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9884',
    value: 'Zulia Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9885',
    value: 'Falcón Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9886',
    value: 'Carabobo Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9887',
    value: 'Aragua Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9888',
    value: 'Trujillo Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9889',
    value: 'Monagas Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9890',
    value: 'Vargas Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9891',
    value: 'Cojedes Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9892',
    value: 'Anzoátegui Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9893',
    value: 'Caracas Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9894',
    value: 'Yaracuy Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9895',
    value: 'Lara Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9896',
    value: 'Delta Amacuro Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9897',
    value: 'Amazonas Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9898',
    value: 'Nueva Esparta Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9899',
    value: 'Mérida Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9900',
    value: 'Portuguesa Area, Venezuela',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9901',
    value: 'Aswan Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9902',
    value: 'New Valley Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9903',
    value: 'Sohag Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9904',
    value: 'North Sinai Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9905',
    value: 'Matrouh Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9906',
    value: 'Port Said Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9907',
    value: 'Faiyum Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9908',
    value: 'Cairo Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9909',
    value: 'Giza Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9910',
    value: 'Asyut Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9911',
    value: 'Damietta Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9912',
    value: 'Dakahlia Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9913',
    value: 'Gharbia Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9914',
    value: 'Minya Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9915',
    value: 'Beni Suef Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9916',
    value: 'Monufia Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9917',
    value: 'Red Sea Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9918',
    value: 'Ismailia Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9919',
    value: 'Alexandria Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9920',
    value: 'Qalyubia Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9921',
    value: 'Kafr el-Sheikh Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9922',
    value: 'South Sinai Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9923',
    value: 'Suez Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9924',
    value: 'Beheira Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9925',
    value: 'Qena Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9926',
    value: 'Luxor Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9927',
    value: 'Al Sharqia Governorate, Egypt',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9928',
    value: 'Baabda District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9929',
    value: 'Koura District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9930',
    value: 'Hermel District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9931',
    value: 'Zahle District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9932',
    value: 'Nabatiye District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9933',
    value: 'Sour District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9934',
    value: 'Baalbek District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9935',
    value: 'Tripoli District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9936',
    value: 'Beirut District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9937',
    value: 'Hasbaiya District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9938',
    value: 'Akkar District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9939',
    value: 'Zgharta District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9940',
    value: 'Jezzine District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9941',
    value: 'Bint Jubail District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9942',
    value: 'Batroun District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9943',
    value: 'El Metn District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9944',
    value: 'Bcharre District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9945',
    value: 'Jubail District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9946',
    value: 'Rachiaya District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9947',
    value: 'Minieh-Danieh District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9948',
    value: 'Chouf District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9949',
    value: 'West Bekaa District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9950',
    value: 'Aley District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9951',
    value: 'Marjaayoun District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9952',
    value: 'Saida District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9953',
    value: 'Kasrouane District, Lebanon',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9954',
    value: 'Doha, Qatar',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9955',
    value: 'Al Shamal, Qatar',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9956',
    value: 'Al Rayyan, Qatar',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9957',
    value: 'Al Khor, Qatar',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9958',
    value: 'Umm Salal, Qatar',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9959',
    value: 'Al Wakrah, Qatar',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9960',
    value: 'Al Dayyan, Qatar',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9961',
    value: 'Fujairah, United Arab Emirates',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9962',
    value: 'Abu Dhabi, United Arab Emirates',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9963',
    value: 'Dubai, United Arab Emirates',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9964',
    value: 'Sharjah, United Arab Emirates',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9965',
    value: 'Ras al-Khaimah, United Arab Emirates',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9966',
    value: 'Ajman, United Arab Emirates',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9967',
    value: 'Umm al-Quwain, United Arab Emirates',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9968',
    value: 'Tafielah Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9969',
    value: 'Irbid Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9970',
    value: 'Karak Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9971',
    value: 'Amman Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9972',
    value: 'Zarqa Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9973',
    value: 'Ma’an Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9974',
    value: 'Balqa Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9975',
    value: 'Madaba Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9977',
    value: 'Jerash Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9978',
    value: 'Aqaba Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9979',
    value: 'Mafraq Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9980',
    value: 'Ajlun Governorate, Jordan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9981',
    value: 'Muscat Governorate, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9982',
    value: 'Dhofar Governorate, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9983',
    value: 'Ash Sharqiyah Region, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9984',
    value: 'Al Buraimi Governorate, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9985',
    value: 'Al Wusta Region, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9986',
    value: 'Al Batinah Region, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9987',
    value: 'Ad Dakhiliyah Region, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9988',
    value: 'Ad Dhahirah Governorate, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9989',
    value: 'Musandam Governorate, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9990',
    value: 'Ad Dhahirah Region, Oman',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9991',
    value: 'Al-Mahd Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9992',
    value: 'Abha Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9993',
    value: 'Al-Mukhwa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9994',
    value: 'Al-Asyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9995',
    value: 'Oniezah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9996',
    value: 'Al-Kharkir Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9997',
    value: 'Al-Zulfa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9998',
    value: 'Dhiram Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:9999',
    value: 'Al-’Ola Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10000',
    value: 'Al-Madinah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10001',
    value: 'Al-Lieth Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10002',
    value: 'Wadi Al-Dawsir Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10003',
    value: 'Al-Hrath Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10004',
    value: 'Al-Jubial Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10005',
    value: 'Baq’aa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10006',
    value: 'Abu ’Arieesh Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10007',
    value: 'Dhiba Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10008',
    value: 'Oyoon Al-Jewaa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10009',
    value: 'Tathieth Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10010',
    value: 'Rijal Alma’a Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10011',
    value: 'Rabigh Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10012',
    value: 'Ahad Rufiedah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10013',
    value: 'Al-Nu’aeriyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10014',
    value: 'Tayma Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10015',
    value: 'Al-Rass Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10016',
    value: 'Al-Salil Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10017',
    value: 'Khamis Mishiet Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10018',
    value: 'Badr Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10019',
    value: 'Samtah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10020',
    value: 'Sakaka Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10021',
    value: 'Yanb’u Al-Bahr Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10022',
    value: 'Al-Mandaq Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10023',
    value: 'Al-Kamel Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10024',
    value: 'Al-’Aqiq Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10025',
    value: 'Makkah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10026',
    value: 'Sharorah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10027',
    value: 'Al-Bukieriyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10028',
    value: 'Dhamd Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10029',
    value: 'Raniyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10030',
    value: 'Baljurashi Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10031',
    value: 'Habouna Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10032',
    value: 'Al-Qariyat Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10033',
    value: 'Al-Shamasiyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10034',
    value: 'Ha’il Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10035',
    value: 'Ahd Al-Masarha Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10036',
    value: 'Al-Dammam Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10037',
    value: 'Al-Qwie’iyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10038',
    value: 'Al-Nabhaniyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10039',
    value: 'Al-Qunfudah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10040',
    value: 'Fursan Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10041',
    value: 'Ghazalah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10042',
    value: 'Al-Qateif Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10043',
    value: 'Al-Jamoum Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10044',
    value: 'Al-Kharj Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10045',
    value: 'Al-Aflaj Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10046',
    value: 'Al-Harieq Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10047',
    value: 'Al-Baha Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10048',
    value: 'Khaibar Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10049',
    value: 'Arar Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10050',
    value: 'Al-Khafji Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10051',
    value: 'Riaydh Al-Khubraa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10052',
    value: 'Al-Maznab Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10053',
    value: 'Buriedah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10054',
    value: 'Al-Nams Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10055',
    value: 'Balqarn Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10056',
    value: 'Al Wajh Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10057',
    value: 'Hafr Al-Batin Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10058',
    value: 'Al-Dawdmi Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10059',
    value: 'Thadiq Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10060',
    value: 'Amlaj Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10061',
    value: 'Jeddah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10062',
    value: 'Al-Badai’a Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10063',
    value: 'Yiadmah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10064',
    value: 'Hurimalilaa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10065',
    value: 'Haql Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10066',
    value: 'Al-Riyadh Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10067',
    value: 'Al-’Idabi Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10068',
    value: 'Al-Ahsa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10069',
    value: 'Sabiyaa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10070',
    value: 'Khulies Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10071',
    value: 'Biesh Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10072',
    value: 'Dhahran Al-Janoub Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10073',
    value: 'Dawmat Al-Jandal Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10074',
    value: 'Al-Dair Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10075',
    value: 'Rafha’ Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10076',
    value: 'Al-Majardah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10077',
    value: 'Qariyat Al-’Oliyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10078',
    value: 'Qulwah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10079',
    value: 'Al-Qura Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10080',
    value: 'Najran Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10081',
    value: 'Biesha Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10082',
    value: 'Turbah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10083',
    value: 'Raith Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10084',
    value: 'Al-Ghat Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10085',
    value: 'Al-Hanakiayh Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10086',
    value: 'Al-Darb Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10087',
    value: 'Tarif Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10088',
    value: 'Al-Shanan Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10089',
    value: 'Al-Khobar Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10090',
    value: 'Ramamh Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10091',
    value: 'Badr Al-Janoub Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10092',
    value: 'Al-’Ardhah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10093',
    value: 'Al-Taief Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10094',
    value: 'Al-Muzahmiyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10095',
    value: 'Al-Khurmah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10096',
    value: 'Mahaiel Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10097',
    value: 'Abgig Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10098',
    value: 'Shaqraa Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10099',
    value: 'Ras Tannoura Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10100',
    value: 'Hotat Bani Tamim Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10101',
    value: 'Al-Dir’iyah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10102',
    value: 'Afif Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10103',
    value: 'Tabuk Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10104',
    value: 'Khabash Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10105',
    value: 'Thar Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10106',
    value: 'Al-Majma’ah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10107',
    value: 'Jizan Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10108',
    value: 'Surat ’Obiedah Governorate, Saudi Arabia',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10135',
    value: 'Central Singapore',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10136',
    value: 'North East, Singapore',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10137',
    value: 'North West, Singapore',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10138',
    value: 'South East, Singapore',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10139',
    value: 'South West, Singapore',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10140',
    value: 'County Carlow, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10141',
    value: 'County Westmeath, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10142',
    value: 'County Laois, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10143',
    value: 'County Limerick, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10144',
    value: 'County Louth, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10145',
    value: 'County Kerry, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10146',
    value: 'County Clare, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10147',
    value: 'County Wicklow, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10148',
    value: 'County Meath, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10149',
    value: 'County Tipperary, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10150',
    value: 'County Cork, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10151',
    value: 'County Waterford, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10152',
    value: 'County Sligo, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10153',
    value: 'County Kilkenny, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10154',
    value: 'County Galway, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10155',
    value: 'County Kildare, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10156',
    value: 'County Longford, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10157',
    value: 'County Offaly, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10158',
    value: 'County Roscommon, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10159',
    value: 'County Leitrim, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10160',
    value: 'County Cavan, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10161',
    value: 'County Dublin, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10162',
    value: 'County Mayo, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10163',
    value: 'County Wexford, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10164',
    value: 'County Monaghan, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10165',
    value: 'County Donegal, Ireland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10166',
    value: 'Limburg Area, Belgium',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10167',
    value: 'Federal Capial &AJK, Pakistan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10168',
    value: 'Southern Punjab Multan, Pakistan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10169',
    value: 'Balochisan, Pakistan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10170',
    value: 'Lahore, Pakistan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10171',
    value: 'Hyderabad, Pakistan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10172',
    value: 'Northern Punjab Rawalpindi, Pakistan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10173',
    value: 'NWFP Peshawar, Pakistan',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10174',
    value: 'Vinnytsia Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10175',
    value: 'Volyn Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10176',
    value: 'Luhansk Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10177',
    value: 'Dnipropetrovsk Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10178',
    value: 'Zhytomyr Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10179',
    value: 'Donetsk Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10180',
    value: 'Zakarpattia Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10181',
    value: 'Zaporizhia Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10182',
    value: 'Ivano-Frankivsk Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10183',
    value: 'Kiev Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10184',
    value: 'Kirovohrad Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10186',
    value: 'Lviv Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10187',
    value: 'Mykolaiv Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10188',
    value: 'Odessa Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10189',
    value: 'Poltava Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10190',
    value: 'Rivne Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10191',
    value: 'Sumy Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10192',
    value: 'Ternopil Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10193',
    value: 'Kharkiv Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10194',
    value: 'Kherson Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10195',
    value: 'Khmelnytskyi Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10196',
    value: 'Cherkasy Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10197',
    value: 'Chernihiv Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10198',
    value: 'Chernivtsi Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10199',
    value: 'Crimea Region, Ukraine',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10200',
    value: 'Neuchâtel Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:region:10201',
    value: 'Jura Area, Switzerland',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:1',
    value: 'Unpaid',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:2',
    value: 'Training',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:3',
    value: 'Entry',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:4',
    value: 'Senior',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:5',
    value: 'Manager',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:6',
    value: 'Director',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:7',
    value: 'VP',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:8',
    value: 'CXO',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:9',
    value: 'Partner',
    __typename: 'AudienceWrapper',
  },
  {
    name: 'urn:li:seniority:10',
    value: 'Owner',
    __typename: 'AudienceWrapper',
  },
];
