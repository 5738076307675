import { Line, mixins } from 'vue-chartjs';

export default {
  name: 'ChartLine',

  props: {
    legend: {
      default: false,
      type: Boolean,
    },
    displayX: {
      default: false,
      type: Boolean,
    },
    displayY: {
      default: false,
      type: Boolean,
    },
    gridLinesX: {
      default: () => ({
        display: false,
        drawBorder: false,
      }),
    },
    tooltips: {
      default: false,
      type: Boolean,
    },

    callbackLabel: {
      default: () => {},
      type: Function,
    },
  },

  extends: Line,

  mixins: [mixins.reactiveProp],

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.legend,
        },
        tooltips: {
          enabled: this.tooltips,
          displayColors: false,
          backgroundColor: 'rgba(88, 89, 91, 0.6)',
          callbacks: {
            label: this.callbackLabel,
          },
        },
        layout: {
          padding: {
            top: 8,
          },
        },
        scales: {
          xAxes: [
            {
              display: this.displayX,
              gridLines: this.gridLinesX,
              ticks: {
                fontColor: '#BBBBBB',
                fontStyle: '600',
                fontSize: 11,
              },
            },
          ],
          yAxes: [
            {
              display: this.displayY,
              ticks: {
                fontColor: '#BBBBBB',
                fontStyle: '600',
                fontSize: 11,
              },
            },
          ],
        },
      };
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
