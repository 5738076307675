/**
 * ------------------------------------------
 *   Common Functions to calculate metrics.
 * ------------------------------------------
 */

/**
 * Get total Reactions (Available for Facebook and Linkedin)
 *
 * @param {*} reactions
 * @returns
 */
export const getTotalReactions = reactions => {
  if (!reactions) {
    return 0;
  }

  return Object.values(reactions)
    .filter(Number)
    .reduce((carry, value) => carry + value, 0);
};

/**
 * Get Total Engagements from metrics
 * @param {*} metric
 * @returns
 */
export const getTotalEngagement = metric => {
  const clicks = metric?.clicks || 0;
  const comments = metric?.comments || 0;
  const reactions = metric?.reactions ?? [{ likes: metric?.likes }];
  const shares = metric?.shares || 0;

  return clicks + comments + shares + getTotalReactions(reactions);
};

/**
 * Get engagement rate
 *
 * @param {*} metric
 * @returns
 */
export const getEngagementRate = metric => {
  const engagement = metric?.engagedUsers || getTotalEngagement(metric);
  const impressions = metric?.impressions || 0;

  if (impressions > 0) {
    return (engagement / impressions) * 100;
  }

  return 0;
};

/**
 * Get Percent validated
 * @param {*} total
 * @param {*} vs
 */
export const getPercents = (total, vs) => {
  if (total > 0) {
    const percent = (vs * 100) / total;

    return parseFloat(percent).toFixed(2);
  }

  return 0;
};
