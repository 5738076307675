// @flow

import { format, isToday, isThisWeek } from 'date-fns';
import { capitalize, upperCase, startCase, lowerCase } from 'lodash';
import Vue from 'vue';

import dateConsts from './utils/date-consts';

const filters = {};

/**
 * Format dates with the given format.
 */
filters.formatDate = format;

/**
 * Transform text to capitalize format.
 */
filters.capitalize = capitalize;

/**
 * Transform text to capitalize format for all words
 */
filters.capitalizeAll = value => startCase(lowerCase(value));

/**
 * Transform text to uppercase format
 */
filters.uppercase = upperCase;

/**
 *
 * Remove a String
 */
filters.removeString = (value, toRemove) => {
  return value.replace(toRemove, '');
};

/**
 * Sort Object by date (used in sorts for posts)
 */
filters.sortByDate = (
  value: Object[],
  attribute: string,
  direction?: string = 'desc',
  period?: ?string = null
) => {
  let data = value;

  if (['this_week', 'today'].includes(period)) {
    data = value.filter(item => {
      if (period === 'this_week') {
        return isThisWeek(item[attribute], {
          weekStartsOn: dateConsts.DAYS.MONDAY,
        });
      }

      return isToday(item[attribute]);
    });
  }

  return data.sort((a, b) => {
    if (direction.toLowerCase() === 'desc') {
      return new Date(b[attribute]) - new Date(a[attribute]);
    }

    return new Date(a[attribute]) - new Date(b[attribute]);
  });
};

/**
 * Format to Numbers (add separetor (,) to miles, and fixed fractions to X digits)
 */
const formaters = {};

filters.formatNumber = (number, digits = 2) => {
  if (isNaN(number)) {
    return 0;
  }

  if (!formaters[digits]) {
    formaters[digits] = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: digits,
    });
  }

  return formaters[digits].format(number);
};

for (let filter in filters) {
  Vue.filter(filter, filters[filter]);
}

Vue.use({
  install(Vue) {
    Vue.prototype.$filters = filters;
  },
});
