export default {
  name: 'LeftRight',

  props: {
    direction: {
      default: 'left',
      type: String,
      validator: value => ['both', 'left', 'right'].indexOf(value) > -1,
    },
  },
};
