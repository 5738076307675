export const faStories = {
  prefix: 'fac', // font-awesomeicon-custom
  iconName: 'stories',
  icon: [
    1080,
    1080,
    [],
    null,
    `M 230.11,166.59
  C 230.11,182.58 217.15,195.54 201.16,195.54
    185.17,195.54 172.21,182.58 172.21,166.59
    172.21,150.60 185.17,137.64 201.16,137.64
    217.15,137.64 230.11,166.59 230.11,166.59 Z
  M 757.18,569.48
  C 757.18,569.48 323.00,569.48 323.00,569.48
    307.61,568.78 295.35,556.08 295.35,540.51
    295.35,525.12 307.33,512.53 322.48,511.57
    322.48,511.57 757.18,511.57 757.18,511.57
    772.44,512.41 784.57,525.05 784.57,540.53
    784.57,555.99 772.45,568.64 757.19,569.48
    757.19,569.48 757.18,569.48 757.18,569.48 Z
  M 540.08,786.58
  C 540.04,786.58 540.01,786.58 539.97,786.58
    524.03,786.58 511.11,773.68 511.08,757.75
    511.08,757.75 511.08,323.49 511.08,323.49
    511.03,322.81 511.00,322.11 511.00,321.39
    511.00,305.38 523.99,292.39 540.00,292.39
    555.85,292.39 568.73,305.11 569.00,320.90
    569.00,321.76 569.00,322.62 569.00,323.49
    569.00,323.49 569.00,757.68 569.00,757.68
    569.00,757.68 569.00,757.69 569.00,757.69
    569.00,773.65 556.07,786.58 540.11,786.58
    540.11,786.58 540.11,786.58 540.11,786.58
    540.11,786.58 540.08,786.58 540.08,786.58 Z
  M 37.11,509.11
  C 21.18,509.00 8.31,496.06 8.31,480.11
    8.31,478.98 8.37,477.87 8.50,476.78
    14.67,423.61 28.73,372.84 49.44,325.65
    53.91,315.41 64.13,308.26 76.02,308.26
    92.03,308.26 105.02,321.25 105.02,337.26
    105.02,341.39 104.15,345.32 102.60,348.88
    84.11,390.61 71.56,435.83 66.00,483.19
    64.47,497.76 52.14,509.11 37.16,509.11
    37.15,509.11 37.13,509.11 37.11,509.11
    37.11,509.11 37.11,509.11 37.11,509.11 Z
  M 187.66,933.45
  C 179.82,933.45 172.72,930.33 167.51,925.26
    97.34,857.40 45.70,770.52 20.91,672.92
    20.29,670.58 19.95,668.10 19.95,665.54
    19.95,649.52 32.94,636.54 48.96,636.54
    62.41,636.54 73.72,645.70 77.00,658.12
    98.96,745.35 145.09,823.01 207.81,883.61
    213.22,888.87 216.58,896.24 216.58,904.39
    216.58,920.38 203.64,933.35 187.65,933.39
    187.65,933.39 187.66,933.45 187.66,933.45
    187.66,933.45 187.66,933.45 187.66,933.45 Z
  M 540.00,1076.00
  C 451.48,1075.99 367.98,1054.56 294.39,1016.61
    285.05,1011.79 278.66,1002.04 278.66,990.81
    278.66,974.77 291.66,961.77 307.69,961.77
    312.49,961.77 317.01,962.93 321.00,964.99
    385.89,998.86 459.88,1018.00 538.35,1018.00
    538.90,1018.00 539.45,1018.00 540.01,1018.00
    540.15,1018.00 540.30,1018.00 540.45,1018.00
    576.29,1018.00 611.21,1014.09 644.82,1006.67
    647.61,1005.76 650.60,1005.27 653.71,1005.27
    669.70,1005.27 682.66,1018.23 682.66,1034.22
    682.66,1048.30 672.61,1060.03 659.30,1062.63
    658.67,1062.79 658.07,1062.93 657.47,1063.04
    620.03,1071.52 580.54,1076.00 540.00,1076.00
    540.00,1076.00 540.00,1076.00 540.00,1076.00 Z
  M 797.70,1005.67
  C 781.77,1005.57 768.89,992.62 768.89,976.67
    768.89,966.13 774.52,956.91 782.92,951.83
    1010.06,817.62 1085.37,524.68 951.15,297.57
    868.11,157.15 715.10,62.97 540.10,62.91
    524.83,62.08 512.71,49.43 512.71,33.95
    512.71,18.49 524.82,5.85 540.07,5.00
    835.89,5.07 1075.62,244.92 1075.55,540.72
    1075.53,736.91 970.00,908.46 812.62,1001.73
    808.33,1004.23 803.34,1005.67 798.01,1005.67
    797.91,1005.67 797.80,1005.67 797.70,1005.67
    797.70,1005.67 797.70,1005.67 797.70,1005.67 Z
  M 630.00,25.50
  C 630.00,25.50 787.50,67.50 787.50,67.50
    787.50,67.50 624.00,55.50 624.00,55.50
    624.00,55.50 540.00,6.00 540.00,6.00
    540.00,6.00 630.00,25.50 630.00,25.50 Z`,
  ],
};
