import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

export default {
  name: 'ReactionsCharts',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    reactions: {
      required: true,
    },
  },

  computed: {
    computedReactions() {
      if (!this.reactions) {
        return [];
      }

      const filters = Object.entries(this.reactions).filter(
        toFilter => !toFilter[0].includes('typename')
      );

      const total = filters.reduce((ac, reaction) => (ac += reaction[1]), 0);

      return filters
        .sort((a, b) => {
          return b[1] - a[1];
        })
        .map(reaction => {
          const progress = (reaction[1] * 100) / total;

          return {
            type: reaction[0],
            value: this.$filters.formatNumber(reaction[1]) || 0,
            progress: progress.toFixed(1),
          };
        });
    },
  },
};
