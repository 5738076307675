const generateIcon = (color, icon) => {
  return {
    classColor: color,
    icon: ['fal', icon],
  };
};

const IconsFans = {
  city: generateIcon('pink', 'city'),
  country: generateIcon('pink', 'globe-americas'),
  functions: generateIcon('pink', 'address-card'),
  gender: generateIcon('pink', 'venus-mars'),
  industry: generateIcon('pink', 'industry'),
  language: generateIcon('pink', 'language'),
  location: generateIcon('pink', 'location'),
  siteMap: generateIcon('pink', 'sitemap'),
};

const IconsTimes = {
  clock: generateIcon('sharelov', 'clock'),
  plane: generateIcon('sharelov', 'paper-plane'),
};

const LinkedinReaction = {
  celebrate: generateIcon('blue', 'sign-language'),
  support: generateIcon('blue', 'hand-holding-heart'),
  insightful: generateIcon('blue', 'lightbulb-on'),
  curious: generateIcon('blue', 'surprise'),
};

const IconsReactions = {
  anger: generateIcon('blue', 'angry'),
  haha: generateIcon('blue', 'laugh'),
  like: generateIcon('blue', 'thumbs-up'),
  likeGreen: generateIcon('green', 'thumbs-up'),
  likes: generateIcon('blue', 'thumbs-up'),
  likesFromComments: generateIcon('blue', 'thumbs-up'),
  love: generateIcon('blue', 'heart'),
  lovePurple: generateIcon('purple', 'heart'),
  organicLikes: generateIcon('blue', 'heart'),
  others: generateIcon('blue', 'star-of-life'),
  sad: generateIcon('blue', 'sad-tear'),
  sorry: generateIcon('blue', 'sad-tear'),
  wow: generateIcon('blue', 'surprise'),
  ...LinkedinReaction,
};

const IconStories = {
  averageUsers: generateIcon('yellow', 'user-friends'),
  backward: generateIcon('blue', 'backward'),
  completionRate: generateIcon('red', 'hourglass-end'),
  exits: generateIcon('blue', 'sign-out-alt'),
  forward: generateIcon('blue', 'forward'),
  replies: generateIcon('blue', 'reply'),
};

const ICONS = {
  ...IconsFans,
  ...IconsTimes,
  ...IconsReactions,
  ...IconStories,
  clicks: generateIcon('purple', 'mouse-pointer'),
  comments: generateIcon('blue', 'comments'),
  engagementRate: generateIcon('red', 'chart-line'),
  impressions: generateIcon('yellow', 'eye'),
  organicComments: generateIcon('blue', 'comments'),
  organicEngagementRate: generateIcon('red', 'chart-line'),
  organicReach: generateIcon('green', 'leaf'),
  organicSaves: generateIcon('blue', 'bookmark'),
  organicViews: generateIcon('orange', 'leaf'),
  paidReach: generateIcon('green', 'usd-circle'),
  paidViews: generateIcon('orange', 'usd-circle'),
  peopleReached: generateIcon('green', 'broadcast-tower'),
  posts: generateIcon('blue', 'sticky-note'),
  saves: generateIcon('blue', 'bookmark'),
  shares: generateIcon('blue', 'share'),
  totalEngagements: generateIcon('red', 'heartbeat'),
  engagement: generateIcon('red', 'heartbeat'),
  engagements: generateIcon('red', 'heartbeat'),
  totalFans: generateIcon('pink', 'user-friends'),
  totalFollowers: generateIcon('pink', 'user-friends'),
  totalOrganicEngagements: generateIcon('red', 'heartbeat'),
  totalReactions: generateIcon('blue', 'smile'),
  videoViews: generateIcon('orange', 'video'),
};

export default {
  name: 'AnalyticsCardIcon',

  props: {
    background: {
      default: true,
    },
    icon: {
      required: true,
      type: String,
    },
    mini: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    name() {
      return this.toCamel(this.icon);
    },

    selectIcon() {
      return ICONS[this.name].icon;
    },

    classColor() {
      return ICONS[this.name].classColor;
    },
  },

  methods: {
    toCamel(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) {
          // or if (/\s+/.test(match)) for white spaces
          return '';
        }

        return index == 0 ? match.toLowerCase() : match.toUpperCase();
      });
    },
  },
};
