import { saveAsPng } from 'save-html-as-image';
import AlertMessages from '@/mixins/AlertMessages';

export default {
  name: 'ComponentWithFloatingIconDownload',

  mixins: [AlertMessages],

  props: {
    componentName: {
      required: true,
    },
    componentProps: {
      required: true,
    },
    downloadName: {
      default: 'download',
    },
    innerButtonClass: {
      default: null,
    },
    innerComponentClass: {
      default: null,
    },
    hardFixText: {
      default: false,
    },
  },

  computed: {
    componentLoader() {
      if (this.componentName) {
        return () => import(`@/components/${this.componentName}/index.vue`);
      }

      return null;
    },
  },

  methods: {
    async download() {
      this.alertMessage();

      await saveAsPng(
        this.$refs.refComponent,
        {
          filename: this.downloadName,
          forceFixText: this.hardFixText,
        },
        {
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none',
          },
        }
      );
    },
  },
};
