import { COLORS } from './colors';
import { format, eachDay, min, max, parse } from 'date-fns';

const getChartsTotal = (labels, data, color) => {
  return {
    labels: [...labels],
    datasets: [
      {
        ...COLORS[color],
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 0,
        data,
      },
    ],
  };
};

export const chartTotalEngagements = metrics => {
  return getChartsTotal(
    metrics.filter(({ metrics }) => metrics).map(print => print.date),
    metrics
      .filter(({ metrics }) => metrics)
      .map(print => {
        const { comments, reactions } = print.metrics;
        const shares = print.metrics.shares || 0;

        let totalReactions = 0;

        if (reactions) {
          totalReactions = Object.values(reactions).reduce((ac, val) => {
            if (!isNaN(val)) {
              return (ac += val);
            }

            return ac;
          }, 0);
        }

        return comments + shares + totalReactions;
      }),
    'red'
  );
};

/**
 * @deprecated
 * @param {*} fans
 */
export const chartTotalFans = fans => {
  let totalFans = (fans || []).map(fan => {
    const endTime = fan?.endTime ? new Date(fan.endTime) : new Date();

    return {
      day: format(parse(endTime), 'DD-MM-YYYY'),
      value: fan?.value || 0,
    };
  });

  return getChartsTotal(
    totalFans.map(({ day }) => day),
    totalFans.map(({ value }) => value),
    'pink'
  );
};

//Convert {AccountPageStats} into a chart
export const chartTotalPageInfo = (item, color = 'pink') => {
  let totals = (item || []).map(obj => {
    const endTime = obj?.endTime ? new Date(obj.endTime) : new Date();

    return {
      day: format(parse(endTime), 'DD-MM-YYYY'),
      value: obj?.value || 0,
    };
  });

  return getChartsTotal(
    totals.map(({ day }) => day),
    totals.map(({ value }) => value),
    color
  );
};

/**
 *
 * @param {*} metrics
 * @returns
 */
export const chartTotalReactions = metrics => {
  if (!metrics || (metrics && !metrics.some(({ metrics }) => metrics))) {
    return {};
  }

  const totalReactions = metrics.map(print => {
    const reactions = print.metrics?.reactions || [];

    return Object.values(reactions)
      .filter(Number)
      .reduce((ac, value) => {
        return (ac += value);
      }, 0);
  });

  return getChartsTotal(
    metrics.map(print => print.date),
    totalReactions,
    'blue'
  );
};

export const getGenericCharts = (metrics, field, color) => {
  return getChartsTotal(
    metrics.filter(({ metrics }) => metrics).map(print => print.date),
    metrics.filter(({ metrics }) => metrics).map(print => print.metrics[field]),
    color
  );
};

export const getTotalPosts = (posts, color) => {
  const daysInPosts = posts.map(post => new Date(post.remotePublishAt));

  const minDay = min(...daysInPosts);
  const maxDay = max(...daysInPosts);

  const allDays = eachDay(parse(minDay), parse(maxDay));

  const days = allDays.map(day => {
    let count = 0;

    daysInPosts.forEach(dayInPost => {
      const _dayInPost = format(dayInPost, 'DD-MM-YYYY');
      const _dayInRange = format(parse(day), 'DD-MM-YYYY');

      if (_dayInPost === _dayInRange) {
        count++;
      }
    });

    return {
      day,
      count,
    };
  });

  return getChartsTotal(
    days.map(({ day }) => day),
    days.map(({ count }) => count),
    color
  );
};

/**
 *
 * @param {*} metrics
 * @returns
 */
export const totalEngagements = metrics => {
  if (!metrics || (metrics && !metrics.some(({ metrics }) => metrics))) {
    return 0;
  }

  const CS = metrics.reduce((ac, value) => {
    const { comments, shares, clicks } = value?.metrics ?? {};

    ac += (comments || 0) + (shares || 0) + (clicks || 0);

    return ac;
  }, 0);

  return CS + totalReactions(metrics);
};

export const totalMetrics = metrics => {
  if (!metrics || (metrics && !metrics.some(({ metrics }) => metrics))) {
    return {};
  }

  let initial = {
    clicks: 0,
    comments: 0,
    engagement: 0,
    impressions: 0,
    likes: 0,
    reach: 0,
    saved: 0,
    shares: 0,
    videoViews: 0,
    views: 0,
    estimatedMinutesWatched: 0,
    averageViewDuration: 0,
    averageViewPercentage: 0,
    subscribersGained: 0,
    videosAddedToPlaylists: 0,
    annotationImpressions: 0,
    annotationClickableImpressions: 0,
    cardImpressions: 0,
    cardClicks: 0,
  };
  let keys = Object.keys(initial);

  return metrics
    .filter(({ metrics }) => metrics)
    .reduce((ac, print) => {
      keys.forEach(key => (ac[key] += print.metrics[key]));

      return ac;
    }, initial);
};

export const totalReactions = metrics => {
  return metrics
    .filter(({ metrics }) => metrics)
    .map(({ metrics }) => metrics.reactions)
    .reduce((ac, reactions) => {
      if (reactions) {
        ac += Object.values(reactions)
          .filter(Number)
          .reduce((ac, reaction) => (ac += reaction), 0);
      }

      return ac;
    }, 0);
};
