export default {
  methods: {
    alertMessage(
      message = 'Your download should begin in a few seconds...',
      type = 'success',
      timeAlive = 5
    ) {
      this.$store.dispatch(
        'indicators/addIndicator',
        {
          message,
          timeAlive,
          type,
        },
        {
          root: true,
        }
      );
    },
  },
};
