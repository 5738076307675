export default {
  name: 'circular',

  props: {
    background: {
      default: 'white',
      type: String,
    },
    icon: {
      default: undefined,
      type: Array,
    },
    iconColor: {
      default: 'white',
      type: String,
    },
    progress: {
      required: true,
      validator: value => {
        return (
          !isNaN(value) && parseFloat(value) >= 0 && parseFloat(value) <= 100
        );
      },
    },
    progressColor: {
      default: '#53777a',
      Type: String,
    },
    separate: {
      default: false,
      type: Boolean,
    },
    size: {
      default: '24px',
      type: String,
    },
  },

  computed: {
    afterStyle() {
      let inlineStyle = `background: ${this.background};`;

      if (this.separate) {
        return inlineStyle + `border: 4px solid white`;
      }

      return inlineStyle;
    },
    applyColor() {
      const { progressColor } = this;

      return `border: 0.45em solid ${progressColor}; background-color: ${progressColor};`;
    },
    over50() {
      return this.progress > 50;
    },
    rotated() {
      const deg = (this.progress * 360) / 100;

      return `transform: rotate(${deg}deg);`;
    },
  },

  methods: {
    rotate(deg) {
      if (this.$refs.valueBar.animate) {
        const initial = this.over50 ? 180 : 0;

        this.$refs.valueBar.animate(
          [
            { transform: `rotate(${initial}deg)` },
            { transform: `rotate(${deg}deg)` },
          ],
          {
            duration: 1000,
          }
        );
      }
    },
  },

  watch: {
    progress(newValue) {
      if (newValue > 0) {
        this.$nextTick(() => {
          const deg = (newValue * 360) / 100;
          this.rotate(deg);
        });
      }
    },
  },
};
