import { format } from 'date-fns';

import {
  chartTotalEngagements,
  chartTotalPageInfo,
  chartTotalReactions,
  getGenericCharts,
  getTotalPosts,
  totalEngagements,
  totalMetrics,
  totalReactions,
} from './utils.js';

import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

export default {
  name: 'BrandAnalyticsSummaryTotalsCharts',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    accountType: {
      default: 'FACEBOOK',
    },
    pageInfo: {
      defualt: {},
    },
    posts: {
      required: true,
    },
  },

  computed: {
    fans() {
      return [...(this.pageInfo?.fans ?? [])].sort(
        (a, b) => new Date(a.endTime) - new Date(b.endTime)
      );
    },

    likes() {
      return [...(this.pageInfo?.likes ?? [])].sort(
        (a, b) => new Date(a.endTime) - new Date(b.endTime)
      );
    },

    metrics() {
      return this.posts.map(post => {
        return {
          date: format(new Date(post?.remotePublishAt), 'DD-MM-YYYY'),
          metrics: post?.metrics,
        };
      });
    },

    totalCharts() {
      const { fans, metrics, accountType } = this;

      if (accountType === 'INSTAGRAM') {
        return this.generateInstagramData(fans, metrics);
      }

      if (accountType === 'TIKTOK') {
        return this.generateTikTokData(fans, metrics);
      }

      if (accountType === 'LINKEDIN') {
        return this.generateLinkedinData(fans, metrics);
      }

      return this.generateFacebookData(fans, metrics);
    },
  },

  methods: {
    getProgress(chartData) {
      const { data } = chartData.datasets[0];
      const initial = data[0] || 0;
      const final = data[data.length - 1] || 0;
      const relation = initial === 0 ? 1 : initial;

      return parseFloat(((final - initial) / relation) * 100).toFixed(1);
    },

    getLastValue(data) {
      const sorted = [...(data || [])].sort(
        (a, b) => new Date(b.endTime) - new Date(a.endTime)
      );

      return sorted[0]?.value ?? 0;
    },

    getEngagementRate(posts) {
      const metrics = posts.map(({ metrics }) => metrics).filter(Boolean);

      const engagedUsers = metrics.reduce((ac, metric) => {
        ac += metric?.engagedUsers || 0;

        return ac;
      }, 0);

      const impressions = metrics.reduce((ac, metric) => {
        ac += metric?.impressions || 0;

        return ac;
      }, 0);

      if (impressions > 0) {
        const engagement = (engagedUsers / impressions) * 100;

        return parseFloat(engagement).toFixed(1);
      }

      return 0;
    },

    generateFacebookData(fans, metrics) {
      const {
        clicks,
        comments,
        impressions,
        shares,
        videoViews,
      } = totalMetrics(metrics);

      return [
        {
          chartValue: chartTotalPageInfo(fans),
          icon: 'totalFans',
          progress: this.getProgress(chartTotalPageInfo(fans)),
          text: 'Total Fans',
          value: this.getLastValue(fans),
        },
        {
          chartValue: getGenericCharts(metrics, 'impressions', 'green'),
          icon: 'peopleReached',
          text: 'Post Reach',
          value: impressions,
        },
        {
          chartValue: chartTotalEngagements(metrics),
          icon: 'totalEngagements',
          rate: this.getEngagementRate(metrics),
          text: 'Post Engagements',
          value: totalEngagements(metrics),
        },
        {
          chartValue: getGenericCharts(metrics, 'clicks', 'purple'),
          icon: 'clicks',
          text: 'Post Clicks',
          value: clicks,
        },
        {
          chartValue: chartTotalReactions(metrics),
          icon: 'totalReactions',
          text: 'Post Reactions',
          value: totalReactions(metrics),
        },
        {
          chartValue: getGenericCharts(metrics, 'comments', 'blue'),
          icon: 'comments',
          text: 'Post Comments',
          value: comments,
        },
        {
          chartValue: getGenericCharts(metrics, 'shares', 'blue'),
          icon: 'shares',
          text: 'Post Shares',
          value: shares,
        },
        {
          chartValue: getGenericCharts(metrics, 'videoViews', 'orange'),
          icon: 'videoViews',
          text: 'Post Video View',
          value: videoViews,
        },
      ];
    },

    generateInstagramData(fans, metrics) {
      const {
        comments,
        engagement,
        impressions,
        likes,
        reach,
        saved,
      } = totalMetrics(metrics);

      return [
        {
          chartValue: chartTotalPageInfo(fans),
          icon: 'totalFans',
          progress: this.getProgress(chartTotalPageInfo(fans)),
          text: 'Total Followers',
          value: this.getLastValue(fans),
        },
        {
          chartValue: getGenericCharts(metrics, 'reach', 'green'),
          icon: 'peopleReached',
          text: 'People Reached',
          value: reach,
        },
        {
          chartValue: getGenericCharts(metrics, 'engagement', 'red'),
          icon: 'Total Engagements',
          text: 'Total Organic Engagements',
          value: engagement,
        },
        {
          chartValue: getGenericCharts(metrics, 'impressions', 'orange'),
          icon: 'Impressions',
          text: 'Total Impressions',
          value: impressions,
        },
        {
          chartValue: getTotalPosts(this.posts, 'blue'),
          icon: 'posts',
          text: 'Total Posts',
          value: this.posts.length,
        },
        {
          chartValue: getGenericCharts(metrics, 'likes', 'blue'),
          icon: 'Organic Likes',
          text: 'Total Organic Likes',
          value: likes,
        },
        {
          chartValue: getGenericCharts(metrics, 'comments', 'blue'),
          icon: 'comments',
          text: 'Total Organic Comments',
          value: comments,
        },
        {
          chartValue: getGenericCharts(metrics, 'saved', 'blue'),
          icon: 'saves',
          text: 'Total Organic Saves',
          value: saved,
        },
      ];
    },

    generateTikTokData(fans, metrics) {
      const { comments, engagement, impressions, likes, shares } = totalMetrics(
        metrics
      );

      const chartTotalLikes = chartTotalPageInfo(fans, 'purple');

      return [
        {
          chartValue: chartTotalPageInfo(fans),
          icon: 'totalFans',
          progress: this.getProgress(chartTotalPageInfo(fans)),
          text: 'Total Followers',
          value: this.getLastValue(fans),
        },
        {
          chartValue: chartTotalLikes,
          icon: 'lovePurple',
          progress: this.getProgress(chartTotalLikes),
          text: 'Total Likes',
          value: this.getLastValue(this.likes),
        },
        {
          chartValue: getGenericCharts(metrics, 'engagement', 'red'),
          icon: 'Engagements',
          text: 'Engagements',
          value: engagement,
        },
        {
          chartValue: getGenericCharts(metrics, 'impressions', 'orange'),
          icon: 'Impressions',
          text: 'Impressions',
          value: impressions,
        },
        {
          chartValue: getTotalPosts(this.posts, 'blue'),
          icon: 'posts',
          text: 'Posts',
          value: this.posts.length,
        },
        {
          chartValue: getGenericCharts(metrics, 'likes', 'blue'),
          icon: 'Organic Likes',
          text: 'Likes',
          value: likes,
        },
        {
          chartValue: getGenericCharts(metrics, 'comments', 'blue'),
          icon: 'comments',
          text: 'Comments',
          value: comments,
        },
        {
          chartValue: getGenericCharts(metrics, 'shares', 'blue'),
          icon: 'shares',
          text: 'Shares',
          value: shares,
        },
      ];
    },

    generateLinkedinData(fans, metrics) {
      const { clicks, comments, impressions, shares } = totalMetrics(metrics);

      const engagementRate =
        impressions > 0
          ? ((totalEngagements(metrics) / impressions) * 100).toFixed(2)
          : 0;

      return [
        {
          chartValue: chartTotalPageInfo(fans),
          icon: 'totalFans',
          progress: this.getProgress(chartTotalPageInfo(fans)),
          text: 'Total Followers',
          value: this.getLastValue(fans),
        },
        {
          chartValue: getGenericCharts(metrics, 'impressions', 'orange'),
          icon: 'impressions',
          text: 'Impressions',
          value: impressions,
        },
        {
          chartValue: chartTotalEngagements(metrics),
          icon: 'totalEngagements',
          rate: engagementRate,
          text: 'Post Engagements',
          value: totalEngagements(metrics),
        },
        {
          chartValue: getGenericCharts(metrics, 'clicks', 'purple'),
          icon: 'clicks',
          text: 'Post Clicks',
          value: clicks,
        },
        {
          chartValue: getTotalPosts(this.posts, 'blue'),
          icon: 'posts',
          text: 'Total Posts',
          value: this.posts.length,
        },
        {
          chartValue: chartTotalReactions(metrics),
          icon: 'totalReactions',
          text: 'Post Reactions',
          value: totalReactions(metrics),
        },
        {
          chartValue: getGenericCharts(metrics, 'comments', 'blue'),
          icon: 'comments',
          text: 'Post Comments',
          value: comments,
        },
        {
          chartValue: getGenericCharts(metrics, 'shares', 'blue'),
          icon: 'shares',
          text: 'Post Shares',
          value: shares,
        },
      ];
    },
  },
};
