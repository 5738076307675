var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.haveCountryData && _vm.haveRegionData)?_c('div',{class:[_vm.$style.reportElement, _vm.$style.changeBorder1],staticStyle:{"width":"1040px"},attrs:{"id":"cards-country-regions"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('BrandAnalyticsFansCards',{staticStyle:{"width":"474px","margin-right":"63px"},attrs:{"title":"Fans Top Countries","icon":'country',"itemClass":_vm.$style.item,"chartHorizontalBarWithStepProps":{
          dataSet: _vm.countryChartData,
          steps: _vm.steps,
          style: 'width:100%',
        }}}),_vm._v(" "),_c('BrandAnalyticsFansCards',{staticStyle:{"width":"474px"},attrs:{"title":"Fans Top Region","icon":'location',"itemClass":_vm.$style.item,"chartHorizontalBarWithStepProps":{
          dataSet: _vm.regionChartData,
          steps: _vm.steps,
          style: 'width:100%',
        }}})],1)]):_vm._e(),_vm._v(" "),(_vm.haveIndustryData && _vm.haveFunctionData)?_c('div',{class:[_vm.$style.reportElement, _vm.$style.changeBorder1],staticStyle:{"width":"1040px"},attrs:{"id":"cards-industries-functions"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('BrandAnalyticsFansCards',{staticStyle:{"width":"474px","margin-right":"63px"},attrs:{"title":"Fans Top Industries","icon":'industry',"itemClass":_vm.$style.item,"chartHorizontalBarWithStepProps":{
          dataSet: _vm.industryChartData,
          steps: _vm.steps,
          style: 'width:100%',
        }}}),_vm._v(" "),_c('BrandAnalyticsFansCards',{staticStyle:{"width":"474px"},attrs:{"title":"Fans Top Functions","icon":'functions',"itemClass":_vm.$style.item,"chartHorizontalBarWithStepProps":{
          dataSet: _vm.functionChartData,
          steps: _vm.steps,
          style: 'width:100%',
        }}})],1)]):_vm._e(),_vm._v(" "),(_vm.haveSeniorityData)?_c('div',{class:[_vm.$style.reportElement, _vm.$style.changeBorder1],staticStyle:{"width":"500px","height":"625px"},attrs:{"id":"cards-senority"}},[_c('BrandAnalyticsFansCards',{staticStyle:{"width":"474px"},attrs:{"title":"Fans Top Senority","icon":'siteMap',"itemClass":_vm.$style.item,"chartHorizontalBarWithStepProps":{
        dataSet: _vm.seniorityChartData,
        steps: _vm.steps,
        style: 'width:100%',
      }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }