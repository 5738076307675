import AnalyticsCardIcon from '@/components/AnalyticsCardIcon';
import ChartLine from '@/components/Charts/ChartLine';
import ChartWeekHours from '@/components/ChartWeekHours';

export default {
  name: 'BrandAnalyticsBestTimesCards',

  components: {
    AnalyticsCardIcon,
    ChartLine,
    ChartWeekHours,
  },

  props: {
    audienceProps: {
      default: null,
      type: Object,
    },
    chartWeekHoursProps: {
      default: null,
      type: Object,
    },
    isWeekly: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    chartLineProps() {
      return this.audienceProps?.chartLineProps;
    },

    isAudience() {
      return !!this.audienceProps;
    },

    timezone() {
      return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'undefined';
    },
  },
};
