import getElementRect from './getElementRect';

export default function(node) {
  const clientRect = getElementRect(node);

  return {
    x: clientRect.left,
    y: clientRect.top,
    width: clientRect.right - clientRect.left,
    height: clientRect.bottom - clientRect.top,
  };
}
