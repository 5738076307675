import { library } from '@fortawesome/fontawesome-svg-core';

// free brands
// - no used -

// free
// - no used -

// free regulars
// - no used -

// pro regulars
// - no used -

// pro solid
import { faCommentAltDots as fasCommentAltDots } from '@fortawesome/pro-solid-svg-icons';

// pro lights
import {
  faAddressCard as falAddressCard,
  faAlarmClock as falAlarmClock,
  faAlignCenter as falAlignCenter,
  faAlignJustify as falAlignJustify,
  faAlignLeft as falAlignLeft,
  faAlignRight as falAlignRight,
  faAngleLeft as falAngleLeft,
  faAngry as falAngry,
  faArchive as falArchive,
  faArrowAltRight as falArrowAltRight,
  faArrowCircleRight as falArrowCircleRight,
  faArrowLeft as falArrowLeft,
  faArrows as falArrows,
  faBackward as falBackward,
  faBallot as falBallot,
  faBars as falBars,
  faBezierCurve as falBezierCurve,
  faBinoculars as falBinoculars,
  faBold as falBold,
  faBolt as falBolt,
  faBookHeart as falBookHeart,
  faBookmark as falBookmark,
  faBookReader as falBookReader,
  faBriefcase as falBriefcase,
  faBroadcastTower as falBroadcastTower,
  faBullhorn as falBullhorn,
  faCalendarAlt as falCalendarAlt,
  faCalendarCheck as falCalendarCheck,
  faCalendarDay as falCalendarDay,
  faCalendarExclamation as falCalendarExclamation,
  faCalendarPlus as falCalendarPlus,
  faCalendarStar as falCalendarStar,
  faCalendarWeek as falCalendarWeek,
  faCamera as falCamera,
  faCameraAlt as falCameraAlt,
  faCameraRetro as falCameraRetro,
  faCaretDown as falCaretDown,
  faChartBar as falChartBar,
  faChartLine as falChartLine,
  faCheck as falCheck,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faChevronUp as falChevronUp,
  faCity as falCity,
  faClipboardCheck as falClipboardCheck,
  faClipboardList as falClipboardList,
  faClock as falClock,
  faClone as falClone,
  faCloud as falCloud,
  faCloudUploadAlt as falCloudUploadAlt,
  faCog as falCog,
  faComment as falComment,
  faCommentAlt as falCommentAlt,
  faCommentAltDots as falCommentAltDots,
  faCommentAltLines as falCommentAltLines,
  faCommentAltSmile as falCommentAltSmile,
  faComments as falComments,
  faCopy as falCopy,
  faCrosshairs as falCrosshairs,
  faCubes as falCubes,
  faDotCircle as falDotCircle,
  faDownload as falDownload,
  faEdit as falEdit,
  faEllipsisH as falEllipsisH,
  faEnvelope as falEnvelope,
  faEnvelopeOpen as falEnvelopeOpen,
  faEraser as falEraser,
  faExclamationCircle as falExclamationCircle,
  faExpandArrows as falExpandArrows,
  faExternalLink as falExternalLink,
  faExternalLinkAlt as falExternalLinkAlt,
  faEye as falEye,
  faFileAlt as falFileAlt,
  faFileArchive as falFileArchive,
  faFileDownload as falFileDownload,
  faFileEdit as falFileEdit,
  faFileExcel as falFileExcel,
  faFileExclamation as falFileExclamation,
  faFileExport as falFileExport,
  faFileImport as falFileImport,
  faFilePdf as falFilePdf,
  faFilePowerpoint as falFilePowerpoint,
  faFileWord as falFileWord,
  faFilm as falFilm,
  faFilter as falFilter,
  faFolder as falFolder,
  faFolderOpen as falFolderOpen,
  faForward as falForward,
  faGift as falGift,
  faGlobeAmericas as falGlobeAmericas,
  faGrimace as falGrimace,
  faGrinStars as falGrinStars,
  faGrinTears as falGrinTears,
  faHandHoldingHeart as falHandHoldingHeart,
  faHandPointer as falHandPointer,
  faHashtag as falHashtag,
  faHeading as falHeading,
  faHeart as falHeart,
  faHeartbeat as falHeartbeat,
  faHistory as falHistory,
  faHome as falHome,
  faHomeHeart as falHomeHeart,
  faHourglassEnd as falHourglassEnd,
  faImage as falImage,
  faInbox as falInbox,
  faIndustry as falIndustry,
  faInfoCircle as falInfoCircle,
  faItalic as falItalic,
  faLanguage as falLanguage,
  faLaugh as falLaugh,
  faLayerPlus as falLayerPlus,
  faLeaf as falLeaf,
  faLightbulb as falLightbulb,
  faLightbulbOn as falLightbulbOn,
  faLink as falLink,
  faListAlt as falListAlt,
  faListOl as falListOl,
  faListUl as falListUl,
  faLocation as falLocation,
  faLock as falLock,
  faLockOpen as falLockOpen,
  faLongArrowLeft as falLongArrowLeft,
  faMinus as falMinus,
  faMinusCircle as falMinusCircle,
  faMousePointer as falMousePointer,
  faNewspaper as falNewspaper,
  faPalette as falPalette,
  faPaperclip as falPaperclip,
  faPaperPlane as falPaperPlane,
  faPaste as falPaste,
  faPen as falPen,
  faPenAlt as falPenAlt,
  faPencil as falPencil,
  faPencilAlt as falPencilAlt,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faPrayingHands as falPrayingHands,
  faQuestionCircle as falQuestionCircle,
  faQuoteLeft as falQuoteLeft,
  faRectangleLandscape as falRectangleLandscape,
  faRectanglePortrait as falRectanglePortrait,
  faRepeat as falRepeat,
  faReply as falReply,
  faReplyAll as falReplyAll,
  faRocket as falRocket,
  faSadTear as falSadTear,
  faSave as falSave,
  faSearch as falSearch,
  faShare as falShare,
  faShareSquare as falShareSquare,
  faSignal as falSignal,
  faSignLanguage as falSignLanguage,
  faSignOutAlt as falSignOutAlt,
  faSitemap as falSitemap,
  faSmile as falSmile,
  faSortDown as falSortDown,
  faSortUp as falSortUp,
  faSpinner as falSpinner,
  faSpinnerThird as falSpinnerThird,
  faSquare as falSquare,
  faStar as falStar,
  faStarOfLife as falStarOfLife,
  faStickyNote as falStickyNote,
  faStrikethrough as falStrikethrough,
  faSurprise as falSurprise,
  faSync as falSync,
  faTable as falTable,
  faTag as falTag,
  faThumbsDown as falThumbsDown,
  faThumbsUp as falThumbsUp,
  faThumbtack as falThumbtack,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faToolbox as falToolbox,
  faTrash as falTrash,
  faTrashAlt as falTrashAlt,
  faUnderline as falUnderline,
  faUnlock as falUnlock,
  faUpload as falUpload,
  faUsdCircle as falUsdCircle,
  faUserAlt as falUserAlt,
  faUserCheck as falUserCheck,
  faUserCircle as falUserCircle,
  faUserFriends as falUserFriends,
  faUserPlus as falUserPlus,
  faUsers as falUsers,
  faUserTie as falUserTie,
  faVenusMars as falVenusMars,
  faVideo as falVideo,
  faVolume as falVolume,
  faVolumeMute as falVolumeMute,
  faVolumeUp as falVolumeUp,
} from '@fortawesome/pro-light-svg-icons';

// Custom icons
import { faStories } from './utils/CustomIcons';

//Customs
library.add(faStories);

//Free Brands

//Free Solid

//Pro Regular

// Pro Solid
library.add(fasCommentAltDots);

// Pro Lights
library.add(
  falAddressCard,
  falAlarmClock,
  falAlignCenter,
  falAlignJustify,
  falAlignLeft,
  falAlignRight,
  falAngleLeft,
  falAngry,
  falArchive,
  falArrowAltRight,
  falArrowCircleRight,
  falArrowLeft,
  falArrows,
  falBackward,
  falBallot,
  falBars,
  falBezierCurve,
  falBinoculars,
  falBold,
  falBolt,
  falBookHeart,
  falBookmark,
  falBookReader,
  falBriefcase,
  falBroadcastTower,
  falBullhorn,
  falCalendarAlt,
  falCalendarCheck,
  falCalendarDay,
  falCalendarExclamation,
  falCalendarPlus,
  falCalendarStar,
  falCalendarWeek,
  falCamera,
  falCameraAlt,
  falCameraRetro,
  falCaretDown,
  falChartBar,
  falChartLine,
  falCheck,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falChevronUp,
  falCity,
  falClipboardCheck,
  falClipboardList,
  falClock,
  falClone,
  falCloud,
  falCloudUploadAlt,
  falCog,
  falComment,
  falCommentAlt,
  falCommentAltDots,
  falCommentAltLines,
  falCommentAltSmile,
  falComments,
  falCopy,
  falCrosshairs,
  falCubes,
  falDotCircle,
  falDownload,
  falEdit,
  falEllipsisH,
  falEnvelope,
  falEnvelopeOpen,
  falEraser,
  falExclamationCircle,
  falExpandArrows,
  falExternalLink,
  falExternalLinkAlt,
  falEye,
  falFileAlt,
  falFileArchive,
  falFileDownload,
  falFileEdit,
  falFileExcel,
  falFileExclamation,
  falFileExport,
  falFileImport,
  falFilePdf,
  falFilePowerpoint,
  falFileWord,
  falFilm,
  falFilter,
  falFolder,
  falFolderOpen,
  falForward,
  falGift,
  falGlobeAmericas,
  falGrimace,
  falGrinStars,
  falGrinTears,
  falHandHoldingHeart,
  falHandPointer,
  falHashtag,
  falHeading,
  falHeart,
  falHeartbeat,
  falHistory,
  falHome,
  falHomeHeart,
  falHourglassEnd,
  falImage,
  falInbox,
  falIndustry,
  falInfoCircle,
  falItalic,
  falLanguage,
  falLaugh,
  falLayerPlus,
  falLeaf,
  falLightbulb,
  falLightbulbOn,
  falLink,
  falListAlt,
  falListOl,
  falListUl,
  falLocation,
  falLock,
  falLockOpen,
  falLongArrowLeft,
  falMinus,
  falMinusCircle,
  falMousePointer,
  falNewspaper,
  falPalette,
  falPaperclip,
  falPaperPlane,
  falPaste,
  falPen,
  falPenAlt,
  falPencil,
  falPencilAlt,
  falPlus,
  falPlusCircle,
  falPrayingHands,
  falQuestionCircle,
  falQuoteLeft,
  falRectangleLandscape,
  falRectanglePortrait,
  falRepeat,
  falReply,
  falReplyAll,
  falRocket,
  falSadTear,
  falSave,
  falSearch,
  falShare,
  falShareSquare,
  falSignal,
  falSignLanguage,
  falSignOutAlt,
  falSitemap,
  falSmile,
  falSortDown,
  falSortUp,
  falSpinner,
  falSpinnerThird,
  falSquare,
  falStar,
  falStarOfLife,
  falStickyNote,
  falStrikethrough,
  falSurprise,
  falTable,
  falTag,
  falThumbsDown,
  falThumbsUp,
  falThumbtack,
  falTimes,
  falTimesCircle,
  falToolbox,
  falTrash,
  falTrashAlt,
  falUnderline,
  falUnlock,
  falUpload,
  falUsdCircle,
  falUserAlt,
  falUserCheck,
  falUserCircle,
  falUserFriends,
  falUserPlus,
  falUsers,
  falUserTie,
  falVenusMars,
  falVideo,
  falVolume,
  falVolumeMute,
  falVolumeUp
);
