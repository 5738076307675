// @flow

import { isElement, isEmpty, isUndefined, unescape } from 'lodash';

const cache: { [key: string]: Object } = {};

export default {
  getBootstrapData(name, optional = false) {
    const node = this.getScriptElm(name);

    if (isElement(node)) {
      const data = JSON.parse(unescape(node.innerHTML));

      if (node.parentNode) {
        node.parentNode.removeChild(node);
      }

      cache[name] = data;

      return data;
    }

    const cachedData = cache[name];

    if (!isUndefined(cachedData)) {
      return cachedData;
    } else if (!optional) {
      throw new Error(`Missing bootstrap data {${name}}`);
    }
  },

  hasBootstrapData(name) {
    if (name in cache) {
      if (isEmpty(cache[name])) {
        return false;
      }

      return true;
    }

    const elmScript = this.getScriptElm(name);

    return isElement(elmScript);
  },

  isInBootstrapCache(name) {
    return name in cache && !isEmpty(cache[name]);
  },

  getScriptElm(name) {
    return document.getElementById(`bootstrap-data-${name}`);
  },
};
