import { capitalize } from 'lodash';
import {
  getPercents,
  getTotalReactions,
  getTotalEngagement,
} from './utilsCommon';

/**
 * Get engagements rate of Linkedin
 * @param {*} metric
 */
const getEngagementRate = metric => {
  const engagement = getTotalEngagement(metric) || 0;
  const impressions = metric?.impressions || 0;

  if (impressions > 0) {
    return (engagement / impressions) * 100;
  }

  return 0;
};

/**
 * Convert metrics in data with name and value information
 * @param {*} metrics
 */
export const getLinkedinData = metrics => {
  const { comments, impressions, shares, clicks } = metrics;

  const engagement = getTotalEngagement(metrics) || 0;
  const engagementRate = getEngagementRate(metrics);

  /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
  const { __typename, ...rest } = metrics?.reactions || {};

  const reactions = {
    ...rest,
    total: getTotalReactions(rest),
  };

  const metricsToShow = [
    {
      name: 'Impressions',
      position: 3,
      values: impressions || 0,
    },
    {
      name: 'Engagement Rate',
      percent: true,
      position: 4,
      values: engagementRate,
    },
    {
      icon: 'Engagements',
      name: 'Total Engagements',
      position: 5,
      values: engagement,
    },
    {
      icon: 'Clicks',
      name: 'Post Clicks',
      position: 6,
      values: clicks,
    },

    //Reactions
    {
      details: Object.keys(rest).map(name => {
        return {
          name: capitalize(name),
          percent: getPercents(reactions.total, reactions[name]),
          values: reactions[name],
        };
      }),
      icon: 'Total Reactions',
      name: 'Post Reactions',
      position: 7,
      showMore: true,
      values: reactions.total,
    },
    {
      icon: 'Comments',
      name: 'Post Comments',
      position: 8,
      values: comments || 0,
    },
    {
      icon: 'Shares',
      name: 'Post Shares',
      position: 9,
      values: shares,
    },
  ];

  return metricsToShow;
};
