import { format } from 'date-fns';

export default {
  name: 'ChartWeekHours',

  props: {
    weekDays: {
      default: null,
      type: Array,
    },
    forceMobile: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      hours: [],
      dayHours: null,
      isMobile: false,
    };
  },

  computed: {
    weekDaysHours() {
      return Object.entries(this.weekDays).map(([day, value]) => {
        const hours = value.reduce((ac, d) => {
          const { hour24 } = d;

          if (ac[hour24]) {
            const amount = ac[hour24].amount + d.amount;
            const average = (ac[hour24].average + d.amount) / 2;

            ac[hour24] = {
              ...d,
              amount,
              average,
            };
          } else {
            ac[hour24] = {
              ...d,
              amount: parseInt(d.amount),
              average: parseInt(d.amount),
            };
          }

          return ac;
        }, []);

        return { day, hours };
      });
    },

    sortedDaysByTraffic() {
      const sorted = this.weekDaysHours
        .map(week => {
          const traffic = week.hours.reduce((ac, h) => {
            ac += h.average;

            return ac;
          }, 0);

          return { day: week.day, traffic };
        })
        .sort((a, b) => {
          return b.traffic - a.traffic;
        });

      return sorted;
    },

    bestFiveHoursByDay() {
      const bestFiveHours = this.weekDaysHours.map(week => {
        const hours = (week?.hours || [])
          .sort((a, b) => {
            return b?.average - a?.average;
          })
          .slice(0, 5);

        return { day: week.day, hours };
      });

      return bestFiveHours;
    },

    numberOneBestDay() {
      let best = { day: '', hour24: 0, average: 0 };

      this.weekDaysHours.forEach(week => {
        const { day, hours } = week;

        hours.forEach(hour => {
          if (hour?.average > best?.average) {
            const { hour24, amount, average } = hour;
            best = { day, hour24, amount, average };
          }
        });
      });

      return best;
    },
  },

  methods: {
    getPointStyle(day, hour) {
      const style = [this.$style.point, this.$style.hintStyle];
      const { day: bestDay, hour24 } = this.numberOneBestDay;

      if (this.getBetTime(day, hour)) {
        style.push(this.$style.pointYellow);
      }

      if (day === bestDay && parseInt(hour) === parseInt(hour24)) {
        style.push(this.$style.pointBlue);
        style.push(this.$style.pointBlueAnimation);
      }

      return style;
    },

    getAveragePopover(day, hour) {
      const selected = this.weekDaysHours.reduce((ac, week) => {
        if (week.day === day) {
          const findHour = week.hours
            .filter(h => h)
            .find(h => parseInt(h.hour24) === hour);

          if (findHour) {
            ac = findHour;
          }
        }

        return ac;
      }, {});

      return this.getSymbolicNumber(selected.average);
    },

    getSymbolicNumber(num = 0) {
      const number = parseFloat(num).toFixed(0);

      let printNumber = number;
      let symbol = '';

      if (number >= 1000 && number < 1000 * 1000) {
        printNumber = number / 1000;
        symbol = 'K';
      }

      if (number >= 1000 * 1000) {
        printNumber = number / (1000 * 1000);
        symbol = 'M';
      }

      return `${this.$filters.formatNumber(printNumber, 2)}${symbol}`;
    },

    getBetTime(day, hour) {
      const weekDay = this.bestFiveHoursByDay.find(w => w?.day === day);
      const hours = weekDay?.hours ?? [];

      return !!hours.find(({ hour24 }) => parseInt(hour24) === parseInt(hour));
    },

    getBestDayTraffic(day) {
      const dayTraffic = this.sortedDaysByTraffic
        .slice(0, 3)
        .find(d => d.day === day);

      return dayTraffic ? this.$style.yellow : '';
    },

    getBestHours(day) {
      const hoursOfDay = this.bestFiveHoursByDay.find(
        best => best?.day === day
      );

      return (hoursOfDay?.hours || []).slice(0, 3);
    },

    getStringHour(hour) {
      const date = new Date();

      return format(date.setHours(hour), 'h A');
    },

    handleResize() {
      this.isMobile = window.innerWidth < 1280;
    },
  },

  created() {
    for (let i = 0; i < 24; i++) {
      this.hours.push({ hour: i, size: 0 });
    }

    this.dayHours = [
      { name: 'Sunday', hours: this.hours },
      { name: 'Monday', hours: this.hours },
      { name: 'Tuesday', hours: this.hours },
      { name: 'Wednesday', hours: this.hours },
      { name: 'Thursday', hours: this.hours },
      { name: 'Friday', hours: this.hours },
      { name: 'Saturday', hours: this.hours },
    ];
  },

  mounted() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
